import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TasksPageType} from '../../types/TasksPageType';
import {RootState} from '../store';
import {EmployeeTaskStatusEnum} from "../../enums/EmployeeTaskStatusEnum.ts";

type TaskPageState = {
    taskPageStatus: EmployeeTaskStatusEnum;
    tasksRefreshIndex: number;
}

const initialState: TaskPageState = {
    taskPageStatus: EmployeeTaskStatusEnum.OnGoing,
    tasksRefreshIndex: 0,
};

const tasksPageSlice = createSlice({
    name: 'tasksPage',
    initialState,
    reducers: {
        setTaskPageStatus(state, action: PayloadAction<EmployeeTaskStatusEnum>) {
            state.taskPageStatus = action.payload;
        },
        setTaskRefreshIndex(state) {
            state.tasksRefreshIndex++;
        }
    },
});

export const {setTaskPageStatus, setTaskRefreshIndex} = tasksPageSlice.actions;

// Використовуйте правильну назву slice у селекторі
export const selectTasksPageStatus = (state: RootState) => state.tasksPage.taskPageStatus;
export const selectTasksRefreshIndex = (state: RootState) => state.tasksPage.tasksRefreshIndex;
export const tasksPageReducer = tasksPageSlice.reducer;