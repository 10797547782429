import React, {FC, PropsWithChildren, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {currentNotificationHub} from "../../config/appConfig.ts";
import {selectAuthToken} from "../../redux/slices/authSlice.ts";
import {useAppSelector} from "../../redux/hooks/hooks.ts";
import {setTaskRefreshIndex} from "../../redux/slices/tasksSlice.ts";
import {setNotificationsRefreshIndex} from "../../redux/slices/appSlice.ts";

type Props = {}

const SignalR: FC<PropsWithChildren<Props>> = ({children}) => {
    const [notificationHubConnection, setNotificationHubConnection] =
        React.useState<HubConnection>();
    const dispatch = useDispatch();
    const token = useAppSelector(selectAuthToken);
    const jwtToken = token?.token;

    const createNotificationHubConnection = async () => {
        if (!jwtToken) {
            return;
        }
        const retryDelays = [3000];
        for (let i = 0; i < 9999; i++) {
            retryDelays.push(5000);
        }
        console.log("useEffect 3");

        const connection = new HubConnectionBuilder()
            .withUrl(currentNotificationHub, {
                accessTokenFactory() {
                    return jwtToken;
                },
                logger: {
                    log(logLevel, message) {
                        console.debug(
                            `[HUB ${logLevel}]`,
                            message,
                        );
                    },
                },
                logMessageContent: true,
                timeout: 5000,
            })
            .withAutomaticReconnect()
            .build();

        setNotificationHubConnection(connection);
        try {
            await connection.start();
            console.log("Connected to notification hub");
        } catch (error) {
            console.log("Error while starting notification hub connection: " + error);
        }
    };

    useEffect(() => {
        createNotificationHubConnection();
    }, []);

    useEffect(() => {
        if (notificationHubConnection) {
            notificationHubConnection.on("NotificationMessage", (message: any) => {
                console.log("NotificationMessage", message);
                // addNotification({
                //     title: "Warning",
                //     subtitle: "You've got a new notification!",
                //     message: "message",
                //     theme: 'darkblue',
                //     native: true // when using native, your OS will handle theming.
                // });                
                dispatch(setTaskRefreshIndex());
                dispatch(setNotificationsRefreshIndex());
            });
        }
        return () => {
            notificationHubConnection?.off("NotificationMessage");
            notificationHubConnection?.stop();
            console.log("Disconnected from notification hub");
        };
    }, [notificationHubConnection]);

    return (
        <>
            {children}
        </>
    );
};

export default SignalR;