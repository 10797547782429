import React, {FC, Fragment, PropsWithChildren, useRef} from 'react';
import './PageHeaderSelect.css';
import {useDetectClickOutside} from "react-detect-click-outside";

type Props = {
    label: string;
    cssClass?: string;
    width?: string;
}

const PageHeaderSelect: FC<PropsWithChildren<Props>> = ({
                                                            children,
                                                            cssClass,
                                                            label,
                                                            width
                                                        }) => {
    const childrenArray = React.Children.toArray(children);
    const [isOpen, setIsOpen] = React.useState(false);

    const ref = useDetectClickOutside({onTriggered: ()=>setIsOpen(false)});

    return (
        <div ref={ref} className={`text-sm rounded border border-black-10 ${cssClass}`}>
            <div className="dropdown inline-block relative">
                <button onClick={() => setIsOpen(!isOpen)}
                    className={`${width} font-myriad text-xs font-regular text-dark-green md:text-sm bg-black-secondary-strong dark:bg-gray-700 py-2 px-4 rounded inline-flex items-center`}>
                    <div className={"w-full flex flex-row justify-between align-middle"}>
                    <span className="mr-1">{label}</span>
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                    </svg>
                    </div>
                </button>
                <ul className={`${isOpen ? "block" : "hidden"} dropdown-menu absolute font-myriad text-xs font-regular text-dark-green md:text-sm bg-black-secondary-strong pt-1 shadow shadow-shadow-dark rounded-b-xl z-50`}>
                    {childrenArray.map((child, index) => {
                        return (
                            <Fragment key={index}>
                                {React.cloneElement(child as any, {key: index, width, onInternalClick: () => setIsOpen(false)})}
                            </Fragment>
                        );
                    })}
                </ul>
            </div>

        </div>
    );
};

export default PageHeaderSelect;