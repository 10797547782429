const CheckedIcon = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.36865 3.32889L3.01339 4.97363L6.63181 1.02626"
        stroke="#FCFDFD"
        strokeWidth="0.986842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckedIcon;
