import React, {FC, PropsWithChildren} from 'react';
import FAQIcon from "./res/FAQIcon.tsx";
import {useNavigate} from "react-router-dom";

type Props = {
    label?: string;
    icon?: React.ReactNode;
    onClick?: () => void;
}

const AccountMenuItem: FC<PropsWithChildren<Props>> = ({label, icon, onClick}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="cursor-pointer p-2.5 hover:rounded-lg hover:bg-black-10">
                <div
                    onClick={onClick}
                >
                    <a className="flex flex-row items-center justify-start gap-2.5 font-myriad text-sm font-regular text-black-primary">
                        {icon && icon}
                        {label}
                    </a>
                </div>
            </div>
        </>
    );
};

export default AccountMenuItem;