const EditIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="edit-icon"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="#FCFDFD" />
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#E5E9EC" />
      <path
        d="M19.5553 11.9141C20.1142 11.3086 20.3936 11.0058 20.6906 10.8292C21.4071 10.4031 22.2893 10.3898 23.0178 10.7942C23.3197 10.9619 23.6077 11.2561 24.1838 11.8446C24.7599 12.4331 25.0479 12.7273 25.212 13.0357C25.6079 13.7798 25.5949 14.6811 25.1778 15.413C25.0049 15.7164 24.7085 16.0018 24.1157 16.5728L17.063 23.3657C15.9397 24.4477 15.378 24.9886 14.676 25.2628C13.9741 25.537 13.2024 25.5168 11.659 25.4764L11.449 25.471C10.9792 25.4587 10.7443 25.4525 10.6077 25.2975C10.4711 25.1426 10.4898 24.9033 10.5271 24.4246L10.5473 24.1648C10.6523 22.8177 10.7047 22.1441 10.9678 21.5387C11.2308 20.9332 11.6846 20.4416 12.5921 19.4584L19.5553 11.9141Z"
        stroke="#00A9B5"
        strokeWidth="1.5625"
        strokeLinejoin="round"
      />
      <path
        d="M18.7461 12L23.9961 17.25"
        stroke="#00A9B5"
        strokeWidth="1.5625"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
