import {t} from "i18next";
import useFetchData from "../../../hooks/useFetchData";
import Loading from "../../../components/Loading/Loading";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import AlertDanger from "../../../components/Alerts/AlertDanger";
import BaseModal from "../../../components/Modals/BaseModal";
import {FormProvider, useForm} from "react-hook-form";
import {useCallback, useEffect} from "react";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import {toast} from "react-hot-toast";
import FormTextInput from "../../../components/FormControls/FormTextInput";
import {CreateTaskSourceDto, TaskSourceDto} from "../../../dtos/taskSource/TaskSourceDto";
import FormSelectControl from "../../../components/FormControls/FormSelectControl";
import {CreateServiceDto, ServiceDto} from "../../../dtos/services/ServiceDto";
import {FormHelper} from "../../../utils/FormHelper.ts";

type Props = {
    onClose: () => void;
    onChanged: () => void;
    serviceId?: string;
}

type EditServiceFormModel = {
    id?: string,
    name: string,
    predefinedEmployeeTaskDescription: SelectControlOption<string>,
}

export const EditServiceModal: React.FC<Props> = ({
                                                      onClose,
                                                      serviceId,
                                                      onChanged,
                                                  }) => {
    const {
        createItem,
        updateItem,
        item,
        fetchItem,
        error,
        isLoading,
        isSubmitting
    } = useFetchData<CreateServiceDto, ServiceDto, ServiceDto>("Services");

    const {
        isLoading: isPredefinedEmployeeTaskDescriptionsLoading,
        fetchItems: fetchPredefinedEmployeeTaskDescriptions,
        error: predefinedEmployeeTaskDescriptionsError,
        itemOptions: predefinedEmployeeTaskDescriptionsOptions
    } = useFetchData("PredefinedEmployeeTaskDescriptions", "description", "id");

    const isBusy = isLoading || isSubmitting || isPredefinedEmployeeTaskDescriptionsLoading;

    const handleAddOrUpdateService = useCallback(async (data: EditServiceFormModel) => {
        console.log("data: ", data)
        if (!serviceId) {
            const newService: CreateServiceDto = {
                name: data.name,
                predefinedEmployeeTaskDescriptionId: data.predefinedEmployeeTaskDescription?.value,
            };
            await createItem(newService);
        } else {
            const updateService: ServiceDto = {
                id: serviceId,
                name: data.name,
                predefinedEmployeeTaskDescriptionId: data.predefinedEmployeeTaskDescription?.value,
            };
            await updateItem(serviceId, updateService);
        }

        onChanged();
        toast.success(t("ServiceSavedSuccessfully"));
    }, [serviceId, onChanged]);

    const hookForm = useForm<EditServiceFormModel>();
    const {reset, handleSubmit} = hookForm;


    const fetchData = useCallback(async () => {
        const predefinedTasks = await fetchPredefinedEmployeeTaskDescriptions();

        if (serviceId) {
            const service = await fetchItem(serviceId);
            if (service) {
                reset({
                    id: service.id,
                    name: service.name,
                    predefinedEmployeeTaskDescription: FormHelper.getSelectedOption<string>(service, predefinedTasks, "predefinedEmployeeTaskDescriptionId", "description"),
                });
            }
        }
    }, [serviceId, predefinedEmployeeTaskDescriptionsOptions]);

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={!serviceId ? (t("AddService")) : (t("EditService"))}
                                     onClose={onClose}/>
                    <AlertDanger errorMessages={[error]}/>
                    <Loading
                        loading={isBusy}
                        type={"cover"}
                    >
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="name"
                                type="text"
                                label={t("Name")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                        <div className="mt-2">
                            <FormSelectControl
                                fieldName="predefinedEmployeeTaskDescription"
                                options={predefinedEmployeeTaskDescriptionsOptions}
                                label={t("PredefinedTaskDescription")}
                                extraCssClass="w-full"
                                isRequired={false}
                            />
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Save")}
                    showCloseButton={false}
                    onSave={handleSubmit(handleAddOrUpdateService)}
                    saveButtonDisabled={isBusy}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    )
}