import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Currency} from "../../types/Currency.ts";
import {CalendarEventFilter} from "../../pages/Calendar/types.ts";
import UserType from "../../types/UserType.ts";
import {TaskFilterModal} from "../../pages/TasksPage/types.ts";
import {DashboardFilter} from "../../pages/DashboardPage/types.tsx";

type AppSlice = {
    appCurrency: Currency;
    calendarFilter: CalendarEventFilter;
    dashboardFilter: DashboardFilter;
    tasksFilter: TaskFilterModal;
    notificationsRefreshIndex: number;
}

const initialState: AppSlice = {
    appCurrency: "GEL",
    dashboardFilter: {
        periodType: "Monthly"
    },
    calendarFilter: {
        apartments: [],
        allEvents: true
    },
    tasksFilter: {},
    notificationsRefreshIndex: 0
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppCurrency: (state, action: PayloadAction<Currency>) => {
            state.appCurrency = action.payload;
        },
        setDashboardFilter: (state, action: PayloadAction<DashboardFilter>) => {
            state.dashboardFilter = action.payload;
        },
        setCalendarFilter: (state, action: PayloadAction<CalendarEventFilter>) => {
            state.calendarFilter = action.payload;
        },
        setTasksFilter: (state, action: PayloadAction<TaskFilterModal>) => {
            state.tasksFilter = action.payload;
        },
        setNotificationsRefreshIndex: (state) => {
            state.notificationsRefreshIndex++;
        }
    }
});

export const {
    setCalendarFilter,
    setAppCurrency,
    setTasksFilter,
    setDashboardFilter,
    setNotificationsRefreshIndex
} = appSlice.actions;

export const selectAppCurrency = (state: { app: AppSlice }) => state.app.appCurrency;
export const selectDashboardFilter = (state: { app: AppSlice }) => state.app.dashboardFilter;
export const selectCalendarFilter = (state: { app: AppSlice }) => state.app.calendarFilter;
export const selectTasksFilter = (state: { app: AppSlice }) => state.app.tasksFilter;
export const selectNotificationsRefreshIndex = (state: { app: AppSlice }) => state.app.notificationsRefreshIndex;
export const appReducer = appSlice.reducer;