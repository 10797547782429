import {useState} from "react";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto.ts";
import {axiosInstance} from "../config/appConfig.ts";
import EmployeeTaskSourceDto from "../dtos/employeeTaskSources/EmployeeTaskSourceDto.ts";

const useFetchTaskSources = () => {
    const [employeeTaskSources, setEmployeeTaskSources] = useState<EmployeeTaskSourceDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const employeeTaskSourceOptions: SelectControlOption<string>[] = employeeTaskSources.map((employeeTaskSource) => ({
        value: employeeTaskSource.id,
        label: employeeTaskSource.description
    })) || [];

    const fetchEmployeeTaskSources = async () => {
        try {
            setIsLoading(true);
            setError(undefined);

            const response = await axiosInstance.get<ListResponseDto<EmployeeTaskSourceDto>>("EmployeeTaskSources");
            setEmployeeTaskSources(response.data.items);

            return response.data.items;
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return {
        employeeTaskSources,
        isLoading,
        error,
        fetchEmployeeTaskSources,
        employeeTaskSourceOptions
    };
}

export default useFetchTaskSources;