import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import usflag from "../../assets/png/flags/Flag US/Flag=US.png";

interface ILanguageDropdownState {
  isDropdownOpen: boolean;
  selectedFlag: string;
  selectedLanguage: string;
}

const initialState: ILanguageDropdownState = {
  isDropdownOpen: false,
  selectedFlag: usflag,
  selectedLanguage: "us",
};

export const languageDropdownSlice = createSlice({
  name: "languageDropdown",
  initialState,
  reducers: {
    setIsDropdownOpen: (state, action: PayloadAction<boolean>) => {
      state.isDropdownOpen = action.payload;
    },
    setSelectedFlag: (state, action: PayloadAction<string>) => {
      state.selectedFlag = action.payload;
    },
    setSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
    selectFlag: (
      state,
      action: PayloadAction<{ flag: string; language: string }>,
    ) => {
      const { flag, language } = action.payload;
      state.selectedFlag = flag;
      state.selectedLanguage = language;
      state.isDropdownOpen = false;
    },
  },
});

export const {
  setIsDropdownOpen,
  setSelectedFlag,
  setSelectedLanguage,
  selectFlag,
} = languageDropdownSlice.actions;

export const languageDropdownReducer = languageDropdownSlice.reducer;
