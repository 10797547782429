import React, {FC, PropsWithChildren} from 'react';
import {useFormContext} from "react-hook-form";
import {FormControlBaseProps} from "../../types/Forms/FormControlBaseProps.ts";
import {useTranslation} from "react-i18next";

type Props = {
    width?: string;
} & FormControlBaseProps

const FormTextAreaInput: FC<PropsWithChildren<Props>> = ({placeholder, label, fieldName, width, isRequired}) => {
    const {register, formState: {errors}} = useFormContext();
    const {t} = useTranslation();

    const cssWidth = () => {
        if (width) {
            return `w-${width}`;
        }
    }

    return (
        <>
            {label && <label className={"text-xs text-black-primary"}>{label}</label>}
            <textarea
                className={`flex ${cssWidth()} font-myriad text-xs font-regular mt-2 items-center 
                justify-center rounded border-[1px] border-black-10 p-3.5 md:rounded-lg hover:border-dark-green dark:bg-white text-black`}
                placeholder={placeholder} {...register(fieldName, {
                required: isRequired
            })}
            />
            {errors[fieldName] && <span className={"text-red-500 text-xs"}>This field is required</span>}
        </>
    );
};

export default FormTextAreaInput;