import {useCallback} from "react";
import EmployeeTaskDto from "../dtos/EmployeeTask/EmployeeTaskDto.ts";
import UserType from "../types/UserType.ts";

const isUserAbleToChangeTaskStatus = (employeeTask: EmployeeTaskDto, userRole: UserType) => {
    if (userRole === "Employee") {
        return true;
    }
    if (userRole === "Supervisor") {
        return true;
    }

    return true;
};

export const EmployeeTaskHelper = {
    isUserAbleToChangeTaskStatus,
}