import {defaultDateFormat, defaultDateTimeFormat} from "../config/appConfig.ts";
import {format} from "date-fns";

const formatDate = (dateString: Date) => {
    const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "long",
        year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

const formatDefaultDate = (dateString?: Date) => {
    if (!dateString) {
        return "";
    }
    
    return format(dateString, defaultDateFormat);
}

const formatDefaultDateWithTime = (dateString?: Date) => {
    if (!dateString) {
        return "";
    }
    
    return format(dateString, defaultDateTimeFormat);
}

export const DateHelper = {
    formatDate,
    formatDefaultDateWithTime,
    formatDefaultDate
} 


