import { t } from "i18next";
import { CreateDepartmentDto, DepartmentDto } from "../../../dtos/departments/DepartmentDto";
import useFetchData from "../../../hooks/useFetchData";
import Loading from "../../../components/Loading/Loading";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import AlertDanger from "../../../components/Alerts/AlertDanger";
import BaseModal from "../../../components/Modals/BaseModal";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import { toast } from "react-hot-toast";
import FormTextInput from "../../../components/FormControls/FormTextInput";
import { CreateLocationDto, LocationDto } from "../../../dtos/location/LocationDto";

type Props = {
    onClose: () => void;
    onChanged: () => void;
    locationId?: string;
}

type EditLocationFormModel = {
    id?: string,
    name: string,
}

export const EditLocationModal: React.FC<Props> = ({
                                                       onClose,
                                                       locationId,
                                                       onChanged,
                                                   }) => 
{
    const {
        createItem,
        updateItem,
        fetchItem,
        isLoading,
        error: LocationError,
        isSubmitting,
    } = useFetchData<CreateLocationDto, LocationDto, LocationDto>("Locations", "name", "id");

    const isBusy = isLoading || isSubmitting;


    const handleAddOrUpdateLocation = useCallback(async (data: EditLocationFormModel) => {
        if (!locationId) {
            const newLocation: CreateLocationDto = {
                name: data.name,
            };
            await createItem(newLocation);
        } else {
            const updateLocation: LocationDto = {
                id: locationId,
                name: data.name,
            };
            await updateItem(locationId, updateLocation);
        }

        onChanged();
        toast.success(t("LocationSavedSuccessfully"));
    }, [locationId, onChanged]);

    const hookForm = useForm<EditLocationFormModel>();
    const {reset, handleSubmit} = hookForm;

    const fetchData = useCallback(async () => {

        if (locationId) {
            const location = await fetchItem(locationId);
            if (location) {
                reset({
                    id: location.id,
                    name: location.name,
                });
            }
        }
    }, [locationId]);

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={!locationId ? (t("AddLocation")) : (t("EditLocation"))}
                                     onClose={onClose}/>
                    <AlertDanger errorMessages={[LocationError]}/>
                    <Loading
                        loading={isBusy}
                        type={"cover"}
                    >
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="name"
                                type="text"
                                label={t("Name")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Save")}
                    showCloseButton={false}
                    onSave={handleSubmit(handleAddOrUpdateLocation)}
                    saveButtonDisabled={isBusy}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    )
}