import {AdminSidebarMenu, EmployeeSidebarMenu, SupervisorSidebarMenu} from "./SidebarMenu";
import {Link, useLocation} from "react-router-dom";
import "./styles.css";
import HamburgerMenu from "../../../assets/svg/sidebarIcons/HamburgerMenu";
import LanguagesDropdown from "../../DropDowns/languagesDropdown/LanguagesDropdown";
import CloseIcon from "../../../assets/svg/CloseIcon";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/hooks";
import {selectIsAdmin, selectUserType} from "../../../redux/slices/authSlice";
import {toggle} from "../../../redux/slices/SideBarSlice";
import {selectTasksPageStatus, setTaskPageStatus} from "../../../redux/slices/tasksSlice";
import {TasksPageType} from "../../../types/TasksPageType";
import UserType from "../../../types/UserType.ts";
import {useTranslation} from "react-i18next";

const Sidebar = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const location = useLocation();


    const isAdmin = useAppSelector(selectIsAdmin);
    const userType = useAppSelector(selectUserType);
    const sidebarIsOpen = useAppSelector((state) => state.sidebar.isOpen);
    const taskPageType = useAppSelector(selectTasksPageStatus);

    const getUserTypeMenu = (userType: UserType) => {
        switch (userType) {
            case "Admin":
                return AdminSidebarMenu;
            case "Supervisor":
                return SupervisorSidebarMenu;
            default:
                return EmployeeSidebarMenu;
        }
    };

    const filteredSidebar = getUserTypeMenu(userType);

    const sidebarHandler = () => {
        dispatch(toggle());
        const newSidebarWidth = sidebarIsOpen ? "72px" : "240px";
        document.documentElement.style.setProperty(
            "--sidebar-width",
            newSidebarWidth,
        );
    };
    return (
        <div
            className={`select-none z-50 flex h-screen ${sidebarIsOpen ? "w-[240px]" : "w-[72px]"} flex-col  justify-between border-r-2 border-black-10 bg-black-secondary-strong p-4`}
        >
            <div className="flex flex-col gap-6">
                <div
                    onClick={sidebarHandler}
                    className="svg-container flex cursor-pointer p-2.5 hover:rounded-lg hover:bg-black-10"
                >
                    {sidebarIsOpen ? (
                        <div className="sidebar-container flex flex-row items-center justify-start gap-2.5">
                            <CloseIcon/>
                            <div>{t("CloseMenu")}</div>
                        </div>
                    ) : (
                        <HamburgerMenu/>
                    )}
                </div>
                <div>
                    {filteredSidebar.map((menuItem) => {
                        const isActive = location.pathname === menuItem.path;

                        return (
                            <Link
                                to={`${menuItem.path}`}
                                key={menuItem.title}
                                className={`flex flex-row ${sidebarIsOpen ? "justify-start" : "justify-center"} 
                            svg-container mb-2.5 items-center gap-2 p-2.5 hover:rounded-lg hover:bg-black-0 
                            ${isActive ? "active-svg rounded-lg bg-dark-green-10 text-dark-green" : ""} 
                            sidebar-container`}
                            >
                                {menuItem.icon}
                                {sidebarIsOpen && menuItem.title}
                            </Link>
                        );
                    })}
                </div>
            </div>
            <div>
                <LanguagesDropdown
                    showIcon={sidebarIsOpen}
                    dropdownTop={true}
                    showText={false}
                    showFullText={sidebarIsOpen}
                    isLoginPage={false}
                    isOpen={!sidebarIsOpen}
                />
            </div>
        </div>
    );
};

export default Sidebar;
