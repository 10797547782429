import {useTranslation} from "react-i18next";

import "./SettingsPage.css";
import BasicInput from "../../components/inputs/BasicInput";
import LockIcon from "./res/LockIcon";
import EditIcon from "./res/EditIcon";
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal/ChangePasswordModal";
import React, {useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading/Loading.tsx";
import {DateTimeHelper} from "../../utils/DateTimeHelper.ts";
import {axiosInstance} from "../../config/appConfig.ts";
import {UserDetailResponseDto} from "../../dtos/identity/UserDetailResponseDto.ts";
import profilePicture from "../../assets/png/Profile-picture.png";
import toast from "react-hot-toast";
import {useAppSelector} from "../../redux/hooks/hooks.ts";
import {selectUserType} from "../../redux/slices/authSlice.ts";

const SettingsPage = () => {
    const {t} = useTranslation();
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>("");
    const [userDetails, setUserDetails] = useState<UserDetailResponseDto | null>(null);
    const userType = useAppSelector(selectUserType);
    
    let ref = useRef<any | null>();

    const handleChangePassword = () => {
        setChangePasswordModalOpen(true);
    };

    const handleChangePasswordClose = () => {
        setChangePasswordModalOpen(false);
    };

    const handleChooseFile = (e: React.MouseEvent<HTMLDivElement>) => {
        ref.current.click();

    };

    const handleChangedFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        
        try {
            setIsSubmitting(true);
            const formData = new FormData();
            formData.append("file", e.target.files[0] as Blob);

            const response = await axiosInstance.put("identity/ChangeAvatar", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                console.log("Avatar changed successfully");
                await fetchData();
                return
            }

            throw new Error("Avatar change failed");
        } catch (e: any) {
            console.log(e);
            setError(e.message);
            toast.error(t("AvatarChangeFailed"));
        } finally {
            setFile(null);
            setIsSubmitting(false);
        }
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.get<UserDetailResponseDto>("identity/UserDetails");
            setUserDetails(response.data);
        } catch (e: any) {
            setError(e.message)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div className="px-4 py-6 md:px-0 md:py-0">
            <div className="mb-6">
                <div className="font-myriad text-2xl font-regular text-black-primary">
                    {t("Settings")}
                </div>
            </div>
            <Loading type={"cover"} loading={isSubmitting}>
                <div
                    className="flex w-full flex-col items-center justify-center gap-2 rounded-2xl bg-black-secondary-strong p-6 md:border-[1px] md:border-black-10">
                    <div className="hover:cursor-pointer"
                         onClick={(e) => handleChooseFile(e)}>
                        <div className={"relative"}>
                            <img className={"w-20 h-20 rounded-full"} src={userDetails?.avatarUrl ?? profilePicture}
                                 alt={"avatar"}/>
                            <div className={"absolute bottom-0 right-0"}>
                                <EditIcon/>
                            </div>
                        </div>
                    </div>
                    <div className={"mt-5"}>
                        <input type={"file"} className={"hidden"} ref={ref} onChange={(e) => handleChangedFile(e)}/>
                    </div>
                    <div
                        className="cursor-pointer font-myriad text-sm font-regular text-dark-green hover:underline"
                        onClick={handleChangePassword}
                    >
                        {t("ChangePassword")}
                    </div>
                    <div>
                        <BasicInput
                            label={t("UserType")}
                            placeholder={userType}
                            width={328}
                            height={44}
                            icon={<LockIcon/>}
                            multiline={false}
                            disabled
                        />
                    </div>
                    <div>
                        <BasicInput
                            label={t("FullName")}
                            placeholder={userDetails?.fullName}
                            width={328}
                            height={44}
                            icon={<LockIcon/>}
                            disabled
                        />
                    </div>
                    <div>
                        <BasicInput
                            label={t("Department")}
                            placeholder={userDetails?.departmentName}
                            width={328}
                            height={44}
                            icon={<LockIcon/>}
                            disabled
                        />
                    </div>
                    <div>
                        <BasicInput
                            label={t("Email")}
                            placeholder={userDetails?.email}
                            width={328}
                            height={44}
                            icon={<LockIcon/>}
                            disabled
                        />
                    </div>
                    <div>
                        <BasicInput
                            label={t("PhoneNumber")}
                            placeholder={userDetails?.phoneNumber}
                            width={328}
                            height={44}
                            icon={<LockIcon/>}
                            disabled
                        />
                    </div>
                    <div>
                        <BasicInput
                            label={t("TIN")}
                            placeholder={userDetails?.tin}
                            width={328}
                            height={44}
                            icon={<LockIcon/>}
                            disabled
                        />
                    </div>

                </div>
            </Loading>
            {changePasswordModalOpen && (
                <ChangePasswordModal
                    show={changePasswordModalOpen}
                    onClose={handleChangePasswordClose}
                />
            )}
        </div>
    );
};

export default SettingsPage;
