
import {axiosInstance} from "../config/appConfig.ts";
import {UrlHelper} from "../helpers/UrlHelper.ts";
import { useState } from "react";
import { AverageTasksDto } from "../dtos/dashboard/AverageTasks.ts";
import PeriodType from "../types/PeriodType.ts";
import DetailsResponseDto from "../dtos/BaseResponse/DetailsResponseDto.ts";
import { DiagramTasksDto } from "../dtos/dashboard/DiagramTaskDto.tsx";
import { DashboardFilter } from "../pages/DashboardPage/types.tsx";

type Props = {}
const useFetchDashboard = ({}: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [newTasks, setNewTasks] = useState<AverageTasksDto | undefined>(undefined);
    const [taskInProgress, setTaskInProgress] = useState<AverageTasksDto | undefined>(undefined);
    const [allTasks, setAllTasks] = useState<AverageTasksDto | undefined>(undefined);
    const [tasksByType, setTasksByType] = useState<DiagramTasksDto | undefined>(undefined);
    const [tasksByStatuses, setTasksByStatuses] = useState<DiagramTasksDto | undefined>(undefined);
    const [tasksBySource, setTasksBySource] = useState<DiagramTasksDto | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    
    const loadDashboardNewTask = async (filter?: DashboardFilter) => {
        try {
            setIsLoading(true);

            const url = UrlHelper.generateUrlWithParams("Dashboard/NewTasks", {
                periodType: filter?.periodType,
                dateFrom: filter?.dateFrom,
                dateTo: filter?.dateTo,
            });
            const response = await axiosInstance.get<DetailsResponseDto<AverageTasksDto>>(url, {
                validateStatus: (status) => status < 500
            });

            if (response.data.success) {
                setNewTasks(response.data.item)
                return response.data.item;
            } else {
                throw new Error("error fetching new tasks")
            }

        } catch (e: any) {
            console.log(e);
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    const loadDashboardTaskInProgress = async (filter?: DashboardFilter) => {
        try {
            setIsLoading(true);

            const url = UrlHelper.generateUrlWithParams("Dashboard/TaskInProgress", {
                periodType: filter?.periodType,
                dateFrom: filter?.dateFrom,
                dateTo: filter?.dateTo,
            });
            const response = await axiosInstance.get<DetailsResponseDto<AverageTasksDto>>(url, {
                validateStatus: (status) => status < 500
            });

            if (response.data.success) {
                setTaskInProgress(response.data.item)
                return response.data.item;
            } else {
                throw new Error("error fetching tasks in progress")
            }

        } catch (e: any) {
            console.log(e);
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    const loadDashboardAllTasks = async (filter?: DashboardFilter) => {
        try {
            setIsLoading(true);

            const url = UrlHelper.generateUrlWithParams("Dashboard/AllTasks", {
                periodType: filter?.periodType,
                dateFrom: filter?.dateFrom,
                dateTo: filter?.dateTo,
            });
            const response = await axiosInstance.get<DetailsResponseDto<AverageTasksDto>>(url, {
                validateStatus: (status) => status < 500
            });

            if (response.data.success) {
                setAllTasks(response.data.item)
                return response.data.item;
            } else {
                throw new Error("error fetching all tasks")
            }

        } catch (e: any) {
            console.log(e);
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    const loadDashboardTaskByTypes = async (filter?: DashboardFilter) => {
        try {
            setIsLoading(true);

            const url = UrlHelper.generateUrlWithParams("Dashboard/TaskByTypesDiagram", {
                periodType: filter?.periodType,
                dateFrom: filter?.dateFrom,
                dateTo: filter?.dateTo,
            });
            const response = await axiosInstance.get<DetailsResponseDto<DiagramTasksDto>>(url, {
                validateStatus: (status) => status < 500
            });

            if (response.data.success) {
                setTasksByType(response.data.item)
                return response.data.item;
            } else {
                throw new Error("error fetching all tasks")
            }

        } catch (e: any) {
            console.log(e);
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }
    const loadDashboardTaskByStatuses = async (filter?: DashboardFilter) => {
        try {
            setIsLoading(true);

            const url = UrlHelper.generateUrlWithParams("Dashboard/TaskByStatusesDiagram", {
                periodType: filter?.periodType,
                dateFrom: filter?.dateFrom,
                dateTo: filter?.dateTo,
            });
            const response = await axiosInstance.get<DetailsResponseDto<DiagramTasksDto>>(url, {
                validateStatus: (status) => status < 500
            });

            if (response.data.success) {
                setTasksByStatuses(response.data.item)
                return response.data.item;
            } else {
                throw new Error("error fetching all tasks")
            }

        } catch (e: any) {
            console.log(e);
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    const loadDashboardTaskBySources = async (filter?: DashboardFilter) => {
        try {
            setIsLoading(true);

            const url = UrlHelper.generateUrlWithParams("Dashboard/TaskBySourcesDiagram", {
                periodType: filter?.periodType,
                dateFrom: filter?.dateFrom,
                dateTo: filter?.dateTo,
            });
            const response = await axiosInstance.get<DetailsResponseDto<DiagramTasksDto>>(url, {
                validateStatus: (status) => status < 500
            });

            if (response.data.success) {
                setTasksBySource(response.data.item)
                return response.data.item;
            } else {
                throw new Error("error fetching all tasks")
            }

        } catch (e: any) {
            console.log(e);
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    

    return {
        loadDashboardNewTask,
        loadDashboardTaskInProgress,
        loadDashboardAllTasks,
        loadDashboardTaskByTypes,
        loadDashboardTaskByStatuses,
        loadDashboardTaskBySources,
        tasksBySource,
        tasksByStatuses,
        tasksByType,
        allTasks,
        taskInProgress,
        error,
        isLoading,
        newTasks,
    };
};

export default useFetchDashboard;
