import { useMemo, useState } from "react";
import { EmployeeDto } from "../dtos/employees/EmployeeDto";
import { DepartmentDto } from "../dtos/departments/DepartmentDto";
import { UrlHelper } from "../helpers/UrlHelper";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto";
import { axiosInstance } from "../config/appConfig";

const useFetchDepartment = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setDepartmnets] = useState<DepartmentDto[] | []>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const departmentOptions = useMemo(() => {
        return departments.map((employee: DepartmentDto) => ({
            label: employee.description ?? "",
            value: employee.id ?? "",
        })) ?? [];
    }, [departments]);

    const fetchDepartments = async (searchText?: string, skip?: number, take?: number) => {
        try {
            setIsLoading(true);
            setError(undefined)

            const url = UrlHelper.generateUrlWithParams("Departments", {
                searchText,
                skip,
                take,
            });
            const response = await axiosInstance.get<ListResponseDto<DepartmentDto>>(url, {
                validateStatus: (status) => status < 500
            });
            if (response?.data?.success) {
                setDepartmnets(response.data.items);
                setTotalCount(response.data.totalCount);
                return response.data.items;
            }

            throw new Error(response.data.errorMessage ?? "API error");
        } catch (error: any) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    };

    // const createEmployee = (newEmployee: EmployeeDto) => {
    //     try {
    //         setIsSubmitting(true);

    //         if (newEmployee) {
    //             const filteredEmployees = [...departments, newEmployee];
    //             setDepartmnets(filteredEmployees);
    //         }
    //     } catch (error: any) {
    //         setError(error)
    //     } finally {
    //         setIsSubmitting(false);
    //     }
    // };

    // const deleteEmployee = (employeeId: number) => {
    //     try {
    //         setIsSubmitting(true);

    //         const filteredEmployees = departments.filter(employee => +employee.id !== +employeeId);
    //         setDepartmnets(filteredEmployees);
    //         console.log("sucsesfly removed")
    //     } catch (error: any) {
    //         setError(error)
    //     } finally {
    //         setIsSubmitting(false);
    //     }

    // }

    return {
        fetchDepartments,
        isLoading,
        departments,
        error,
        isSubmitting,
        departmentOptions,
        totalCount,
    }

};

export default useFetchDepartment;