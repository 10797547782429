import React, {FC, PropsWithChildren} from 'react';

type Props = {
    label: string;
    cssClass?: string;
    extraClassName?: string;
    onItemClick?: () => void;
    onInternalClick?: () => void;
    selected?: boolean;
    width?: string;
}

const PageHeaderSelectOption: FC<PropsWithChildren<Props>> = ({
                                                                  extraClassName,
                                                                  selected,
                                                                  label,
                                                                  cssClass,
                                                                  onItemClick,
                                                                  onInternalClick,
                                                                  children,
                                                                  width
                                                              }) => {


    const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onInternalClick && onInternalClick();
        onItemClick && onItemClick();
    };

    return (
        <React.Fragment>
            <li className={`${width}`}>
                <a className={`${cssClass ? cssClass : `rounded-t hover:bg-black-10 py-2 px-4 block whitespace-no-wrap ${selected ? 'bg-dark-green-10' : ''} ${extraClassName}`}`}
                   href="#" onClick={(e) => handleOnClick(e)}>
                    {children && children}
                    {!children && label}
                </a>
            </li>
        </React.Fragment>
    )
        ;
};

export default PageHeaderSelectOption;