const LogoutIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8281 12.5999C15.3448 6.5999 18.4281 4.1499 25.1781 4.1499H25.3948C32.8448 4.1499 35.8281 7.13324 35.8281 14.5832V25.4499C35.8281 32.8999 32.8448 35.8832 25.3948 35.8832H25.1781C18.4781 35.8832 15.3948 33.4666 14.8448 27.5666"
        stroke="#00A9B5"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9979 20H6.03125"
        stroke="#00A9B5"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75521 14.4165L4.17188 19.9998L9.75521 25.5832"
        stroke="#00A9B5"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
