import React, {FC, PropsWithChildren, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import ReloadIcon from "../../assets/svg/ReloadIcon.tsx";
import {useNavigate} from "react-router-dom";
import {ThemeColors} from "../../theme/colors.ts";

type Props = {
    errorMessages: (string | undefined)[];
    onReloadClick?: () => void;
}

const AlertDanger: FC<PropsWithChildren<Props>> = ({
                                                       errorMessages,
                                                       onReloadClick
                                                   }) => {
    const {t} = useTranslation();
    const handleReloadPage = () => {
        if (onReloadClick) {
            onReloadClick();
            return;
        }
        window.location.reload()
    };

    if (!errorMessages || errorMessages.length === 0) return null;
    let errorIsEmpty = true;
    errorMessages.forEach((message) => {
        if (message && message !== "") {
            errorIsEmpty = false;
        }
    });
    if (errorIsEmpty) return null;

    return (
        <>
            <div className="bg-red-100 border border-red-400 text-red-primary px-4 py-3 rounded relative" role="alert">
                <span className="font-bold block sm:inline">Something seriously bad happened.</span>
                {errorMessages.map((message, index) => {
                    if (!message || message === "") return null;
                    return (
                        <p key={index}>{message}</p>
                    )
                })}
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer" onClick={handleReloadPage}>
                    <ReloadIcon color={ThemeColors.redPrimary} strokeColor={ThemeColors.redPrimary}/>
                </span>
            </div>
        </>
    );
};

export default AlertDanger;