import DashboardIcon from "../../../assets/svg/sidebarIcons/DashboardIcon";
import { IMenuItemsDto } from "../../../dtos/IMenuItems/IMenuItemsDto";

export const AdminPanelMenu:IMenuItemsDto[] =
    [
        {
            path: "/tasks-type",
            title: "TaskType",
            active: false,
            icon: <DashboardIcon/>,
            items: [],
        },
        {
            path: "/employees",
            title: "Employees",
            active: false,
            icon: <DashboardIcon/>,
            items: [],
        },
        {
            path: "/tasks-sources",
            title: "TaskSources",
            active: false,
            icon: <DashboardIcon/>,
            items: [],
        },
        {
            path: "/predefined-task-description",
            title: "PredefinedTaskDescription",
            active: false,
            icon: <DashboardIcon/>,
            items: [],
        },
        {
            path: "/department",
            title: "Department",
            active: false,
            icon: <DashboardIcon/>,
            items: [],
        },
        {
            path: "/services",
            title: "Services",
            active: false,
            icon: <DashboardIcon/>,
            items: [],
        },
        {
            path: "/locations",
            title: "Locations",
            active: false,
            icon: <DashboardIcon/>,
            items: [],
        },
    ];
