import React, {FC, Fragment, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import useMediaQuery from "../../hooks/useMediaQuery.ts";
import DataTablePaginationRegular from "./DataTablePaginationRegular.tsx";
import {ThemeText} from '../../theme/text.ts';
import {NavLink} from 'react-router-dom';

type Props = {
    extraClassName?: string;
    rowHeight?: string;
    showHeader?: boolean;
    roundedTop?: boolean;
    roundedBottom?: boolean;
    columns: DataTableColumn[];
    pageSize: number;
    currentPage: number;
    allowedPageSizes?: number[];
    items?: any[];
    totalCount: number;
    isHoverable?: boolean;
    noItemsTitle?: string;
    onReload?: (pageSize: number, pageNumber: number) => void;
    onClick?: (item: any) => void;
    onDoubleClick?: (item: any) => void;
    onItemSelected?: (item: any) => void;
    selectedItem?: any;
    allowSelection?: boolean;
    renderMobileRow?: (columns: DataTableColumn[], item: any) => void;
    headerText?: string;
    headerLink?: string;
    headerLinkText?: string;
    showHeaderWithLink?: boolean;
    showPagination?: boolean;
    rowPadding?: string;
}

const DataTable: FC<PropsWithChildren<Props>> = ({
                                                     extraClassName,
                                                     showHeader = true,
                                                     roundedTop = true,
                                                     roundedBottom = true,
                                                     children,
                                                     rowHeight,
                                                     items,
                                                     columns,
                                                     pageSize = 20,
                                                     currentPage = 1,
                                                     isHoverable = true,
                                                     renderMobileRow,
                                                     totalCount,
                                                     noItemsTitle,
                                                     allowedPageSizes = [5, 10, 20,],
                                                     onReload,
                                                     onClick,
                                                     onDoubleClick,
                                                     onItemSelected,
                                                     allowSelection,
                                                     headerText,
                                                     headerLink,
                                                     headerLinkText,
                                                     showHeaderWithLink = false,
                                                     showPagination = false,
                                                     selectedItem
                                                 }) => {
    const {t} = useTranslation();
    const isMobile = useMediaQuery("(max-width: 640px)");
    // const [selectedItem, setSelectedItem] = useState<any | undefined>(undefined);
    const totalPages = Math.ceil(totalCount / pageSize);

    // useEffect(() => {
    //     onItemSelected && onItemSelected(selectedItem);
    // }, [selectedItem]);

    // useEffect(() => {
    //     console.log("1111", defaultSelectedItem)
    //     setSelectedItem(defaultSelectedItem);
    // }, [defaultSelectedItem]);

    if (!columns || !columns.length) {
        return <>No columns in the table</>;
    }

    const columnCount = columns.length;

    function handleDoubleClick(item: any) {
        onDoubleClick && onDoubleClick(item);
    }

    const handleClick = (item: any) => {
        onClick && onClick(item);

        if (allowSelection) {
            if (selectedItem === item) {
                onItemSelected && onItemSelected(undefined);
            } else {
                onItemSelected && onItemSelected(item);
            }
        }
    }

    function formatColumn(column: DataTableColumn, item: any) {
        if (column.format) {
            return column.format(item);
        }

        if (column.type === "quantity") {
            return item?.toFixed(3) ?? "0.000";
        }

        if (column.type === "amount") {
            return item?.toFixed(2) ?? "0.00";
        }

        return item;
    }

    const renderHeader = useCallback(() => {
        return (
            <div className={"flex justify-between px-6 py-4 border-b-[1px] border-black-10 h-[70px]"}>
                <span className={ThemeText.cardTitle}>{headerText}</span>
                {headerLink && (
                    <NavLink
                        className={"font-myriad font-regular text-sm link text-dark-green no-underline hover:underline"}
                        to={headerLink}>{headerLinkText}</NavLink>
                )}
            </div>
        );
    }, [headerText, headerLink, headerLinkText]);

    const renderDesktopVersion = () => {
        return (
            <div
                className={`bg-white ${roundedTop ? "rounded-t-2xl" : ""} ${roundedBottom ? "rounded-b-2xl" : ""} overflow-auto ${extraClassName}`}>
                {showHeaderWithLink && renderHeader()}
                <table className={"table"}>
                    {showHeader && <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}
                                className={`font-myriad text-sm font-regular text-black-primary px-6 py-4 ${column.width}`}>{column.title}</th>
                        ))}
                    </tr>
                    </thead>}
                    <tbody>
                    {(!items || items.length === 0) &&
                        <tr>
                            <td colSpan={columnCount}>
                                <div className="flex items-center justify-center px-6 py-5">
                                    <span>{noItemsTitle ?? t("NoItems")}</span>
                                </div>
                            </td>
                        </tr>}
                    {items && items.length > 0 && (
                        <>
                            {items.map((item, itemIndex) => (
                                <tr key={itemIndex}
                                    className={`h-[70px] ${isHoverable ? "hover:bg-black-10 hover:cursor-pointer" : ""} ${selectedItem === item ? "bg-black-10" : ""}`}
                                    onDoubleClick={() => handleDoubleClick(item)} onClick={() => handleClick(item)}
                                >
                                    <Fragment>
                                        {columns.map((column, index) => {
                                            if (column.render) {
                                                return (
                                                    <td key={index}
                                                        className={`font-myriad text-sm font-regular text-black-60 `}>
                                                        {column.render(item)}
                                                    </td>
                                                )
                                            }

                                            let additionalClasses = "";
                                            if (column.type === "number" || column.type === "amount" || column.type === "quantity") {
                                                additionalClasses += "text-center";
                                            }

                                            return (
                                                <td key={index}
                                                    className={`font-myriad text-sm font-regular text-black-60 ${additionalClasses}`}>
                                                    {formatColumn(column, item[column.field])}
                                                </td>
                                            )
                                        })}
                                    </Fragment>
                                </tr>
                            ))}
                        </>
                    )}
                    </tbody>
                </table>
                {showPagination && (
                    <DataTablePaginationRegular allowedPageSizes={allowedPageSizes}
                                                currentPageSize={pageSize}
                                                totalPagesCount={totalPages}
                                                onSetPageSize={(e: any, newPageSize: number) => onReload && onReload(newPageSize, currentPage)}
                                                currentPage={currentPage}
                                                onSetCurrentPage={(e: any, newPageNumber: number) => onReload && onReload(pageSize, newPageNumber)}
                    />
                )}
            </div>
        )
    }

    const renderDefaultMobileRow = (item: any) => {
        return (
            <div className={""}>
                <div className={"flex flex-col"}>
                    {columns.map((column, index) => (
                        <div key={index} className={"flex flex-row items-top justify-between py-1"}>
                            <span
                                className={"font-myriad text-sm font-semibold text-black-60 me-2"}>{column.title}:</span>
                            <span className={"font-myriad text-sm font-regular text-black-60"}>
                                {column.render ? column.render(item) : ""}
                                {!column.render && formatColumn(column, item[column.field])}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const renderMobileVersion = () => {
        return (
            <>
                <div className={"grid grid-cols-1 border-[1px] rounded-lg"}>
                    {(!items || items.length === 0) &&
                        <div className="flex items-center justify-center px-6 py-5">
                            <span>{noItemsTitle ?? t("NoItems")}</span>
                        </div>
                    }
                    {items && items.length > 0 && (
                        <>
                            {items.map((item, itemIndex) => (
                                <div key={itemIndex}
                                     className={`px-3 py-3 ${itemIndex === 0 ? "" : "border-t-[1px]"} min-h-[48px] hover:cursor-pointer ${isHoverable ? "hover:bg-black-10" : ""}  ${selectedItem === item ? "bg-black-10" : ""}`}
                                     onDoubleClick={() => handleDoubleClick(item)} onClick={() => handleClick(item)}>
                                    <>
                                        {renderMobileRow && renderMobileRow(columns, item)}
                                        {!renderMobileRow && renderDefaultMobileRow(item)}
                                    </>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <DataTablePaginationRegular allowedPageSizes={allowedPageSizes}
                                            currentPageSize={pageSize}
                                            totalPagesCount={totalPages}
                                            onSetPageSize={(e: any, newPageSize: number) => onReload && onReload(newPageSize, currentPage)}
                                            currentPage={currentPage}
                                            onSetCurrentPage={(e: any, newPageNumber: number) => onReload && onReload(pageSize, newPageNumber)}
                />
            </>
        )
    }

    if (isMobile) {
        return renderMobileVersion();
    }

    return renderDesktopVersion();
}


export default DataTable;