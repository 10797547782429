import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OngoingTasksView } from "../../types/OngoingTasksView";
import { RootState } from "../store";

type TasksViewType = {
    ongoingTasksView: OngoingTasksView;
}

const initialState: TasksViewType = {
    ongoingTasksView: "Cards",
}

const ongoingTasksSlice = createSlice({
    name: "tasksView",
    initialState,
    reducers: {
        setTasksView: (state, action: PayloadAction<OngoingTasksView>) => {
            state.ongoingTasksView = action.payload;
        },
    }
});

export const { setTasksView } = ongoingTasksSlice.actions;

export const selectOngoingTasksView = (state: RootState) => state.tasksView.ongoingTasksView;

export const ongoingTasksReducer = ongoingTasksSlice.reducer;