import {PropsWithChildren} from "react";

const MainContent = ({children}: PropsWithChildren) => {
    return (
        <div className="min-h-full w-full bg-black-secondary-strong md:bg-black-0 md:p-8">
            {children}
        </div>
    );
};

export default MainContent;
