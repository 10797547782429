import {FC, PropsWithChildren} from "react";
import {ThemeColors} from "../../../theme/colors.ts";

type Props = {
    color?: string;
    strokeColor?: string;
    width?: number;
    height?: number;
    disabled?: boolean;
};

const FilterApartmentsIcon: FC<PropsWithChildren<Props>> = ({
                                                                disabled,
                                                                color = ThemeColors.whiteColor,
                                                                strokeColor = ThemeColors.mainColor,
                                                                width = 20,
                                                                height = 20
                                                            }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill={disabled ? ThemeColors.disabledColor : color}
        >
            <path
                d="M6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6C8 7.10457 7.10457 8 6 8Z"
                stroke={disabled ? ThemeColors.whiteColor : strokeColor}
                strokeWidth="1.25"
            />
            <path
                d="M14 16C15.1046 16 16 15.1046 16 14C16 12.8954 15.1046 12 14 12C12.8954 12 12 12.8954 12 14C12 15.1046 12.8954 16 14 16Z"
                stroke={disabled ? ThemeColors.whiteColor : strokeColor}
                strokeWidth="1.25"
            />
            <path
                d="M8 6L16 6"
                stroke={disabled ? ThemeColors.whiteColor : strokeColor}
                strokeWidth="1.25"
                strokeLinecap="round"
            />
            <path
                d="M12 14L4 14"
                stroke={disabled ? ThemeColors.whiteColor : strokeColor}
                strokeWidth="1.25"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default FilterApartmentsIcon;
