import React, {FC, PropsWithChildren, ReactNode} from 'react';
import BaseModal from "../Modals/BaseModal.tsx";

type Props = {
    onClose: () => void;
    onYes: () => void;
    title: string;
    content?: string;
    yesButtonText?: string;
    noButtonText?: string;
    type?: "danger" | "regular";
    icon?: ReactNode;
}

const QuestionModal: FC<PropsWithChildren<Props>> = ({
                                                         icon,
                                                         onClose,
                                                         onYes,
                                                         type = "regular",
                                                         title,
                                                         content,
                                                         yesButtonText = "Yes",
                                                         noButtonText = "No"
                                                     }) => {
    return (
        <>
            <BaseModal>
                <div
                    className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 md:relative">
                    <div className="md:flex items-center">
                        <div
                            className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                            {/*<i className="bx bx-error text-3xl"></i>*/}
                            {icon}
                        </div>
                        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                            <p className="font-bold">{title}</p>
                            <p className="text-sm text-gray-700 mt-1">{content}
                            </p>
                        </div>
                    </div>
                    <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                        <button onClick={onYes}
                                className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 
                                text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2 hover:bg-red-80"
                        >
                            {yesButtonText}
                        </button>
                        <button onClick={onClose}
                                className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 
                                rounded-lg font-semibold text-sm mt-4 hover:bg-black-40 md:mt-0 md:order-1">
                            {noButtonText}
                        </button>
                    </div>
                </div>
            </BaseModal>
        </>
    );
};

export default QuestionModal;