import React, {FC, PropsWithChildren, ReactNode} from 'react';
import {useFormContext} from "react-hook-form";
import {FormControlBaseProps} from "../../types/Forms/FormControlBaseProps.ts";
import {useTranslation} from "react-i18next";

type Props = {
    width?: string;
    type?: "text" | "number" | "password" | "email" | "tel" | "url";
    icon?: ReactNode;
} & FormControlBaseProps

const FormTextInput: FC<PropsWithChildren<Props>> = ({
                                                         placeholder,
                                                         label,
                                                         fieldName,
                                                         width,
                                                         type = "text",
                                                         isRequired,
                                                         validationMessage,
                                                         validationRegex,
                                                         extraCssClass,
                                                         icon,
                                                         readOnly,
                                                     }) => {
    const {register, formState: {errors}} = useFormContext();
    const {t} = useTranslation();
    const cssWidth = () => {
        if (width) {
            return `w-${width}`;
        }

        return "";
    }

    return (
        <>
            <   div className="relative">
                {label && <label className={"font-myriad font-regular text-xs text-black-primary"}>{label}</label>}
                <input
                    readOnly={readOnly}
                    type={type}
                    className={`flex h-[36px] ${cssWidth()} font-myriad text-xs font-regular items-center justify-center rounded border-[1px] border-black-10 p-3.5 md:rounded-lg hover:border-dark-green dark:[color-scheme:light] ${extraCssClass}`}
                    placeholder={placeholder} {...register(fieldName, {
                    required: isRequired,
                    pattern: validationRegex && {
                        value: validationRegex,
                        message: validationMessage ?? "Invalid input"
                    }
                })}
                />
                {icon && (
                    <div className="absolute right-4 top-8">
                        {icon}
                    </div>
                )}
                {errors[fieldName] && <span
                    className={"text-red-500 text-xs"}>{validationMessage ? validationMessage : t("ThisFieldIsRequired")}</span>}
            </div>
        </>
    );
};

export default FormTextInput;