import {useTranslation} from "react-i18next";

import PageHeader from "../../components/PageHeader/PageHeader.tsx";
import PageContainer from "../../components/PageContainer/PageContainer.tsx";
import DashboardCardCircleChartTaskBySources from "./components/DashboardCardCircleChartTaskBySources.tsx";
import DashboardCardCircleChartTaskByStatuses from "./components/DashboardCardCircleChartTaskByCategories.tsx";
import DashboardCardCircleChartTaskByTypes from "./components/DashboardCardCircleChartTaskByTypes.tsx";
import DashboardCardAllTasks from "./components/DashboardCardAllTasks.tsx";
import DashboardTaskInProgress from "./components/DashboardTaskInProgress.tsx";
import DashboardTaskNewTasks from "./components/DashboardTaskNewTasks.tsx";
import {useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/hooks/hooks.ts";
import {DashboardFilter} from "./types.tsx";
import FilterByDateDropdown from "../../components/DropDowns/FilterByDateDropdown/FilterByDateDropdown.tsx";
import {endOfDay, format, startOfDay} from "date-fns";
import {defaultDateFormat} from "../../config/appConfig.ts";
import PeriodFilterModal from "./modals/PeriodFilterModal.tsx";
import Loading from "../../components/Loading/Loading.tsx";
import DataTable from "../../components/DataTable/DataTable.tsx";
import useFetchData from "../../hooks/useFetchData.ts";
import CreateEmployeeTaskDto from "../../dtos/EmployeeTask/CreateEmployeeTaskDto.ts";
import EmployeeTaskDto from "../../dtos/EmployeeTask/EmployeeTaskDto.ts";
import TasksOngoingDetailsModal from "../TasksPage/modals/TasksDetailsModal.tsx";
import {selectTasksRefreshIndex} from "../../redux/slices/tasksSlice.ts";
import {DateHelper} from "../../helpers/formatDate.ts";
import {EnumHelper} from "../../helpers/enumHelper.tsx";
import {selectDashboardFilter, setDashboardFilter} from "../../redux/slices/appSlice.ts";

const DashboardPage = () => {
    const {t} = useTranslation();
    const [openedPeriodModal, setOpenedPeriodModal] = useState(false);
    const [isOpenedModal, setIsOpenedModal] = useState(false);

    const [selectedTaskId, setSelectedTaskId] = useState("")

    const filter = useAppSelector(selectDashboardFilter);
    const tasksRefreshIndex = useAppSelector(selectTasksRefreshIndex);
    const dispatch = useAppDispatch();
    const {
        fetchItems,
        items,
        isLoading,
        totalCount,
    } = useFetchData<CreateEmployeeTaskDto, EmployeeTaskDto, EmployeeTaskDto>("EmployeeTasks");

    const handleOpenPeriodModal = () => {
        setOpenedPeriodModal(!openedPeriodModal);
    }

    const handleApplyFilter = (data: DashboardFilter) => {
        dispatch(setDashboardFilter(data));
    }

    const handleDoubleClick = useCallback((task: EmployeeTaskDto) => {
        setIsOpenedModal(true);
        setSelectedTaskId(task.id)
    }, []);

    const handleCloseModal = () => {
        setIsOpenedModal(false);
        setSelectedTaskId("")
    }

    const columns: DataTableColumn[] = [
        {
            title: t("Location"),
            field: "apartmentRoomNo",
            render: (task: EmployeeTaskDto) => {
                return (
                    <div>
                        {task.apartmentRoomNo || task.locationName}
                    </div>
                );
            }
        },
        {
            title: t("Service"),
            field: "departmentDescription"
        },
        {
            title: t("Status"),
            field: "",
            render: (task: EmployeeTaskDto) => {
                return (
                    <div className="ml-2">
                        {EnumHelper.getEmployeeTaskStatusIcon(task.status)}
                    </div>
                );
            }
        },
        {
            title: t("Employee"),
            field: "assignedEmployeeFullName"
        },
        {
            title: t("DateStart"),
            field: "",
            render: (task: EmployeeTaskDto) => {
                return (
                    <div>
                        {t(DateHelper.formatDefaultDate(task.availableFrom))}
                    </div>
                );
            }
        },
        {
            title: t("Deadline"),
            field: "",
            render: (task: EmployeeTaskDto) => {
                return (
                    <div>
                        {t(DateHelper.formatDefaultDate(task.deadline))}
                    </div>
                );
            }
        },
        {
            title: t("Source"),
            field: "sourceDescription"
        },
    ]

    const fetchData = useCallback(async () => {
        await fetchItems(undefined, 0, 5, undefined);
    }, []);

    useEffect(() => {
        const newFilter: DashboardFilter = {
            dateFrom: filter?.dateFrom,
            dateTo: filter?.dateTo,
            periodType: filter?.periodType,
        };

        dispatch(setDashboardFilter(newFilter));
    }, [tasksRefreshIndex]);

    useEffect(() => {
        fetchData();
    }, [tasksRefreshIndex])

    return (
        <>
            <PageHeader title={t("Dashboard")}>
                {/*<div className="text-dark-green cursor-pointer p-1 text-sm flex items-center gap-2">*/}
                {/*    <div onClick={handleOpenPeriodModal}>*/}
                {/*        {`${filter?.dateFrom ? format(filter?.dateFrom, defaultDateFormat) : t("NoStartDate")} - ${filter?.dateTo ? format(filter?.dateTo, defaultDateFormat) : t("NoEndDate")}`}*/}
                {/*    </div>*/}
                {/*    <FilterByDateDropdown/>*/}
                {/*</div>*/}
            </PageHeader>
            <PageContainer>
                <div className="flex flex-col sm:flex-row flex-wrap sm:items-center justify-center gap-3 mb-5">
                    <div className={"flex-1"}>
                        <DashboardTaskNewTasks cardType={"top"}/>
                    </div>
                    <div className={"flex-1"}>
                        <DashboardTaskInProgress cardType={"top"}/>
                    </div>
                    <div className={"flex-1"}>
                        <DashboardCardAllTasks cardType={"top"}/>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-wrap sm:items-center justify-center gap-0 sm:gap-3 mb-5">
                    <div className={"flex-1"}>
                        <DashboardCardCircleChartTaskBySources
                            title={t("TasksBySources")}
                        />
                    </div>
                    <div className={"flex-1"}>
                        <DashboardCardCircleChartTaskByStatuses
                            title={t("TasksByStatuses")}
                        />
                    </div>
                    <div className={"flex-1"}>
                        <DashboardCardCircleChartTaskByTypes
                            title={t("TasksByTypes")}
                        />
                    </div>
                </div>

                <div>
                    <Loading loading={isLoading}>
                        <DataTable
                            columns={columns}
                            items={items}
                            currentPage={0}
                            totalCount={totalCount}
                            showHeaderWithLink={true}
                            headerText={t("LatestTasks")}
                            headerLinkText={t("SeeAll")}
                            headerLink="/tasks"
                            onDoubleClick={handleDoubleClick}
                            pageSize={5}
                            showHeader={true}
                        />
                    </Loading>
                </div>
            </PageContainer>

            {openedPeriodModal && (
                <PeriodFilterModal
                    onClose={handleOpenPeriodModal}
                    filter={filter}
                    onApplyFilter={(data) => handleApplyFilter(data)}
                />
            )}

            {isOpenedModal && (
                <TasksOngoingDetailsModal
                    taskId={selectedTaskId}
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
};

export default DashboardPage;
