import React, {FC, PropsWithChildren, useEffect, useRef} from 'react';

type Props = {
    width?: string;
    height?: string;
    desktopSize?: string;
    isForModal?: boolean
}

const BaseModal: FC<PropsWithChildren<Props>> = ({desktopSize = "auto", children, 
                                                     width, height, isForModal = true}) => {
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.showModal();
        }
    }, [ref]);

    return (
        <>
            <div className="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                {/*Background backdrop, show/hide based on modal state.*/}

                {/*Entering: "ease-out duration-300"*/}
                {/*  From: "opacity-0"*/}
                {/*  To: "opacity-100"*/}
                {/*Leaving: "ease-in duration-200"*/}
                {/*  From: "opacity-100"*/}
                {/*  To: "opacity-0"*/}
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 z-30 overflow-y-auto ">
                    <div
                        className="flex items-center justify-center min-h-full sm:min-h-full 
                        sm:flex sm:justify-center sm:items-center px-4 sm:p-0 text-center z-40">
                        {/*Modal panel, show/hide based on modal state.*/}

                        {/*Entering: "ease-out duration-300"*/}
                        {/*  From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
                        {/*  To: "opacity-100 translate-y-0 sm:scale-100"*/}
                        {/*Leaving: "ease-in duration-200"*/}
                        {/*  From: "opacity-100 translate-y-0 sm:scale-100"*/}
                        {/*  To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
                        <div className="rounded-lg bg-black-secondary-strong shadow-xl sm:w-max w-full">
                            <div
                                className={`flex-1 sm:flex-none relative transform text-left transition-all ${isForModal ? "mt-2 sm:mb-8" : "mt-1 mb-1"}`}>
                                <div className={"justify-center items-center"}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BaseModal;
