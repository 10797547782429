import {FormProvider, useForm} from "react-hook-form";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

import * as accountActions from "../../redux/slices/authSlice";
import {selectLoginStatus} from "../../redux/slices/authSlice";
import MainButton from "../../components/buttons/MainButton.tsx";
import CheckBox from "../../components/inputs/CheckBox";
import FormTextInput from "../../components/inputs/FormTextInput";
import logo from "../../assets/svg/LoginIcons/Logo.svg";
import view from "../../assets/svg/LoginIcons/View.svg";
import viewOn from "../../assets/svg/LoginIcons/State=On.svg";
import {useAppDispatch, useAppSelector} from "../../redux/hooks/hooks";
import Loader from "../../components/Loader/Loader";
import MobileLanguageDropdown from "../../components/DropDowns/languagesDropdown/MobileLanguageDropdown";
import {RootState} from "../../redux/store";
import { t } from "i18next";

const LoginScheme = z.object({
    userName: z.string().min(1, t("UsernameIsNeeded")),
    password: z.string().min(1, t("PasswordIsNeeded")),
});

type FormData = z.infer<typeof LoginScheme>;

const LoginPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const loginStatus = useAppSelector(selectLoginStatus);
    const dispatch = useAppDispatch();
    const [error, setError] = useState("");
    const isDropdownOpen = useAppSelector(
        (state: RootState) => state.languageDropdown.isDropdownOpen,
    );

    const {handleSubmit, formState, ...methods} = useForm<FormData>({
        resolver: zodResolver(LoginScheme),
    });

    const handleLogin = (formModel: FormData) => {
        dispatch(accountActions.signInUser({...formModel}));
    };

    useEffect(() => {
        if (loginStatus === "fulfilled") {
            navigate("/dashboard");
        }
        if (loginStatus === "rejected") {
            setError("Invalid username and/or password");
        }
    }, [loginStatus]);

    const forgotPasswordHandler = () => {
        navigate("/forgot-password");
    };

    const showPasswordToggler = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormProvider
            handleSubmit={handleSubmit}
            formState={formState}
            {...methods}
        >
            {loginStatus === "pending" && <Loader/>}
            <div
                className={`flex h-[460px] w-[392px] flex-col items-center justify-center rounded-[16px] bg-black-secondary-strong px-8 py-14 ${isDropdownOpen ? "" : "overflow-hidden"}`}
            >
                <div className={"mb-8 flex flex-col items-center"}>
                    <img
                        src={logo}
                        width={64}
                        height={64}
                        alt="logo"
                        className="mb-3 hidden md:block"
                        draggable="false"
                    />

                    <div className="text-center font-myriad text-lg font-regular text-black-primary md:text-xl">
                        {t("LogIn")}
                    </div>
                </div>
                <div className={"w-50 h-50 flex flex-col items-center"}>
                    <FormTextInput
                        fieldName={"userName"}
                        placeholder={t("UserName")}
                        disabled={loginStatus === "pending"}
                    />
                    <FormTextInput
                        fieldName={"password"}
                        placeholder={t("Password")}
                        type={showPassword ? "password" : "text"}
                        disabled={loginStatus === "pending"}
                        icon={
                            <img
                                src={showPassword ? viewOn : view}
                                alt="view"
                                onClick={showPasswordToggler}
                                className="cursor-pointer"
                            />
                        }
                    />
                    <div className="font-myriad text-base font-regular text-red-primary">
                        {error}
                    </div>
                </div>
                <div className={"w-full flex flex-col items-center"}>
                    <div className="w-full flex flex-row items-center justify-between my-3">
                        <CheckBox label={t("RememberMe")}/>
                        <div
                            className="font-myriad text-sm font-regular text-dark-green hover:cursor-pointer hover:underline"
                            onClick={forgotPasswordHandler}
                        >
                            {t("ForgotPassword")}
                        </div>
                    </div>
                    <MainButton
                        onClick={handleSubmit(handleLogin)}
                        title={t("LogIn")}
                        className="btn w-full bg-dark-green font-myriad text-[13px] font-regular text-black-secondary-strong hover:bg-dark-green-80 md:text-sm dark:border-0"
                        disabled={loginStatus === "pending"}
                    />
                </div>
                <MobileLanguageDropdown showText={true}/>
            </div>
        </FormProvider>
    );
};

export default LoginPage;
