import axios from "axios";

const devApiUrl = "https://dreamland-tracking.everridge.co.uk/api/v1/";
const localApiUrl = "http://localhost:5700/api/v1/";
const liveApiUrl = "https://orders.dreamland.ge/api/v1/";
const devNotificationHubUrl = "https://dreamland-tracking.everridge.co.uk/hubs/v1/notifications";
const localNotificationHubUrl = `http://localhost:5700/hubs/v1/notifications`;
const liveNotificationHubUrl = "https://orders.dreamland.ge/hubs/v1/notifications";

export const apiUrl = devApiUrl;
export const currentNotificationHub = devNotificationHubUrl;


const axiosConfig = {
    headers: {
        "Content-Type": "application/json",
    },
    baseURL: apiUrl,
};

export const axiosInstance = axios.create(axiosConfig);
export const localStorageTokenKey = "DreamlandTrackingAuthUser";

// axiosInstance.interceptors.request.use(
//     (config) => {
//         // const storedUser = localStorage.getItem(localStorageTokenKey);
//         // if (storedUser) {
//         //     const {token} = JSON.parse(storedUser);
//         //     if (token) {
//         //         config.headers["Authorization"] = `Bearer ${token}`;
//         //     }
//         // }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     },
// );


export const defaultDateFormat = "d MMMM, yyyy";
export const defaultDateTimeFormat = "d/MM/yyyy HH:mm";