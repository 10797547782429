import {DateHelper} from "../../../helpers/formatDate";
import EmployeeTaskDto from "../../../dtos/EmployeeTask/EmployeeTaskDto.ts";
import {EnumHelper} from "../../../helpers/enumHelper.tsx";
import {useAppSelector} from "../../../redux/hooks/hooks.ts";
import {selectIsAdmin, selectIsSupervisor, selectUserType} from "../../../redux/slices/authSlice.ts";
import {LuCombine, LuLoader, LuPencil, LuTrash2} from "react-icons/lu";
import {ThemeColors} from "../../../theme/colors.ts";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {DateTimeHelper} from "../../../utils/DateTimeHelper.ts";
import {axiosInstance} from "../../../config/appConfig.ts";
import toast from "react-hot-toast";
import UpdateResponseDto from "../../../dtos/BaseResponse/UpdateResponseDto.ts";
import TaskAssigneeModal from "../modals/TaskAssigneeModal.tsx";
import ChangeStatusConfirmQuestionModal from "../modals/ChangeStatusConfirmQuestionModal.tsx";
import {EmployeeTaskStatusEnum} from "../../../enums/EmployeeTaskStatusEnum.ts";
import {EmployeeTaskHelper} from "../../../helpers/employeeTaskHelper.ts";


type Props = {
    card: EmployeeTaskDto,
    onDoubleClick: (task: EmployeeTaskDto) => void;
    onChanged: (taskId: string) => void;
    onDelete: (taskId: string) => void;
}

const TaskCard: React.FC<Props> = ({
                                       card,
                                       onDoubleClick,
                                       onChanged,
                                       onDelete
                                   }) => {

    const {t} = useTranslation();
    const isAdmin = useAppSelector(selectIsAdmin);
    const isSupervisor = useAppSelector(selectIsSupervisor);
    const isEmployee = !isAdmin && !isSupervisor;
    const userType = useAppSelector(selectUserType);
    const [showChangeStatusConfirmQuestionModal, setShowChangeStatusConfirmQuestionModal] = useState(false);
    const [showAssigneeFormModal, setShowAssigneeFormModal] = useState(false);
    const [isChangingStatus, setIsChangingStatus] = useState(false);
    const [currentCard, setCurrentCard] = useState<EmployeeTaskDto>(card);

    const deadlineDateInTime = currentCard?.deadline ? new Date(currentCard?.deadline).getTime() : 0;
    const isDeadline = new Date().getTime() > deadlineDateInTime;

    const handleChangeStatus = useCallback(() => {
        if (EmployeeTaskHelper.isUserAbleToChangeTaskStatus(currentCard, userType)) {
            setShowChangeStatusConfirmQuestionModal(true);
        }
    }, [currentCard]);

    const handeChangeStatusConfirmed = useCallback(async (assigneeId?: string) => {
        setShowChangeStatusConfirmQuestionModal(false);
        try {
            setIsChangingStatus(true);
            const url = `EmployeeTasks/ChangeStatus`;
            const requestDto = {
                id: currentCard.id,
                assigneeId,
            }
            const response = await axiosInstance.patch<UpdateResponseDto<EmployeeTaskDto>>(url,
                requestDto,
                {
                    validateStatus: (status) => true,
                });

            if (response.status !== 200) {
                if (response.data.errorMessage) {
                    throw new Error(response.data.errorMessage);
                }
                throw new Error("Failed to change status of the task.");
            }

            if (response?.data?.item?.status) {
                setCurrentCard((prevState) => ({
                    ...prevState,
                    status: response!.data!.item!.status,
                    assignedEmployeeFullName: response!.data!.item!.assignedEmployeeFullName,
                    assignedEmployeeId: response!.data!.item!.assignedEmployeeId,
                }));
            }

            toast.success(t("TaskStatusChangedSuccessfully"));
        } catch (e: any) {
            console.error(e);
            toast.error(e.message);
        } finally {
            setIsChangingStatus(false);
        }
    }, [currentCard]);

    const handleSelectAssignee = useCallback(() => {

    }, [currentCard]);

    return (
        <>
            <div
                className={`h-[240px] px-4 py-2 font-myriad text-black-80 border-[1px] sm:border-none cursor-pointer gap-2`}
                onDoubleClick={() => onDoubleClick(currentCard)}
            >
                <div className={"grid grid-cols-2 gap-y-1"}>
                    <div className={"font-bold"}>{`# ${currentCard?.code}`}</div>
                    <div className={"text-end flex items-end justify-end gap-2"}>
                        <div className="tooltip" data-tip={t("ChangeStatus")}>
                            <button className="p-2 hover:bg-black-10 rounded-full cursor-pointer"
                                    disabled={isChangingStatus}
                                    onClick={handleChangeStatus}>
                                {!isChangingStatus && <LuCombine color={ThemeColors.mainColor}/>}
                                {isChangingStatus && (
                                    <div className={"animate-spin"}>
                                        <LuLoader color={ThemeColors.mainColor}/>
                                    </div>
                                )}
                            </button>
                        </div>
                        {(isAdmin || isSupervisor) && <div className="tooltip" data-tip={t("EditTask")}>
                            <button className="p-2 hover:bg-black-10 rounded-full cursor-pointer"
                                    disabled={isChangingStatus}
                                    onClick={() => onChanged(currentCard.id)}>
                                <LuPencil color={ThemeColors.mainColor}/>
                            </button>
                        </div>}
                        {isAdmin && <div className="tooltip" data-tip={t("DeleteTask")}>
                            <button className="p-2 hover:bg-black-10 rounded-full cursor-pointer"
                                    disabled={isChangingStatus}
                                    onClick={() => onDelete(currentCard.id)}>
                                <LuTrash2 color={ThemeColors.redPrimary}/>
                            </button>
                        </div>}
                    </div>
                    <div className={"font-bold text-sm"}>{t("Location/Room")}</div>
                    <div className={"text-end text-sm"}>{currentCard?.apartmentRoomNo ? (
                        currentCard.apartmentRoomNo
                    ) : (
                        currentCard.locationName
                    )}</div>
                    <div className={"font-bold text-sm"}>{t("Type")}</div>
                    <div className={"text-end text-sm"}>{currentCard?.typeDescription}</div>
                    <div className={"font-bold text-sm"}>{t("Predefined")}</div>
                    <div className={"text-end text-sm"}>{currentCard?.predefinedDescriptionDescription}</div>
                    <div className={"font-bold text-sm"}>{t("Assigneed")}</div>
                    <div className={"text-end text-sm"}>{currentCard?.assignedEmployeeFullName ?? "-"}</div>
                    <div className={"font-bold text-sm"}>{t("AvailableFrom")}</div>
                    <div
                        className="text-end text-sm">{currentCard?.availableFrom ? DateHelper.formatDefaultDateWithTime(currentCard.availableFrom) : '-'}</div>
                    <div className={"font-bold text-sm"}>{t("Deadline")}</div>
                    <div
                        className="text-end text-sm"
                    >
                        {isDeadline ? (
                            <div className="animate-pulse text-red-400">
                                {currentCard?.deadline ? DateHelper.formatDefaultDateWithTime(currentCard.deadline) : '-'}
                            </div>
                        ) : (
                            currentCard?.deadline ? DateHelper.formatDefaultDateWithTime(currentCard.deadline) : '-'
                        )}
                    </div>
                    <div className="font-bold text-sm">{t("Status")}</div>
                    <div className="text-end text-sm">
                        <div className={"tooltip"} data-tip={EnumHelper.getEmployeeTaskStatusKey(currentCard?.status)}>
                            {EnumHelper.getEmployeeTaskStatusIcon(currentCard?.status)}
                        </div>
                    </div>
                </div>
            </div>
            {showChangeStatusConfirmQuestionModal && (
                <ChangeStatusConfirmQuestionModal onClose={() => setShowChangeStatusConfirmQuestionModal(false)}
                                                  onYes={handeChangeStatusConfirmed}
                                                  taskId={currentCard?.id}
                                                  title={t("AreYouSureYouWantToChangeThisTaskStatus")}
                                                  content={t("ThisActionCannotBeUndone")}
                                                  icon={<LuCombine size={24} color={ThemeColors.mainColor}/>}
                                                  type={"regular"}
                />
            )}
            {showAssigneeFormModal && (
                <TaskAssigneeModal onClose={() => setShowAssigneeFormModal(false)}
                                   onChanged={handleSelectAssignee}
                                   taskId={currentCard?.id}/>
            )}
        </>
    )
}

export default TaskCard;
