import {useEffect, useMemo, useState} from "react";
import {axiosInstance} from "../config/appConfig.ts";
import {UrlHelper} from "../helpers/UrlHelper.ts";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto.ts";
import {EmployeeDto} from "../dtos/employees/EmployeeDto.ts";
import UserType from "../types/UserType.ts";

const useFetchEmployees = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState<EmployeeDto[] | []>([]);
    const [employee, setEmployee] = useState<EmployeeDto | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const employeesOptions = useMemo(() => {
        return employees.map((employee: EmployeeDto) => ({
            label: `${employee.fullName} (${employee.userName})` ?? "",
            value: employee.id ?? "",
        })) ?? [];
    }, [employees]);

    const fetchEmployees = async (searchText?: string, skip?: number, take?: number, employeeRole?: UserType) => {
        try {
            setIsLoading(true);
            setError(undefined)

            const url = UrlHelper.generateUrlWithParams("users", {
                searchText,
                skip,
                take,
                employeeRole
            });
            const response = await axiosInstance.get<ListResponseDto<EmployeeDto>>(url, {
                validateStatus: (status) => status < 500
            });
            if (response?.data?.success) {
                setEmployees(response.data.items);
                setTotalCount(response.data.totalCount);
                return response.data.items;
            }

            throw new Error(response.data.errorMessage ?? "API error");
        } catch (error: any) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    };

    const fetchEmployeesById = async (employeeId: string) => {
        try {
            setIsLoading(true);

            if (employeeId) {
                const currentEmployee = employees.find(employee => +employee.id === +employeeId) || undefined;
                setEmployee(currentEmployee);
            }
        } catch (error: any) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    };

    const createEmployee = (newEmployee: EmployeeDto) => {
        try {
            setIsSubmitting(true);

            if (newEmployee) {
                const filteredEmployees = [...employees, newEmployee];
                setEmployees(filteredEmployees);
            }
        } catch (error: any) {
            setError(error)
        } finally {
            setIsSubmitting(false);
        }
    };

    const updateEmployee = (updatedEmployee: EmployeeDto) => {
        try {
            setIsSubmitting(true);

            const updatedEmployees = employees.map(employee =>
                employee.id === updatedEmployee.id ? updatedEmployee : employee
            );

            setEmployees(updatedEmployees);
        } catch (error: any) {
            setError(error)
        } finally {
            setIsSubmitting(false);
        }
    };

    const deleteEmployee = (employeeId: number) => {
        try {
            setIsSubmitting(true);

            const filteredEmployees = employees.filter(employee => +employee.id !== +employeeId);
            setEmployees(filteredEmployees);
            console.log("sucsesfly removed")
        } catch (error: any) {
            setError(error)
        } finally {
            setIsSubmitting(false);
        }

    }

    return {
        createEmployee,
        fetchEmployeesById,
        updateEmployee,
        fetchEmployees,
        deleteEmployee,
        isLoading,
        employees,
        employee,
        error,
        isSubmitting,
        employeesOptions,
    }

};

export default useFetchEmployees;