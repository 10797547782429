import React, {FC, Fragment, PropsWithChildren} from 'react';
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
import empty_avatar from "../../../assets/png/icons/ProfilePicture.png";
import BaseModal from "../../../components/Modals/BaseModal.tsx";
import BaseModalContent from "../../../components/Modals/BaseModalContent.tsx";
import MessageIsRead from "../../../assets/svg/MessageIsRead.tsx";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons.tsx";
import {MessageDto} from "../../../dtos/messages/MessageDto.ts";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader.tsx";
import {LuMailOpen} from "react-icons/lu";
import TasksDetailModal from "../../TasksPage/modals/TasksDetailsModal.tsx";

type Props = {
    onClose: () => void;
    onOpenEmployeeTask: () => void;
    message?: MessageDto;
}

const MessageModal: FC<PropsWithChildren<Props>> = ({onClose, onOpenEmployeeTask, message}) => {
    const {t} = useTranslation();

    if (!message) {
        return null
    }

    return (
        <>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={t("Message")} onClose={onClose}/>
                    <div className={"font-myriad text-sm"}>
                        <div className={"flex justify-between text-black-40  mb-4"}>
                            <div>{format(message.messageDate, "d MMMM, yyyy")}</div>
                            <div>{format(message.messageDate, "hh:mm aaaaa'm'")}</div>
                        </div>
                        <div className={"mb-4"}>
                            <div className={"text-black"}>
                                {t("Message")}
                            </div>
                            <div className={"text-black-60 max-h-[200px] overflow-auto"}>
                                {message.messageContent}
                            </div>
                        </div>
                        {/*<div className={"mb-4"}>*/}
                        {/*    <div className={"text-black"}>*/}
                        {/*        {t("Building")}*/}
                        {/*    </div>*/}
                        {/*    <div className={"text-black-60"}>*/}
                        {/*        {"Unknown"}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={"mb-4"}>
                            <div className={"text-black"}>
                                {t("Room")}
                            </div>
                            <div className={"text-black-60"}>
                                {message.apartmentNo}
                            </div>
                        </div>
                        <div className={"mb-4"}>
                            <div className={"text-black"}>
                                {t("To")}
                            </div>
                            <div className={"text-black-60 max-h-[200px] overflow-y-auto"}>
                                <div className="flex flex-wrap justify-start">
                                    {message.receivers?.length === 0 && <div>{t("No receivers")}</div>}
                                    {message.receivers?.map((receiver, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <div
                                                    className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-black-60 bg-black-10 border">
                                                    <div slot="avatar">
                                                        <div
                                                            className="flex relative w-8 h-8 bg-black-20 justify-center items-center m-1 mr-2 ml-0 my-0 text-xs rounded-full">
                                                            <img className="rounded-full" alt="A"
                                                                 src={receiver.avatarUrl ? receiver.avatarUrl : empty_avatar}/>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="font-myriad text-black-60 text-sm font-normal leading-none max-w-full flex-initial">
                                                        {receiver.fullName}
                                                    </div>
                                                    <div>
                                                        {receiver.isRead &&
                                                            <div className={"ms-2"}><MessageIsRead/></div>}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </BaseModalContent>
                <BaseModalButtons onClose={onClose} 
                                  onSave={onOpenEmployeeTask}
                                  showSaveButton={true} 
                                  saveButtonText={t("Open")} 
                                  saveButtonIcon={<LuMailOpen />}/>
            </BaseModal>
        </>
    );
};

export default MessageModal;