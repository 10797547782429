import {t} from "i18next";
import TableEditIcon from "../../assets/svg/TableEditIcon";
import DeleteItemIcon from "../../assets/svg/DeleteItemIcon";
import { useCallback, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import SearchInput from "../../components/inputs/SearchInput";
import MainButton from "../../components/buttons/MainButton";
import AlertDanger from "../../components/Alerts/AlertDanger";
import PageContainer from "../../components/PageContainer/PageContainer";
import Loading from "../../components/Loading/Loading";
import DataTable from "../../components/DataTable/DataTable";
import { EditTaskSourceModal } from "../TasksSources/modals/EditTaskSourceModal";
import QuestionModal from "../../components/QuestionModal/QuestionModal";
import PlusIcon from "../../assets/svg/buttonIcons/PlusIcon";
import useFetchData from "../../hooks/useFetchData";
import { CreateServiceDto, ServiceDto } from "../../dtos/services/ServiceDto";
import { EditServiceModal } from "./modals/EditServiceModal";

const ServicesPage = () => {
    
    const [selectedServiceId, setSelectedServiceId] = useState<string | undefined>(undefined);
    const [showServiceDeleteQuestionModal, setShowServiceDeleteQuestionModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState<string>("");

    const {
        fetchItems,
        items,
        error,
        isLoading,
        deleteItem,
        totalCount
    } = useFetchData<CreateServiceDto, ServiceDto, ServiceDto>("Services")

    const columns: DataTableColumn[] = [
        {
            title: t("ServiceName"),
            field: "name"
        },
        {
            title: t("EmployeeTaskDescription"),
            field: "predefinedEmployeeTaskDescriptionDescription"
        },
        {
            title: t("Actions"),
            field: "actions",
            render: (value: any) => (
                <div className="flex gap-2">
                    <button onClick={() => handleEditService(value)}><TableEditIcon/></button>
                    <button onClick={() => handleDeleteService(value)}><DeleteItemIcon/></button>
                </div>
            )
        }
    ]

    const handleOnReload = (newPageSize, newPageNumber) => {
        setPageSize(newPageSize);
        setPageNumber(newPageNumber);
    };
    const handleCloseModal = async () => {
        setShowEditModal(false);
    }

    const handleUpdateService = async () => {
        setShowEditModal(false);
        await fetchData();
    };

    const handleNewService = () => {
        setSelectedServiceId(undefined);
        setShowEditModal(true);
    };

    const handleEditService = (department: ServiceDto) => {
        setSelectedServiceId(department.id);
        setShowEditModal(true);
    };

    const handleDeleteService = async (taskSource: ServiceDto) => {
        setSelectedServiceId(taskSource.id);
        setShowServiceDeleteQuestionModal(true);
    };

    const handleDeleServiceService = async () => {
        setShowServiceDeleteQuestionModal(false);
        if (!selectedServiceId) return;

        await deleteItem(selectedServiceId);
        await fetchData();
    };

    const fetchData = useCallback(async () => {
        await fetchItems(searchText, (pageNumber - 1) * pageSize, pageSize);
    }, [pageSize, pageNumber, searchText]);

    useEffect(() => {
        fetchData();
    }, [pageSize, pageNumber, searchText])

    console.log(items);
    console.log(totalCount )
    
    return (
        <div>
            <PageHeader title={t("Services")}>
                <div className={"flex gap-2"}>
                    <SearchInput searchText={searchText} onSearchTextChanged={setSearchText}/>
                    <MainButton
                        disabled={isLoading}
                        title={t("NewService")}
                        onClick={handleNewService}
                        icon={<PlusIcon/>}
                    />
                </div>
            </PageHeader>
            <AlertDanger errorMessages={[error]}/>
            <PageContainer>
                <Loading loading={isLoading}>
                    <div className={"mt-2"}>
                        <DataTable
                            showPagination={true}
                            items={items}
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={pageNumber}
                            totalCount={totalCount}
                            onReload={handleOnReload}
                        />
                    </div>
                </Loading>
            </PageContainer>
            {showEditModal && (
                <EditServiceModal
                    onClose={handleCloseModal}
                    onChanged={handleUpdateService}
                    serviceId={selectedServiceId}
                />
            )}

            {showServiceDeleteQuestionModal && (
                <QuestionModal onClose={() => setShowServiceDeleteQuestionModal(false)}
                               onYes={handleDeleServiceService}
                               title={t("AreYouSureYouWantToDeleteThisService")}
                               content={t("ThisActionCannotBeUndone")}
                               icon={<DeleteItemIcon/>} type={"danger"}
                               yesButtonText={t("Yes")}
                               noButtonText={t("No")}/>
            )}
        </div>
    );
};

export default ServicesPage;