import {useTranslation} from "react-i18next";
import React from "react";
import CloseIcon from "../../assets/svg/CloseIcon.tsx";
import TrashIcon from "../../assets/svg/TrashIcon.tsx";
import UseFetchCalendar from "../../hooks/useFetchCalendar.ts";
import useFetchCalendar from "../../hooks/useFetchCalendar.ts";
import {ThemeColors} from "../../theme/colors.ts";

type Props = {
    title?: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    extraClassName?: string;
    inProgress?: boolean;
};

const CancelButton = ({
                          title,
                          className,
                          onClick,
                          disabled,
                          inProgress,
                          extraClassName,
                      }: Props) => {
    
    const {t} = useTranslation();
    
    return (
        <button
            type="button"
            className={className ? className : `w-2/5 select-none flex h-[36px] flex-row items-center gap-2 rounded-lg py-2 pl-[9px] pr-[13px] font-myriad text-sm font-regular text-red-80 disabled:text-black-40 bg-black-secondary-strong disabled:bg-black-20 hover:bg-dark-green-10 border-[1px] border-red-80 disabled:border-black-20 ${extraClassName}`}
            disabled={inProgress === true || disabled === true}
            onClick={onClick}
        >
            {inProgress && (
                <span className="loading loading-spinner loading-sm"></span>
            )}
            {!inProgress && <TrashIcon strokeColor={"white"} color={disabled ? ThemeColors.disabledColor : ThemeColors.redPrimary}/>}
            {t(title ? title : "Cancel")}
        </button>
    );
};

export default CancelButton;
