import {useTranslation} from "react-i18next";
import MainButton from "../../components/buttons/MainButton.tsx";
import PlusIcon from "../../assets/svg/buttonIcons/PlusIcon";
import {createRef, useMemo, useRef, useState} from "react";
import CalendarEvents, {CalendarEventsRef} from "./CalendarEvents.tsx";
import PageHeader from "../../components/PageHeader/PageHeader.tsx";
import SecondaryButton from "../../components/buttons/SecondaryButton.tsx";
import FilterApartmentsIcon from "../../assets/svg/apartmentIcons/FilterApartmentsIcon.tsx";
import CalendarEventModal from "./modals/CalendarEventModal.tsx";
import {CalendarEventDto} from "../../dtos/calendar/CalendarEventDto.ts";
import CalendarScheduler from "./CalendarScheduler.tsx";
import {CalendarFilterFormModel} from "../../types/CalendarFilterFormModel.ts";
import {useAppDispatch, useAppSelector} from "../../redux/hooks/hooks.ts";
import {selectCalendarFilter, setCalendarFilter} from "../../redux/slices/appSlice.ts";
import {CalendarEventFilter} from "./types.ts";
import PageContainer from "../../components/PageContainer/PageContainer.tsx";

const CalendarPage = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const {t} = useTranslation();
    const [showNewEventModal, setShowNewEventModal] = useState(false);
    const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
    const [showCalendarFilterModal, setShowCalendarFilterModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEventDto | undefined>(undefined);
    const filter = useAppSelector(selectCalendarFilter);
    const dispatch = useAppDispatch();

    const schedulerEventsRef = useMemo(() => createRef<CalendarEventsRef>(), []);
    const calendarEventsRef = useMemo(() => createRef<CalendarEventsRef>(), []);
    const handleMonthChanged = (newMonth: Date) => {
        setCurrentMonth(newMonth);
    };

    const handleOnCreate = async () => {
        setShowNewEventModal(false);
        await calendarEventsRef.current?.fetchData();
        await schedulerEventsRef.current?.fetchData();
    };

    const handleShowEventDetails = (event: CalendarEventDto) => {
        setShowEventDetailsModal(true);
        setSelectedEvent(event);
    };

    const handleChangeMonth = async (newMonth: Date) => {
        setCurrentMonth(newMonth);
        await schedulerEventsRef.current?.fetchData();
        await calendarEventsRef.current?.fetchData();
    };

    const handleApplyFilter = (newFilter: CalendarEventFilter) => {
        dispatch(setCalendarFilter(newFilter));
        setShowCalendarFilterModal(false);
    };

    return (
        <>
            <PageContainer>
                    <PageHeader title={t("Calendar")}>
                        <div className={"flex"}>
                            <SecondaryButton title={t("Filter")} icon={<FilterApartmentsIcon/>}
                                            onClick={() => setShowCalendarFilterModal(true)}/>
                            <span className={"px-1"}></span>
                        </div>
                    </PageHeader>
                    <div className={"grid grid-cols-1 sm:grid-cols-2"}>
                        <div className={"p-0 sm:pe-2"}>
                            <CalendarScheduler ref={schedulerEventsRef} currentMonth={currentMonth}
                                            onMonthChanged={handleMonthChanged}/>
                        </div>
                        <div className={"p-0 sm:ps-2 mt-3 sm:mt-0"}>
                            <CalendarEvents ref={calendarEventsRef} currentMonth={currentMonth}
                                            onChangeMonth={(newMonth) => handleChangeMonth(newMonth)}
                                            onDoubleClickEvent={(event) => handleShowEventDetails(event)}/>
                        </div>
                    </div>
                    {showEventDetailsModal && selectedEvent && (
                        <CalendarEventModal onClose={() => setShowEventDetailsModal(false)}
                                            eventId={selectedEvent.id}
                                            eventType={selectedEvent.type}/>
                    )}
            </PageContainer>
        </>
    );
};

export default CalendarPage;
