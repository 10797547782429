import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import Select, {MultiValue, SingleValue} from "react-select";
import {ThemeColors} from "../../theme/colors.ts";

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        borderRadius: 8,
        borderWidth: 0,  // Remove borders
        boxShadow: 'none', // Remove box shadow if there is any
        fontSize: '12px', // Set font size to text-xs equi
        '&:focus': {
            borderWidth: 1, // Remove border on focus
        },
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? ThemeColors.mainColor : ThemeColors.whiteColor,
    }),
};

type Props = {
    options: SelectControlOption<string>[];
    isMulti?: boolean;
    label?: string;
    placeHolder?: string;
    onSingleValueSelect?: (item?: SelectControlOption<string>) => void;
    onMultiValueSelect?: (items: SelectControlOption<string>[]) => void;
    defaultValues?: SelectControlOption<string>[] | SelectControlOption<string>
    values?: SelectControlOption<string>[] | SelectControlOption<string>
    clearable?: boolean;
    isDisabled?: boolean;
}

const SelectControl
    :
    FC<PropsWithChildren<Props>> = ({
                                        defaultValues,
                                        values,
                                        options,
                                        label,
                                        placeHolder,
                                        isMulti,
                                        clearable = true,
                                        onMultiValueSelect,
                                        onSingleValueSelect,
                                        isDisabled = false,
                                    }) => {
    const [selectedItem, setSelectedItem] = useState<any | undefined>(undefined);

    useEffect(() => {
        setSelectedItem(defaultValues || values);
    }, [values]);

    const handleSelect = (item: SingleValue<SelectControlOption<string>> | MultiValue<SelectControlOption<string>>) => {
        if (Array.isArray(item)) {
            setSelectedItem(item as SelectControlOption<string>[]);
            if (onMultiValueSelect) {
                onMultiValueSelect(item as SelectControlOption<string>[]);
            }
        } else {
            setSelectedItem(item as SelectControlOption<string>);
            if (onSingleValueSelect) {
                onSingleValueSelect(item as SelectControlOption<string>);
            }
        }
    }

    return (
        <div className={"font-myriad font-regular"}>
            {label && <div className="mb-1.5 text-xs text-black-primary overflow-x-auto">{label}</div>}
            <Select options={options}
                    placeholder={placeHolder}
                    styles={customStyles}
                    isClearable={clearable}
                // menuIsOpen={true}
                    defaultValue={defaultValues}
                    classNames={{
                        container: () => 'border-[1px] border-black-10 rounded-lg font-myriad text-xs font-regular hover:border-dark-green ',
                        placeholder: () => ' text-black-60 font-myriad text-xs font-regular',
                        control: () => "border-0 rounded-lg shadow-none focused:border-dark-green",
                        input: () => "border-0   ",
                        option: () => "text-xs font-myriad font-regular text-black-60 bg-white hover:bg-dark-green-80 hover:text-white selected:bg-dark-green selected:text-white",
                        menu: () => "position-relative z-50 overflow-x-auto",
                        menuList: () => "text-xs font-myriad font-regular text-black-60",
                    }}
                    isDisabled={isDisabled}
                    isMulti={isMulti}
                    value={selectedItem}
                    onChange={(e) => handleSelect(e)}
            />
        </div>
    );
};

export default SelectControl;