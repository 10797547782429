import React, {FC, PropsWithChildren, useState} from 'react';
import "./SelectDateControl.css";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";

type Props = {
    label?: string;
    value?: Date;
    onDateChanged: (date?: Date) => void;
}

const SelectDateControl: FC<PropsWithChildren<Props>> = ({value, onDateChanged, label}) => {
    const {t} = useTranslation();
    const handleDateChange = (date?: string) => {
        if (!date) {
            onDateChanged(undefined);
            return;
        }
        onDateChanged(new Date(date));
    }

    return (
        <>
            {label && <label className={"mb-1.5 text-xs text-black-primary"}>{label}</label>}
            <input
                className={`w-full flex h-[36px] font-myriad text-xs font-regular items-center justify-center border-[1px] border-black-10 rounded-lg md:rounded-lg`}
                type={"date"}
                value={value ? format(value, "yyyy-MM-dd") : ""}
                onChange={(e) => handleDateChange(e.target.value)}
            />
        </>
    );
};

export default SelectDateControl;