import React, {FC, PropsWithChildren} from 'react';
import NextArrow from "../../assets/svg/NextArrow.tsx";
import PrevIcon from "../../assets/svg/PrevIcon.tsx";

type Props = {
    allowedPageSizes: number[];
    currentPageSize: number;
    totalPagesCount: number;
    onSetPageSize: (e: any, pageSize: number) => void;
    currentPage: number;
    onSetCurrentPage: (e: any, pageNumber: number) => void;
}

const DataTablePaginationRegular: FC<PropsWithChildren<Props>> = ({
                                                               allowedPageSizes,
                                                               currentPageSize,
                                                               totalPagesCount,
                                                               onSetCurrentPage,
                                                               onSetPageSize,
                                                               currentPage
                                                           }) => {

    const renderPaginationButtonClass = (isActive: boolean) => {
        return `font-myriad text-sm font-regular flex h-[32px] w-[32px] items-center justify-center rounded-lg ${isActive ? "cursor-not-allowed bg-dark-green-10 text-dark-green" : "hover:bg-black-10"} cursor-pointer`;
    }
    const renderPages = (currentPage: number, totalPagesCount: number, handleSetCurrentPage: (e: any, pageNumber: number) => void) => {
        let pages: any[] = [];
        let startPage = currentPage - 2;
        let endPage = currentPage + 2;
        if (startPage < 1) {
            endPage = endPage + (1 - startPage);
            startPage = 1;
        }
        if (endPage > totalPagesCount) {
            startPage = startPage - (endPage - totalPagesCount);
            endPage = totalPagesCount;
        }
        if (startPage < 1) {
            startPage = 1;
        }
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li className={renderPaginationButtonClass(currentPage == i)} key={i}
                    onClick={(e) => handleSetCurrentPage(e, i)}>
                    {i}
                </li>
            )
        }
        return pages;
    }

    return (
        <>
            <div className={"flex justify-between select-none px-6 py-4 border-t-[1px]"}>
                <ul className="flex">
                    {allowedPageSizes.map((pageSize, index) => (
                        <li className={renderPaginationButtonClass(currentPageSize === pageSize)} key={index}
                            onClick={(e) => onSetPageSize(e, pageSize)}>
                            {pageSize}
                        </li>
                    ))}
                </ul>
                {totalPagesCount > 0 &&
                    <ul className="flex">
                        <li className={renderPaginationButtonClass(false)}
                            onClick={(e) => onSetCurrentPage(e, currentPage <= 1 ? 1 : currentPage - 1)}>
                            <span aria-hidden="true"><PrevIcon/></span>
                        </li>
                        {renderPages(currentPage, totalPagesCount, onSetCurrentPage)}
                        <li className={renderPaginationButtonClass(false)}
                            onClick={(e) => onSetCurrentPage(e, currentPage >= totalPagesCount ? totalPagesCount : currentPage + 1)}>
                            <span aria-hidden="true"><NextArrow/></span>
                        </li>
                    </ul>
                }
            </div>
        </>);
};

export default DataTablePaginationRegular;