import {t, use} from "i18next";
import {LuCombine, LuDownload, LuPencil, LuSettings, LuTrash2} from "react-icons/lu";
import PageHeader from "../../components/PageHeader/PageHeader";
import DataCards from "../../components/DataCards/DataCards";
import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import useFetchEmployeeTasks from "../../hooks/useFetchEmployeeTasks.ts";
import Loading from "../../components/Loading/Loading";
import TasksDetailModal from "./modals/TasksDetailsModal";
import DataTable from "../../components/DataTable/DataTable";
import UpdateResponseDto from "../../dtos/BaseResponse/UpdateResponseDto.ts";
import PageContainer from "../../components/PageContainer/PageContainer";
import {useAppDispatch, useAppSelector} from "../../redux/hooks/hooks";
import {selectOngoingTasksView, setTasksView} from "../../redux/slices/OngoingTasksSlice";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import FilterApartmentsIcon from "../../assets/svg/apartmentIcons/FilterApartmentsIcon";
import AlertDanger from "../../components/Alerts/AlertDanger";
import TasksFilterModal from "./modals/TasksFilterModal";
import {TaskFilterModal} from "./types";
import MainButton from "../../components/buttons/MainButton";
import PlusIcon from "../../assets/svg/buttonIcons/PlusIcon";
import {EditTaskModal} from "./modals/EditTaskModal.tsx";
import EmployeeTaskDto from "../../dtos/EmployeeTask/EmployeeTaskDto.ts";
import {EmployeeTaskStatusEnum} from "../../enums/EmployeeTaskStatusEnum.ts";
import SearchInput from "../../components/inputs/SearchInput.tsx";
import {selectTasksFilter, setTasksFilter} from "../../redux/slices/appSlice.ts";
import {selectIsAdmin, selectIsSupervisor, selectUserType} from "../../redux/slices/authSlice.ts";
import QuestionModal from "../../components/QuestionModal/QuestionModal.tsx";
import DeleteItemIcon from "../../assets/svg/DeleteItemIcon.tsx";
import useFetchData from "../../hooks/useFetchData.ts";
import CreateEmployeeTaskDto from "../../dtos/EmployeeTask/CreateEmployeeTaskDto.ts";
import TaskTypeButton from "./components/TaskTypeButton.tsx";
import TableEditIcon from "../../assets/svg/TableEditIcon.tsx";
import NewTaskIcon from "../../assets/svg/tasksIcons/NewTaskIcon.tsx";
import AssignedTaskIcon from "../../assets/svg/tasksIcons/AssignedTaskIcon.tsx";
import InProgressTaskIcon from "../../assets/svg/tasksIcons/InProgressIcon.tsx";
import {EnumHelper} from "../../helpers/enumHelper.tsx";
import {selectTasksRefreshIndex} from "../../redux/slices/tasksSlice.ts";
import toast from "react-hot-toast";
import {axiosInstance} from "../../config/appConfig.ts";
import {DateTimeHelper} from "../../utils/DateTimeHelper.ts";
import TaskCard from "./components/TaskCard.tsx";
import {ThemeColors} from "../../theme/colors.ts";
import TaskFilterButtons from "./components/TaskFilterButtons.tsx";
import notificationIcon from "../../components/layouts/UserProfile/OwnerNotifications/res/Notification.svg";
import {render} from "react-dom";
import {DateHelper} from "../../helpers/formatDate.ts";
import {EmployeeTaskHelper} from "../../helpers/employeeTaskHelper.ts";
import ChangeStatusConfirmQuestionModal from "./modals/ChangeStatusConfirmQuestionModal.tsx";

type Props = {}

const TasksList: React.FC<Props> = ({}) => {
    const {tasks, fetchTasks, isLoading, error, totalCount} = useFetchEmployeeTasks();
    const {
        deleteItem,
        updateItem,
    } = useFetchData<CreateEmployeeTaskDto, EmployeeTaskDto, EmployeeTaskDto>("EmployeeTasks");

    const isAdmin = useAppSelector(selectIsAdmin);
    const isSupervisor = useAppSelector(selectIsSupervisor);

    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);

    const [isOpenedModal, setIsOpenedModal] = useState(false);
    const [isCardView, setIsCardsView] = useState(true);
    const [showTasksModal, setShowTasksModal] = useState(false);
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const [showEmployeeDeleteQuestionModal, setShowEmployeeDeleteQuestionModal] = useState(false);
    const [showChangeStatusConfirmQuestionModal, setShowChangeStatusConfirmQuestionModal] = useState(false);
    const [isChangingStatus, setIsChangingStatus] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [selectedTaskId, setSelectedTaskId] = useState("");
    const [isReportPreparing, setIsReportPreparing] = useState(false);

    const isMobile = useMediaQuery("(max-width: 640px)");

    const tasksView = useAppSelector(selectOngoingTasksView);
    const tasksFilter = useAppSelector(selectTasksFilter);
    const userType = useAppSelector(selectUserType);

    const dispatch = useAppDispatch();
    const tasksRefreshIndex = useAppSelector(selectTasksRefreshIndex);

    const filterLength = Object.values(tasksFilter).reduce((acc, el) => (el !== undefined && el !== null) ? acc + 1 : acc, 0);
    const currentTask: EmployeeTaskDto | undefined = tasks.find((task) => task.id === selectedTaskId);

    const handleOnReload = (pageSize: number, pageNumber: number) => {
        setPageNumber(pageNumber);
        setPageSize(pageSize);
    };

    const handleDoubleClick = useCallback((task: EmployeeTaskDto) => {
        setIsOpenedModal(true);
        setSelectedTaskId(task.id)
    }, [])

    const handleCloseModal = () => {
        setIsOpenedModal(false);
        setShowAddTaskModal(false);
        setShowTasksModal(false);

        setSelectedTaskId("");
    }

    const toggleViewTasks = () => {
        setIsCardsView(prevView => !prevView);
        dispatch(setTasksView(
            tasksView !== "Cards" ? "Cards" : "List"
        ));
    }

    const handleSetTasksFilter = (newFilter: TaskFilterModal) => {
        console.log("New filter: ", newFilter);
        setShowTasksModal(false);
        dispatch(setTasksFilter(newFilter));
    }

    const handleUpdateTasksList = async () => {
        handleCloseModal();
        await fetchData()
    }

    const handleEditTask = (taskId: string) => {
        setSelectedTaskId(taskId);
        setShowAddTaskModal(true);
    };

    const handleDeleteEmployee = (taskId: string) => {
        setSelectedTaskId(taskId);
        setShowEmployeeDeleteQuestionModal(true);
    };

    const handleDeleteEmployeeConfirmed = async () => {
        setShowEmployeeDeleteQuestionModal(false);
        if (!selectedTaskId) return;

        await deleteItem(selectedTaskId);
        await fetchData();
        setSelectedTaskId("");
    };

    const columns: DataTableColumn[] = [
        {
            title: t("Location"),
            field: "apartmentRoomNo",
            render: (task: EmployeeTaskDto) => {
                return (
                    <div>
                        {task.apartmentRoomNo || task.locationName}
                    </div>
                );
            }
        },
        {
            title: t("Service"),
            field: "departmentDescription"
        },
        {
            title: t("Status"),
            field: "",
            render: (task: EmployeeTaskDto) => {
                return (
                    <div className="ml-2">
                        {EnumHelper.getEmployeeTaskStatusIcon(task.status)}
                    </div>
                );
            }
        },
        {
            title: t("Employee"),
            field: "assignedEmployeeFullName"
        },
        {
            title: t("DateStart"),
            field: "",
            render: (task: EmployeeTaskDto) => {
                return (
                    <div>
                        {t(DateHelper.formatDefaultDateWithTime(task.availableFrom))}
                    </div>
                );
            }
        },
        {
            title: t("Deadline"),
            field: "",
            render: (task: EmployeeTaskDto) => {
                return (
                    <div>
                        {t(DateHelper.formatDefaultDateWithTime(task.deadline))}
                    </div>
                );
            }
        },
        {
            title: t("Source"),
            field: "sourceDescription"
        },
        {
            title: t("Actions"),
            field: "",
            render: (task: EmployeeTaskDto) => {
                console.log("Task123: ", task);
                return (
                    <div className="flex items-center justify-center gap-4">
                        <div className={"tooltip"} data-tip={t("ChangeStatus")}>
                            <button onClick={() => handleChangeStatus(task)}>
                                <LuCombine color={ThemeColors.mainColor} size={20}/>
                            </button>
                        </div>
                        {(isAdmin || isSupervisor) && <div className={"tooltip"} data-tip={t("EditTask")}>
                            <button onClick={() => handleEditTask(task.id)}>
                                <LuPencil color={ThemeColors.mainColor} size={20}/>
                            </button>
                        </div>}
                        {(isAdmin || isSupervisor) && <div className={"tooltip"} data-tip={t("DeleteTask")}>
                            <button onClick={() => handleDeleteEmployee(task.id)}>
                                <LuTrash2 color={ThemeColors.redPrimary} size={20}/>
                            </button>
                        </div>}
                    </div>
                );
            }
        },
    ]

    const fetchData = useCallback(async () => {
        await fetchTasks(searchText, ((pageNumber - 1) * pageSize), pageSize, tasksFilter);
    }, [pageNumber, pageSize, tasksFilter, searchText, tasksRefreshIndex]);

    useEffect(() => {
        fetchData()
    }, [pageSize, pageNumber, tasksFilter, searchText, tasksRefreshIndex]);

    const generateTitle = useMemo(() => {
        switch (tasksFilter.taskStatus?.value) {
            case EmployeeTaskStatusEnum.OnGoing:
                return t("OngoingTasks");
            case EmployeeTaskStatusEnum.Closed:
                return t("CompletedTasks");
            case EmployeeTaskStatusEnum.New:
                return t("New");
            case EmployeeTaskStatusEnum.Inspected:
                return t("Inspected");
            case EmployeeTaskStatusEnum.Assigned:
                return t("Assigned");
            default:
                return t("AllTasks")
        }
    }, [tasksFilter]);

    const handleDownloadReport = useCallback(async () => {
        try {
            setIsReportPreparing(true);
            const requestDto = {
                assigneeId: tasksFilter?.assigneeEmployee?.value,
                taskTypeId: tasksFilter?.taskType?.value,
                taskStatus: tasksFilter?.taskStatus?.value,
                locationId: tasksFilter?.location?.value,
                taskSourceId: tasksFilter?.taskSource?.value,
                dateFrom: tasksFilter?.dateFrom,
                dateTo: tasksFilter?.dateTo,
            };

            const response = await axiosInstance.post("EmployeeTasks/Report", requestDto, {
                responseType: "blob",
            });
            const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/xlsx"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `tasks_report.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up the URL object and remove the link
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (e: any) {
            console.error(e);
            toast.error("Error occurred while downloading report");
        } finally {
            setIsReportPreparing(false);
        }
    }, [tasksFilter]);

    const handleChangeStatus = (task: EmployeeTaskDto) => {
        console.log("Task: ", task);
        if (EmployeeTaskHelper.isUserAbleToChangeTaskStatus(task, userType)) {
            setShowChangeStatusConfirmQuestionModal(true);
            setSelectedTaskId(task.id)
        }
    }

    const handleChangeStatusConfirmed = useCallback(async (assigneeId?: string) => {
        setShowChangeStatusConfirmQuestionModal(false);
        try {
            setIsChangingStatus(true);
            const url = `EmployeeTasks/ChangeStatus`;

            if (currentTask) {
                const requestDto = {
                    id: currentTask.id,
                    assigneeId,
                };

                const response = await axiosInstance.patch(url, requestDto, {
                    validateStatus: (status) => true,
                });

                if (response.status !== 200) {
                    if (response.data.errorMessage) {
                        throw new Error(response.data.errorMessage);
                    }
                    throw new Error("Failed to change status of the task.");
                }

                if (response.data?.item?.status) {
                    currentTask.status = response.data.item.status;
                    currentTask.assignedEmployeeFullName = response.data.item.assignedEmployeeFullName;
                    currentTask.assignedEmployeeId = response.data.item.assignedEmployeeId;
                }

                toast.success("Task status changed successfully");
            }
        } catch (e: any) {
            console.error(e);
            toast.error(e.message);
        } finally {
            setIsChangingStatus(false);
        }
    }, [tasks, selectedTaskId]);

    const isFilterEmpty = useMemo(() => {
        return !tasksFilter?.assigneeEmployee?.value &&
            !tasksFilter?.location?.value &&
            !tasksFilter?.apartment?.value &&
            !tasksFilter?.taskType?.value &&
            !tasksFilter?.taskStatus?.value &&
            !tasksFilter?.taskSource?.value &&
            !tasksFilter?.dateFrom &&
            !tasksFilter?.dateTo;
    }, [tasksFilter]);

    return (
        <PageContainer>
            <div className="flex justify-between items-center mb-1 flex-wrap">
                <PageHeader title={generateTitle}></PageHeader>
                <div className="flex items-center gap-3 flex-wrap">
                    {tasksFilter?.taskStatus?.value !== EmployeeTaskStatusEnum.Inspected &&
                        <TaskFilterButtons/>
                    }
                    <SearchInput searchText={searchText} onSearchTextChanged={setSearchText}/>
                    {(isAdmin || isSupervisor) && (
                        <>
                            <MainButton
                                disabled={isLoading}
                                title={t("AddTask")}
                                onClick={() => setShowAddTaskModal(true)}
                                icon={<PlusIcon/>}
                            />
                            <MainButton
                                disabled={isLoading || isReportPreparing}
                                title={t("DownloadReport")}
                                onClick={handleDownloadReport}
                                inProgress={isReportPreparing}
                                icon={<LuDownload/>}
                            />
                        </>
                    )}
                    <div className="relative">
                        {filterLength > 0 && (
                            <span
                                className="absolute top-[-10px] right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold text-red-100 bg-dark-green rounded-full"
                            >
                                {filterLength}
                            </span>
                        )}
                        <SecondaryButton
                            title={t("Filter")}
                            icon={<FilterApartmentsIcon/>}
                            onClick={() => setShowTasksModal(true)}
                        />
                    </div>
                    <button
                        onClick={toggleViewTasks}
                        className="text-dark-green cursor-pointer p-1 text-sm flex items-center gap-2"
                    >
                        {tasksView === "Cards" ? t(`ViewAsList`) : t(`ViewAsCards`)}
                    </button>
                </div>
            </div>
            {
                error && <AlertDanger errorMessages={[error]}/>
            }
            {
                tasksView === "Cards" && (
                    <div className={"mt-3"}>
                        <Loading loading={isLoading}>
                            <DataCards
                                cards={tasks}
                                showPagination={true}
                                showHeader={!isMobile}
                                pageSize={pageSize}
                                currentPage={pageNumber}
                                totalCount={totalCount}
                                onReload={handleOnReload}
                                onRenderCard={(e, index) => (
                                    <TaskCard
                                        card={e}
                                        key={index}
                                        onDoubleClick={handleDoubleClick}
                                        onChanged={handleEditTask}
                                        onDelete={handleDeleteEmployee}
                                    />
                                )}
                            >
                            </DataCards>
                        </Loading>
                    </div>
                )
            }

            {
                tasksView === "List" && (
                    <div>
                        <Loading loading={isLoading}>
                            <DataTable
                                columns={columns}
                                items={tasks}
                                currentPage={pageNumber}
                                totalCount={totalCount}
                                pageSize={pageSize}
                                onDoubleClick={handleDoubleClick}
                                onReload={handleOnReload}
                            />
                        </Loading>
                    </div>
                )
            }

            {
                isOpenedModal && (
                    <TasksDetailModal
                        taskId={selectedTaskId}
                        onClose={handleCloseModal}
                    />
                )
            }

            {
                showTasksModal && (
                    <TasksFilterModal
                        onClose={() => handleSetTasksFilter({})}
                        onApply={(newFilter) => handleSetTasksFilter(newFilter)}
                        filter={tasksFilter}
                    />
                )
            }

            {
                showAddTaskModal && (
                    <EditTaskModal
                        onClose={handleCloseModal}
                        onChanged={handleUpdateTasksList}
                        taskId={selectedTaskId}
                    />
                )
            }

            {
                showEmployeeDeleteQuestionModal && (
                    <QuestionModal onClose={() => setShowEmployeeDeleteQuestionModal(false)}
                                   onYes={handleDeleteEmployeeConfirmed}
                                   title={t("AreYouSureYouWantToDeleteThisTask")}
                                   content={t("ThisActionCannotBeUndone")}
                                   icon={<DeleteItemIcon/>} type={"danger"}
                                   yesButtonText={t("Yes")}
                                   noButtonText={t("No")}/>
                )
            }

            {showChangeStatusConfirmQuestionModal && currentTask && (
                <ChangeStatusConfirmQuestionModal onClose={() => setShowChangeStatusConfirmQuestionModal(false)}
                                                  onYes={handleChangeStatusConfirmed}
                                                  taskId={currentTask.id}
                                                  title={t("AreYouSureYouWantToChangeThisTaskStatus")}
                                                  content={t("ThisActionCannotBeUndone")}
                                                  icon={<LuCombine size={24} color={ThemeColors.mainColor}/>}
                                                  type={"regular"}
                />
            )}
        </PageContainer>
    )
        ;
};

export default TasksList;
  