import React, {FC, PropsWithChildren} from 'react';

type Props = {}

const PageCard: FC<PropsWithChildren<Props>> = ({children}) => {
    return (
        <div className="flex flex-col bg-black-secondary-strong rounded-2xl">
            {children}
        </div>
    );
};

export default PageCard;