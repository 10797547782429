﻿import React, {FC, PropsWithChildren} from 'react';

type Props = {}

const BaseModalContent: FC<PropsWithChildren<Props>> = ({children}) => {
    return (
        <>
            <div className="bg-black-secondary-strong px-5 pt-2 pb-5 sm:w-[428px] h-full w-full">
                {children}
            </div>
        </>
    );
};

export default BaseModalContent;