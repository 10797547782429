import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        isOpen: false,
    },
    reducers: {
        toggle: (state) => {
            state.isOpen = !state.isOpen;
        },
        setIsOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
    },
});

export const {toggle, setIsOpen} = sidebarSlice.actions;

export const sidebarReducer = sidebarSlice.reducer;
