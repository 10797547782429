import React, {FC, PropsWithChildren} from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    searchText: string;
    onSearchTextChanged: (text: string) => void;
}

const SearchInput: FC<PropsWithChildren<Props>> = ({searchText, onSearchTextChanged}) => {
    const {t} = useTranslation()
    return (
        <>
            <input type={"text"}
                   className={"flex h-[36px] font-myriad text-xs font-regular items-center " +
                       "justify-center rounded border-[1px] border-black-10 p-3.5 md:rounded-lg hover:border-dark-green bg-black-secondary-strong dark:bg-black-secondary-strong"}
                   placeholder={t("Search...")}
                   onChange={(e) => onSearchTextChanged(e.target.value)}
                   value={searchText}/>
        </>
    );
};

export default SearchInput;