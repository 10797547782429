import {useDetectClickOutside} from "react-detect-click-outside";

import {useAppDispatch, useAppSelector} from "../../../redux/hooks/hooks";
import userIcon from "./res/User profile picture.svg";
import {setIsOpen} from "../../../redux/slices/SideBarSlice";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    doLogout, selectAuthUserDetails,
    selectIsAdmin,
    selectIsSupervisor, selectUserType,
} from "../../../redux/slices/authSlice";
import {AdminSidebarMenu, EmployeeSidebarMenu, SupervisorSidebarMenu} from "./SidebarMenu";
import FAQIcon from "../UserProfile/Account/res/FAQIcon";
import SettingsIcon from "../UserProfile/Account/res/SettingsIcon";
import {useTranslation} from "react-i18next";
import LogOutIcon from "../UserProfile/Account/res/LogOutIcon";
import {animated, useSpring} from "react-spring";
import OwnerUserIcon from "../UserProfile/Account/res/OwnerUserIcon";
import NotificationIcon from "../../../assets/svg/NotificationIcon";
import {useCallback, useMemo} from "react";

const MobileSidebar = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();


    const userDetails = useAppSelector(selectAuthUserDetails);
    const userType = useAppSelector(selectUserType);
    const isAdmin = useAppSelector(selectIsAdmin);
    const isSupervisor = useAppSelector(selectIsSupervisor);
    const sidebarIsOpen = useAppSelector((state) => state.sidebar.isOpen);


    const filteredSidebar = useMemo(() => {
        switch (userType) {
            case "Admin":
                return AdminSidebarMenu;
            case "Supervisor":
                return SupervisorSidebarMenu;
            default:
                return EmployeeSidebarMenu;
        }
    }, [userType]);

    const closeDropdown = () => {
        dispatch(setIsOpen(false));
    };

    const logOuthandler = () => {
        dispatch(doLogout());
        navigate("/");
    };

    const ref = useDetectClickOutside({onTriggered: closeDropdown});

    const animationStyles = useSpring({
        to: {
            opacity: sidebarIsOpen ? 1 : 0,
            transform: sidebarIsOpen ? "translateX(0%)" : "translateX(-100%)",
        },
        from: {
            opacity: 0,
            transform: "translateX(-100%)",
        },
        config: {duration: 300},
    });

    return (
        <animated.div
            className={`fixed left-0 top-0 z-10 h-full w-[280px] bg-black-secondary-strong`}
            ref={ref}
            style={animationStyles}
        >
            <div className="flex flex-row items-center gap-[10px] border-b-[1px] border-black-10  p-4">
                <img src={userIcon} alt="userIcon"/>
                <div className="flex flex-col">
                    <span>{userDetails?.fullName}</span>
                    <span>{userType}</span>
                </div>
            </div>
            <div className="border-b-[1px] border-black-10 p-4">
                {filteredSidebar.map((menuItem) => {
                    return (
                        <Link
                            to={`${menuItem.path}`}
                            key={menuItem.path}
                            className={`flex flex-row font-myriad text-sm font-regular text-black-80 ${sidebarIsOpen ? "justify-start" : "justify-center"} svg-container items-center gap-2 p-2.5 hover:rounded-lg hover:bg-dark-green-10  ${location.pathname === menuItem.path ? "active-svg rounded-lg bg-dark-green-10 text-dark-green" : ""} sidebar-container`}
                            onClick={closeDropdown}
                        >
                            {menuItem.icon}
                            {sidebarIsOpen && menuItem.title}
                        </Link>
                    );
                })}
            </div>
            <div className="border-b-[1px] border-black-10 p-4">
                {/*{isSupervisor && (*/}
                {/*    <>*/}
                <div className="cursor-pointer  hover:rounded-lg hover:bg-dark-green-10">
                    <Link
                        to="/notifications"
                        className={`flex flex-row font-myriad text-sm font-regular text-black-80 ${sidebarIsOpen ? "justify-start" : "justify-center"} svg-container items-center gap-2 p-2.5 hover:rounded-lg hover:bg-dark-green-10  ${location.pathname === "/notifications" ? "active-svg rounded-lg bg-dark-green-10 text-dark-green" : ""} sidebar-container`}
                        onClick={closeDropdown}
                    >
                        <NotificationIcon/>
                        {t("Notifications")}
                    </Link>
                </div>
                {/*        <div className="cursor-pointer  hover:rounded-lg hover:bg-dark-green-10">*/}
                {/*            <Link*/}
                {/*                to="/myaccount"*/}
                {/*                className={`flex flex-row font-myriad text-sm font-regular text-black-80 ${sidebarIsOpen ? "justify-start" : "justify-center"} svg-container items-center gap-2 p-2.5 hover:rounded-lg hover:bg-dark-green-10  ${location.pathname === "/myaccount" ? "active-svg rounded-lg bg-dark-green-10 text-dark-green" : ""} sidebar-container`}*/}
                {/*                onClick={closeDropdown}*/}
                {/*            >*/}
                {/*                <OwnerUserIcon/>*/}
                {/*                {t("My account")}*/}
                {/*            </Link>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}
                <div className="cursor-pointer  hover:rounded-lg hover:bg-dark-green-10">
                    <Link
                        to="/settings"
                        className={`flex flex-row font-myriad text-sm font-regular text-black-80 ${sidebarIsOpen ? "justify-start" : "justify-center"} svg-container items-center gap-2 p-2.5 hover:rounded-lg hover:bg-dark-green-10  ${location.pathname === "/settings" ? "active-svg rounded-lg bg-dark-green-10 text-dark-green" : ""} sidebar-container`}
                        onClick={closeDropdown}
                    >
                        <SettingsIcon/>
                        {t("Settings")}
                    </Link>
                </div>
            </div>
            <div className="p-4">
                <div className="cursor-pointer p-2.5 hover:rounded-lg hover:bg-dark-green-10">
                    <div onClick={logOuthandler}>
                        <a className="flex flex-row items-center justify-start gap-2.5 font-myriad text-sm font-regular text-black-primary">
                            <LogOutIcon/>
                            {t("Log out")}
                        </a>
                    </div>
                </div>
            </div>
        </animated.div>
    );
};

export default MobileSidebar;
