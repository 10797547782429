import {apartmentModalReducer} from "../slices/ApartmentModalSlice";
import {authReducer} from "../slices/authSlice";
import {languageDropdownReducer} from "../slices/LanguageDropdownSlice";
import {sidebarReducer} from "../slices/SideBarSlice";
import {appReducer} from "../slices/appSlice.ts";
import { ongoingTasksReducer } from "../slices/OngoingTasksSlice.ts";
import {tasksPageReducer} from "../slices/tasksSlice.ts"


const rootReducer = {
    auth: authReducer,
    sidebar: sidebarReducer,
    languageDropdown: languageDropdownReducer,
    apartmentModal: apartmentModalReducer,
    app: appReducer,
    tasksView: ongoingTasksReducer,
    tasksPage: tasksPageReducer,
};

export default rootReducer;
