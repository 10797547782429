import React, {FC, PropsWithChildren, useCallback, useEffect, useState} from "react";
import useFetchEmployeeTasks from "../../../hooks/useFetchEmployeeTasks.ts";
import {useTranslation} from "react-i18next";
import BaseModal from "../../../components/Modals/BaseModal";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import Loading from "../../../components/Loading/Loading";
import {axiosInstance, defaultDateTimeFormat} from "../../../config/appConfig.ts";
import {format, intervalToDuration} from "date-fns";
import {DateHelper} from "../../../helpers/formatDate.ts";
import {EnumHelper} from "../../../helpers/enumHelper.tsx";
import AlertDanger from "../../../components/Alerts/AlertDanger.tsx";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons.tsx";
import SecondaryButton from "../../../components/buttons/SecondaryButton.tsx";
import ChangeStatusConfirmQuestionModal from "./ChangeStatusConfirmQuestionModal.tsx";
import {EmployeeTaskStatusEnum} from "../../../enums/EmployeeTaskStatusEnum.ts";
import {LuCombine} from "react-icons/lu";
import {ThemeColors} from "../../../theme/colors.ts";
import {DateTimeHelper} from "../../../utils/DateTimeHelper.ts";
import UpdateResponseDto from "../../../dtos/BaseResponse/UpdateResponseDto.ts";
import EmployeeTaskDto from "../../../dtos/EmployeeTask/EmployeeTaskDto.ts";
import toast from "react-hot-toast";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/hooks.ts";
import {setTaskRefreshIndex} from "../../../redux/slices/tasksSlice.ts";
import DataTable from "../../../components/DataTable/DataTable.tsx";
import {selectUserType} from "../../../redux/slices/authSlice.ts";

type Props = {
    taskId: string;
    onClose: () => void;
}

const TasksOngoingDetailsModal: FC<PropsWithChildren<Props>> = ({taskId, onClose}) => {
    const {t} = useTranslation();
    const {fetchTask, task, isLoading, error} = useFetchEmployeeTasks();
    const [showChangeStatusConfirmQuestionModal, setShowChangeStatusConfirmQuestionModal] = useState(false);
    const [isChangingStatus, setIsChangingStatus] = useState(false);
    const dispatch = useAppDispatch();

    const deadlineDateInTime = task?.deadline ? new Date(task?.deadline).getTime() : 0;
    const isDeadline = new Date().getTime() > deadlineDateInTime;
    const isClosedStatus = task?.status === EmployeeTaskStatusEnum.Closed;
    const userType = useAppSelector(selectUserType);
    const showDates = userType === "Admin" || userType === "Supervisor";

    const DeadlineClosedDuration = task?.deadline && task.closedAt
        ? intervalToDuration({
            start: task.deadline,
            end: task.closedAt
        })
        : null;

    const AssignationDuration = task?.createdAt && task.assignedAt
        ? intervalToDuration({
            start: task.createdAt,
            end: task.assignedAt
        })
        : null;

    const StartingDuration = task?.assignedAt && task.startedAt
        ? intervalToDuration({
            start: task.startedAt,
            end: task.assignedAt
        })
        : null;

    const TaskDuration = task?.closedAt && task.startedAt
        ? intervalToDuration({
            start: task.startedAt,
            end: task.closedAt
        })
        : null;

    const InspectionDuration = task?.closedAt && task.inspectedAt
        ? intervalToDuration({
            start: task.inspectedAt,
            end: task.closedAt
        })
        : null;

    useEffect(() => {
        fetchTask(taskId);
    }, [taskId]);

    const handleMoveToNextStatus = useCallback(() => {
        setShowChangeStatusConfirmQuestionModal(true);
    }, [task]);

    const handeChangeStatusConfirmed = useCallback(async (assigneeId?: string) => {
        if (!task) return;
        setShowChangeStatusConfirmQuestionModal(false);
        try {
            setIsChangingStatus(true);
            const url = `EmployeeTasks/ChangeStatus`;
            const requestDto = {
                id: task.id,
                assigneeId,
            }
            const response = await axiosInstance.patch<UpdateResponseDto<EmployeeTaskDto>>(url,
                requestDto,
                {
                    validateStatus: (status) => true,
                });

            if (response.status !== 200) {
                if (response.data.errorMessage) {
                    throw new Error(response.data.errorMessage);
                }
                throw new Error("Failed to change status of the task.");
            }

            if (response?.data?.item?.status) {
                await fetchTask(taskId);
            }

            toast.success("Task status changed successfully.");
            dispatch(setTaskRefreshIndex());
        } catch (e: any) {
            console.error(e);
            toast.error(e.message);
        } finally {
            setIsChangingStatus(false);
        }
    }, [task]);

    return (
        <>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={`${t("TaskDetails")} - ${task?.code}`} onClose={onClose}/>
                    {error && <AlertDanger errorMessages={[error]}/>}
                    <Loading loading={isLoading || isChangingStatus} type={"cover"}>
                        <div className={"grid grid-cols-2 gap-3 font-myriad text-xs border-y-[1px] px-3 py-3"}>
                            <div className={"text-black"}>{t("Type")}</div>
                            <div className={"text-end"}>{task?.typeDescription}</div>

                            <div className={"text-black"}>{t("Predefined")}</div>
                            <div className={"text-end"}>{task?.predefinedDescriptionDescription}</div>

                            <div className={"text-black"}>{t("Room/Location")}</div>
                            <div className={"text-end"}>{task?.apartmentRoomNo ?? task?.locationName}</div>

                            <div className={"text-black"}>{t("Service")}</div>
                            <div className={"text-end"}>{task?.typeDescription}</div>

                            <div className={"text-black"}>{t("Employee")}</div>
                            <div className={"text-end"}>{task?.assignedEmployeeFullName}</div>

                            <div className={"text-black"}>{t("Department")}</div>
                            <div className={"text-end"}>{task?.departmentDescription}</div>

                            <div className={"text-black"}>{t("Source")}</div>
                            <div className={"text-end"}>{task?.sourceDescription}</div>

                            <div className={"text-black"}>{t("Status")}</div>
                            <div className={"text-end flex justify-end"}>
                                <div className={"flex text-end"}>
                                    {EnumHelper.getEmployeeTaskStatusKey(task?.status)}
                                    <span className={"ms-2"}>
                                        {EnumHelper.getEmployeeTaskStatusIcon(task?.status)}
                                    </span>
                                </div>
                            </div>

                            <div className={"text-black"}>{t("DateStart")}</div>
                            <div className="text-end">
                                {DateHelper.formatDefaultDateWithTime(task?.createdAt)}
                            </div>

                            <div className="text-black">{t("Deadline")}</div>
                            <div className="text-end">
                                {isDeadline ? (
                                    <div className="animate-pulse text-red-400">
                                        {task?.deadline ? DateHelper.formatDefaultDateWithTime(task.deadline) : '-'}
                                    </div>
                                ) : (
                                    task?.deadline ? DateHelper.formatDefaultDateWithTime(task.deadline) : '-'
                                )}
                            </div>

                            <div className="text-black">{t("Expired")}</div>
                            <div className={"text-end"}>
                                {isDeadline
                                    ? <div className="text-red-400">{t("Yes")}</div>
                                    : t("No")}
                            </div>

                            {isDeadline && isClosedStatus && (
                                <>
                                    <div className="text-black">{t("ExpirationTime")}</div>
                                    <div className="text-end">
                                        {DeadlineClosedDuration
                                            ? `${Math.abs(DeadlineClosedDuration.days ? DeadlineClosedDuration.days : 0)} d ${Math.abs(DeadlineClosedDuration.hours ? DeadlineClosedDuration.hours : 0)} h ${Math.abs(DeadlineClosedDuration.minutes ? DeadlineClosedDuration.minutes : 0)} m`
                                            : "-"
                                        }
                                    </div>
                                </>
                            )}
                            <div className={"text-black"}>{t("TaskCreator")}</div>
                            <div className={"text-end"}>{task?.createdByFullName}</div>

                            <div className={"text-black"}>{t("CreationDateAndTime")}</div>
                            <div className={"text-end"}>
                                {DateHelper.formatDefaultDateWithTime(task?.createdAt) || "-"}
                            </div>

                            {showDates && <>
                                <div className={"text-black"}>{t("AssignationDuration")}</div>
                                <div className={"text-end"}>
                                    {AssignationDuration
                                        ? `${Math.abs(AssignationDuration.days ? AssignationDuration.days : 0)} d ${Math.abs(AssignationDuration.hours ? AssignationDuration.hours : 0)} h ${Math.abs(AssignationDuration.minutes ? AssignationDuration.minutes : 0)} m`
                                        : "-"
                                    }
                                </div>

                                <div className={"text-black"}>{t("AssignationDateAndTime")}</div>
                                <div className={"text-end"}>
                                    {DateHelper.formatDefaultDateWithTime(task?.assignedAt) || "-"}
                                </div>

                                <div className={"text-black"}>{t("StartingDuration")}</div>
                                <div className={"text-end"}>
                                    {StartingDuration
                                        ? `${Math.abs(StartingDuration.days ? StartingDuration.days : 0)} d ${Math.abs(StartingDuration.hours ? StartingDuration.hours : 0)} h ${Math.abs(StartingDuration.minutes ? StartingDuration.minutes : 0)} m`
                                        : "-"
                                    }
                                </div>

                                <div className={"text-black"}>{t("StartingDateAndTime")}</div>
                                <div className={"text-end"}>
                                    {DateHelper.formatDefaultDateWithTime(task?.startedAt) || "-"}
                                </div>

                                <div className={"text-black"}>{t("TaskDuration")}</div>
                                <div className={"text-end"}>
                                    {TaskDuration
                                        ? `${Math.abs(TaskDuration.days ? TaskDuration.days : 0)} d ${Math.abs(TaskDuration.hours ? TaskDuration.hours : 0)} h ${Math.abs(TaskDuration.minutes ? TaskDuration.minutes : 0)} m`
                                        : "-"
                                    }
                                </div>

                                <div className={"text-black"}>{t("EndingDateAndTime")}</div>
                                <div className={"text-end"}>
                                    {DateHelper.formatDefaultDateWithTime(task?.closedAt) || "-"}
                                </div>

                                <div className={"text-black"}>{t("InspectionDuration")}</div>
                                <div className={"text-end"}>
                                    {InspectionDuration
                                        ? `${Math.abs(InspectionDuration.days ? InspectionDuration.days : 0)} d ${Math.abs(InspectionDuration.hours ? InspectionDuration.hours : 0)} h ${Math.abs(InspectionDuration.minutes ? InspectionDuration.minutes : 0)} m`
                                        : "-"
                                    }
                                </div>

                                <div className={"text-black"}>{t("InspectedDateAndTime")}</div>
                                <div className={"text-end"}>
                                    {DateHelper.formatDefaultDateWithTime(task?.inspectedAt) || "-"}
                                </div>
                            </>}
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonDisabled={isChangingStatus || isLoading || task?.status === EmployeeTaskStatusEnum.Inspected}
                    showCloseButton={false}
                    showSaveButton={true}
                    onSave={handleMoveToNextStatus}
                    saveButtonIcon={<LuCombine size={24} color={ThemeColors.whiteColor}/>}
                    saveButtonText={t("MoveToNextStatus")}
                    extraClassName={"flex justify-end"}
                >
                </BaseModalButtons>
            </BaseModal>
            {showChangeStatusConfirmQuestionModal && task && (
                <ChangeStatusConfirmQuestionModal onClose={() => setShowChangeStatusConfirmQuestionModal(false)}
                                                  onYes={handeChangeStatusConfirmed}
                                                  taskId={task.id}
                                                  title={t("ChangeStatus")}
                                                  content={`${t("AreYouSureYouWantToChangeStatusOfThisTask")} ${t("ThisActionCannotBeUndone")}`}
                                                  icon={<LuCombine size={24} color={ThemeColors.mainColor}/>}
                                                  type={"regular"}
                />
            )}
        </>
    );
};

export default TasksOngoingDetailsModal;
