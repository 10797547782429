import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import notificationIcon from "./res/Notification.svg";
import {useNavigate} from "react-router-dom";
import {useDetectClickOutside} from "react-detect-click-outside";
import {useTranslation} from "react-i18next";
import useFetchMessages from "../../../../hooks/useFetchMessages.ts";
import {StringHelper} from "../../../../utils/StringHelper.ts";
import Loading from "../../../Loading/Loading.tsx";
import {format} from "date-fns";
import {MessageDto} from "../../../../dtos/messages/MessageDto.ts";
import {useAppSelector} from "../../../../redux/hooks/hooks.ts";
import {selectNotificationsRefreshIndex} from "../../../../redux/slices/appSlice.ts";
import {LuMailOpen} from "react-icons/lu";
import {ThemeColors} from "../../../../theme/colors.ts";
import TasksDetailModal from "../../../../pages/TasksPage/modals/TasksDetailsModal.tsx";

const NotificationsMenu = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isNotificationDropdownVisible, setIsNotificationDropdownVisible] =
        useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(1);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState<MessageDto | undefined>(undefined);

    const {
        unreadCount,
        messages: rawMessages,
        fetchUnreadMessagesCount,
        fetchMessages,
        isLoading,
        error
    } = useFetchMessages();
    const notificationsRefreshIndexer = useAppSelector(selectNotificationsRefreshIndex);

    const notificationPageHandler = () => {
        navigate("/messages");
        setIsNotificationDropdownVisible(false);
    };

    const closeDropdown = () => {
        setIsNotificationDropdownVisible(false);
    };

    const dropdownRef = useRef<HTMLDivElement>(null);
    const ref = useDetectClickOutside({onTriggered: closeDropdown});

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdownVisible(!isNotificationDropdownVisible);
    };

    const fetchData = useCallback(async () => {
        await fetchUnreadMessagesCount();
        await fetchMessages(undefined, undefined, undefined, 0, 5, false);
    }, [activeTabIndex, notificationsRefreshIndexer]);

    useEffect(() => {
        fetchData();
    }, [activeTabIndex, notificationsRefreshIndexer]);

    const handleOpenMessage = (message: MessageDto) => {
        navigate(`/messages/${message.id}`);
        closeDropdown();
    };

    const handleShowTaskModal = (message: MessageDto) => {
        closeDropdown();
        setSelectedMessage(message);
        setShowTaskModal(true);
    };

    return (
        <>
            <div className="relative" ref={ref}>
                <div
                    className="flex h-[40px] w-[40px] cursor-pointer flex-col items-center"
                    onClick={toggleNotificationDropdown}
                >
                    {unreadCount === 0 && <img src={notificationIcon} className="p-2.5" alt="notification"/>}
                    {unreadCount > 0 && <span className="relative inline-block">                    
                    <img src={notificationIcon} className="p-2.5" alt="notification"/>
                    <span
                        className="animate-bounce absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-2/2 -translate-y-2/2 bg-dark-green rounded-full">
                        {unreadCount < 100 ? unreadCount : "99+"}
                    </span>
                    </span>
                    }
                </div>
                {isNotificationDropdownVisible && (
                    <div
                        className="absolute right-0  bg-black-secondary-strong shadow  md:rounded-2xl z-50"
                        ref={dropdownRef}
                    >
                        <Loading type={"cover"} loading={isLoading}>
                            <div className="border-b-[1px] border-black-10">
                                <div
                                    className="h-[272px] w-[373.33px] overflow-auto font-myriad text-sm font-regular text-black-60 mt-3">
                                    {!rawMessages || rawMessages.length === 0 &&
                                        <div className={"w-full h-full flex flex-col align-middle justify-center"}>
                                            <div className={"text-center"}>{t("NoNotifications")}</div>
                                        </div>}
                                    <div>
                                        {rawMessages && rawMessages.length > 0 &&
                                            <div>
                                                {rawMessages?.map((message, index) => (
                                                    <div key={index}
                                                         className={`w-full py-3 px-3 hover:bg-black-10 ${index === 0 ? "" : "border-black-30 border-t-[1px]"}`}>
                                                        <div className={"flex flex-row justify-between"}>
                                                            <div className={"cursor-pointer "}
                                                                 onClick={() => handleOpenMessage(message)}>
                                                                {StringHelper.cutStringByLengthAddDots(message.messageContent, 100)}
                                                            </div>
                                                            <div>
                                                                <a href={"#"}
                                                                   onClick={() => handleShowTaskModal(message)}>
                                                                    <LuMailOpen
                                                                        className={"text-dark-green hover:text-green-800"}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={"flex flex-row justify-between mt-2 cursor-pointer"}
                                                            onClick={() => handleOpenMessage(message)}>
                                                            <div>{format(message.messageDate, "dd MMM yyyy HH:mm")}</div>
                                                            <div> {message.isRead ?
                                                                <span className={"text-black-30"}>{t("Read")}</span> :
                                                                <span
                                                                    className={"text-dark-green"}>{t("Unread")}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex h-[52px] w-[373.33px] cursor-pointer items-center justify-center rounded-b-2xl px-6 py-4 text-center font-myriad text-sm font-regular text-dark-green hover:bg-black-10"
                                onClick={notificationPageHandler}
                            >
                                {t("SeeAllNotifications")}
                            </div>
                        </Loading>
                    </div>
                )}
            </div>
            {showTaskModal && selectedMessage && selectedMessage.employeeTaskId && (
                <TasksDetailModal
                    taskId={selectedMessage.employeeTaskId}
                    onClose={() => setShowTaskModal(false)}
                />)}
        </>
    );
};

export default NotificationsMenu;
