import en from './en/en.json'
import ka from './ka/ka.json'
import ru from './ru/ru.json'

export const languages = {
    en: {
        translation: {...en}
    },
    ka: {
        translation: {...ka}
    },
    ru: {
        translation: {...ru}
    }
};
