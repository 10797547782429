import Spinner from "../../assets/svg/Spinner";

const Loader = () => {
  return (
    <div className="white-overlay">
      <div className="animate-spin">
        <Spinner />
      </div>
    </div>
  );
};

export default Loader;
