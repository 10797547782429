import {t} from "i18next";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageContainer from "../../components/PageContainer/PageContainer";
import DataTable from "../../components/DataTable/DataTable";
import useFetchDepartment from "../../hooks/useFetchDepartments";
import Loading from "../../components/Loading/Loading";
import {useCallback, useEffect, useState} from "react";
import AlertDanger from "../../components/Alerts/AlertDanger";
import PlusIcon from "../../assets/svg/buttonIcons/PlusIcon";
import MainButton from "../../components/buttons/MainButton";
import TableEditIcon from "../../assets/svg/TableEditIcon";
import DeleteItemIcon from "../../assets/svg/DeleteItemIcon";
import {CreateDepartmentDto, DepartmentDto} from "../../dtos/departments/DepartmentDto";
import useFetchData from "../../hooks/useFetchData";
import QuestionModal from "../../components/QuestionModal/QuestionModal";
import SearchInput from "../../components/inputs/SearchInput.tsx";
import { EditLocationModal } from "./modals/EditLocationModal.tsx";

const LocationsPage = () => {
    const {
        items,
        error,
        deleteItem,
        fetchItems,
        isLoading,
        totalCount,
    } = useFetchData<CreateDepartmentDto, DepartmentDto, DepartmentDto>("Locations")

    const [selectLocationId, setSelectedLocationId] = useState<string | undefined>(undefined);
    const [showEmployeeDeleteQuestionModal, setShowEmployeeDeleteQuestionModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState<string>("");

    const columns: DataTableColumn[] = [
        {
            title: t("LocationName"),
            field: "name"
        },
        {
            title: t("Actions"),
            field: "actions",
            render: (value: any) => (
                <div className="flex gap-2">
                    <button onClick={() => handleEditLocation(value)}><TableEditIcon/></button>
                    <button onClick={() => handleDeleteLocation(value)}><DeleteItemIcon/></button>
                </div>
            )
        }
    ]

    console.log(items);

    const handleOnReload = (newPageSize, newPageNumber) => {
        setPageSize(newPageSize);
        setPageNumber(newPageNumber);
    };
    const handleCloseModal = async () => {
        setShowEditModal(false);
    }

    const handleLocationsUpdate = async () => {
        await handleCloseModal();
        await fetchData();
    };

    const handleNewLocation = () => {
        setSelectedLocationId(undefined);
        setShowEditModal(true);
    };

    const handleEditLocation = (department: DepartmentDto) => {
        setSelectedLocationId(department.id);
        setShowEditModal(true);
    };

    const handleDeleteLocation = async (department: DepartmentDto) => {
        setSelectedLocationId(department.id);
        setShowEmployeeDeleteQuestionModal(true);
    };

    const handleDeleteLocationConfirmed = async () => {
        setShowEmployeeDeleteQuestionModal(false);
        if (!selectLocationId) return;

        await deleteItem(selectLocationId);
        await fetchData();
    };

    const fetchData = useCallback(async () => {
        await fetchItems(searchText, (pageNumber - 1) * pageSize, pageSize);
    }, [pageSize, pageNumber, searchText]);

    useEffect(() => {
        fetchData();
    }, [pageSize, pageNumber, searchText])

    return (
        <PageContainer>
            <PageHeader title={t("Locations")}>
                <div className={"flex gap-2"}>
                    <SearchInput searchText={searchText} onSearchTextChanged={setSearchText}/>
                    <MainButton
                        disabled={isLoading}
                        title={t("NewLocation")}
                        onClick={handleNewLocation}
                        icon={<PlusIcon/>}
                    />
                </div>
            </PageHeader>
            {error && <AlertDanger errorMessages={[error]}/>}
            <Loading loading={isLoading}>
                <div className={"mt-2"}>
                    <DataTable
                        items={items}
                        columns={columns}
                        pageSize={pageSize}
                        currentPage={pageNumber}
                        totalCount={totalCount}
                        onReload={handleOnReload}
                    />
                </div>
            </Loading>
            {showEditModal && (
                <EditLocationModal
                    onClose={handleCloseModal}
                    onChanged={handleLocationsUpdate}
                    locationId={selectLocationId}
                />
            )}

            {showEmployeeDeleteQuestionModal && (
                <QuestionModal onClose={() => setShowEmployeeDeleteQuestionModal(false)}
                               onYes={handleDeleteLocationConfirmed}
                               title={t("AreYouSureYouWantToDeleteThisLocation")}
                               content={t("ThisActionCannotBeUndone")}
                               icon={<DeleteItemIcon/>} type={"danger"}
                               yesButtonText={t("Yes")}
                               noButtonText={t("No")}/>
            )}
        </PageContainer>
    );
};

export default LocationsPage;
