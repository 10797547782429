import {ReactNode} from "react";
import {useTranslation} from "react-i18next";

type Props = {
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    extraClassName?: string;
    isSubmitting?: boolean;
    icon?: ReactNode;
};

const SecondaryButton = ({
                             title,
                             className,
                             extraClassName,
                             onClick,
                             disabled,
                             isSubmitting,
                             icon,
                         }: Props) => {
    const {t} = useTranslation();
    return (
        <button
            type="button"
            className={className ? className : `select-none flex h-[36px] flex-row justify-center sm:justify-start items-center gap-2 rounded-lg py-2 pl-[9px] pr-[13px] font-myriad text-sm font-regular text-dark-green bg-black-secondary-strong disabled:text-black-secondary-strong disabled:bg-black-20 hover:bg-dark-green-10 border-[1px] border-black-20 ${extraClassName}`}
            disabled={isSubmitting === true || disabled === true}
            onClick={onClick}
        >
            {/*{isSubmitting && (*/}
            {/*    <span className="loading loading-spinner loading-sm"></span>*/}
            {/*)}*/}
            {icon}
            {t(title)}
        </button>
    );
};

export default SecondaryButton;
