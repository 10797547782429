import ApartmentDto from "../dtos/apartments/ApartmentDto.ts";
import {useState} from "react";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto.ts";
import {axiosInstance} from "../config/appConfig.ts";

const useFetchApartments = () => {
    const [apartments, setApartments] = useState<ApartmentDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const apartmentOptions: SelectControlOption<string>[] = apartments.map((apartment) => ({
        value: apartment.id,
        label: apartment.roomNo
    })) || [];

    const fetchApartments = async () => {
        try {
            setIsLoading(true);
            setError(undefined);

            const response = await axiosInstance.get<ListResponseDto<ApartmentDto>>("Apartments");
            setApartments(response.data.items);

            return response.data.items;
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return {
        apartments,
        isLoading,
        error,
        fetchApartments,
        apartmentOptions
    };
}

export default useFetchApartments;