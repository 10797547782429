import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {LoginResponseDto} from "../../dtos/ILoginDto/LoginResponseDto.ts";
import {UserDetailsDto} from "../../dtos/identity/UserDetailsDto.ts";
import {axiosInstance, localStorageTokenKey} from "../../config/appConfig.ts";
import UserType from "../../types/UserType.ts";
import {LoginRequestDto} from "../../dtos/ILoginDto/LoginRequestDto.ts";
import {getToken} from "firebase/messaging";
import {messaging} from "../../firebase/firebaseConfig";

// const storedUser = localStorage.getItem(localStorageTokenKey);
// const initialUser = null; storedUser ? JSON.parse(storedUser) : null;
// const initialUser = null;

const extractRolesFromToken = (token: any) => {
    if (!token) return [];
    const claims = JSON.parse(atob(token.split(".")[1]));
    let roles = claims["Roles"];
    if (typeof roles === "string") {
        roles = [roles];
    }
    return roles;
};

// const extractUserDetailsFromToken = (token: any): UserDetailsDto | undefined => {
//     if (!token) return undefined;
//     const claims = JSON.parse(atob(token.split(".")[1]));
//     return {
//         id: claims["Id"],
//         fullName: claims["FullName"],
//         userName: claims["UserName"],
//         avatarUrl: claims["AvatarUrl"],
//     };
// }

// const initialRoles = initialUser
//     ? extractRolesFromToken(initialUser.token)
//     : [];
//
// const initialUserDetails = initialUser
//     ? extractUserDetailsFromToken(initialUser.token)
//     : undefined;

// const isAdmin = initialRoles.includes("Admin");
// const isSupervisor = initialRoles.includes("Supervisor");
// const isEmployee = initialRoles.includes("Employee");

type AccountState = {
    token?: LoginResponseDto;
    userDetails?: UserDetailsDto;
    isAdmin?: boolean;
    isSupervisor?: boolean;
    isEmployee?: boolean;
    loginStatus: "none" | "pending" | "fulfilled" | "rejected";
    isAuthenticated: boolean;
    logoutTimer?: ReturnType<typeof setTimeout>;
    userType: UserType;
};

const initialState: AccountState = {
    token: undefined,
    userDetails: undefined,
    loginStatus: "none",
    isAuthenticated: false,
    isAdmin: false,
    isSupervisor: false,
    isEmployee: false,
    userType: "Admin",
};

export const signInUser = createAsyncThunk(
    "Identity/Login",
    async (data: LoginRequestDto, thunkAPI) => {
        try {
            const response = await axiosInstance.post<LoginResponseDto>("Identity/Login", data);
            console.log("response", response.data);

            try {
                const token = await getToken(messaging, {
                    vapidKey: import.meta.env.VITE_APP_VAPID_KEY,
                });
                const responseSubscribe = await axiosInstance.post(`Messages/Subscribe/${token}`, {}, {
                    headers: {
                        Authorization: `Bearer ${response.data.token}`,
                    },
                    validateStatus: (status) => true,
                });
                console.log("responseSubscribe", responseSubscribe.data);
            } catch (error: any) {
                console.error("Failed to subscribe to notifications", error);
            }

            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({error: "unauthorized"});
        }
    },
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLogoutTimer: (state, action) => {
            if (state.logoutTimer) clearTimeout(state.logoutTimer);
            state.logoutTimer = action.payload;
        },
        doLogout: (state) => {
            localStorage.removeItem(localStorageTokenKey);
            state.token = undefined;
            state.userDetails = undefined;
            state.isAuthenticated = false;
            state.isAdmin = false;
            state.isSupervisor = false;
            state.isEmployee = false;
            state.userType = undefined;
            state.loginStatus = "none";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signInUser.fulfilled, (state, action) => {
                state.token = action.payload;
                console.log("payload", action.payload)

                axiosInstance.defaults.headers.common["Authorization"] =
                    `Bearer ${action.payload.token}`;

                localStorage.setItem(localStorageTokenKey, JSON.stringify(action.payload));
                state.loginStatus = "fulfilled";
                state.isAuthenticated = true;

                console.log(action.payload.token);
                console.log(action.payload.token.split(".")[1]);
                const claims = JSON.parse(atob(action.payload.token.split(".")[1]));

                state.userDetails = {
                    ...action.payload.userDetails,
                    userName: claims["UserName"],
                }

                let roles = claims["Roles"];
                if (typeof roles === "string") {
                    roles = [roles];
                }

                if (roles.includes("ADMIN")) {
                    state.userType = "Admin";
                    state.isAdmin = true;
                } else if (roles.includes("SUPERVISOR")) {
                    state.userType = "Supervisor";
                    state.isSupervisor = true;
                } else if (roles.includes("EMPLOYEE")) {
                    state.userType = "Employee";
                    state.isEmployee = true;
                } else {
                    throw new Error("Invalid user type");
                }
            })
            .addCase(signInUser.pending, (state) => {
                state.loginStatus = "pending";
                state.isAuthenticated = false;
            })
            .addCase(signInUser.rejected, (state) => {
                state.loginStatus = "rejected";
                state.isAuthenticated = false;
            });
    },
});

export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectAuthUserToken = (state: RootState) => state.auth.token;
export const selectLoginStatus = (state: RootState) => state.auth.loginStatus;
export const selectIsAdmin = (state: RootState) => state.auth.isAdmin;
export const selectIsSupervisor = (state: RootState) => state.auth.isSupervisor;
export const selectIsEmployee = (state: RootState) => state.auth.isEmployee;
export const selectUserType = (state: RootState) => state.auth.userType;
export const selectAuthUserDetails = (state: RootState) => state.auth.userDetails;
export const {setLogoutTimer, doLogout} = authSlice.actions;

export const authReducer = authSlice.reducer;
