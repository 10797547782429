import svgLogo from "../../../assets/svg/logo/newLogo.svg";
import LanguagesDropdown from "../../DropDowns/languagesDropdown/LanguagesDropdown";
import {useNavigate} from "react-router-dom";
import AccountMenu from "../UserProfile/Account/AccountMenu.tsx";
import React from "react";
import NotificationsMenu from "../UserProfile/OwnerNotifications/NotificationsMenu.tsx";

type Props = {
    isOnDashboard?: boolean;
};

const Header = ({isOnDashboard}: Props) => {
    const navigate = useNavigate();
    return (
        <div
            className="h-[64px] select-none border-b-2 border-black-10 bg-black-secondary-strong p-[16px] md:h-[72px] md:px-[32px] md:py-[16px]">
            {isOnDashboard ? (
                <div className="flex items-center justify-between">
                    <img
                        src={svgLogo}
                        width={102.83}
                        height={32}
                        alt="logo"
                        draggable="false"
                        onClick={() => navigate("/dashboard")}
                        className="cursor-pointer"
                    />
                    <div className="flex flex-row items-center gap-2">
                        <NotificationsMenu/>
                        <AccountMenu/>
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-between">
                    <div>
                        <img
                            src={svgLogo}
                            // width={128.54}
                            // height={40}
                            alt="logo"
                            draggable="false"
                            className="h-[32px] w-[102.84px] md:h-[40px] md:w-[128.54px]"
                        />
                    </div>
                    <div>
                        <LanguagesDropdown
                            showIcon={true}
                            showText={true}
                            dropdownTop={false}
                            showFullText={false}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
