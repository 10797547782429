const PrevIcon = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99997 9C4.99997 9 1.00001 6.05404 1 4.99997C0.999991 3.94589 5 1 5 1"
        stroke="#667B8F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PrevIcon;
