import {t} from "i18next";
import useFetchData from "../../../hooks/useFetchData";
import Loading from "../../../components/Loading/Loading";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import AlertDanger from "../../../components/Alerts/AlertDanger";
import BaseModal from "../../../components/Modals/BaseModal";
import {FormProvider, useForm} from "react-hook-form";
import {useCallback, useEffect} from "react";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import {toast} from "react-hot-toast";
import FormTextInput from "../../../components/FormControls/FormTextInput";
import {CreateTaskSourceDto, TaskSourceDto} from "../../../dtos/taskSource/TaskSourceDto";
import {CreateTaskTypeDto, TaskTypeDto} from "../../../dtos/taskType/TaskType";

type Props = {
    onClose: () => void;
    onChanged: () => void;
    taskTypeId?: string;
}

type EditTaskTypeFormModel = {
    id?: string,
    description: string,
}

export const EditTaskTypeModal: React.FC<Props> = ({
                                                       onClose,
                                                       taskTypeId,
                                                       onChanged,
                                                   }) => {
    const {
        createItem,
        updateItem,
        item,
        fetchItem,
        error,
        isLoading,
        isSubmitting
    } = useFetchData<CreateTaskTypeDto, TaskTypeDto, TaskTypeDto>("EmployeeTaskTypes");

    const isBusy = isLoading || isSubmitting;

    const handleAddOrUpdateTaskType = useCallback(async (data: EditTaskTypeFormModel) => {
        if (!taskTypeId) {
            const newTAskSource: CreateTaskTypeDto = {
                description: data.description,
            };
            await createItem(newTAskSource);
        } else {
            const updateTaskType: TaskTypeDto = {
                id: taskTypeId,
                description: data.description,
            };
            await updateItem(taskTypeId, updateTaskType);
        }

        onChanged();
        toast.success(t("TaskTypeSavedSuccessfully"));
    }, [taskTypeId, onChanged]);

    const hookForm = useForm<EditTaskTypeFormModel>();
    const {reset, handleSubmit} = hookForm;

    const fetchData = useCallback(async () => {

        if (taskTypeId) {
            const taskType = await fetchItem(taskTypeId);
            if (taskType) {
                reset({
                    id: taskType.id,
                    description: taskType.description,
                });
            }
        }
    }, [taskTypeId]);

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={!taskTypeId ? (t("AddTaskType")) : (t("EditTaskType"))}
                                     onClose={onClose}/>
                    {error && <AlertDanger errorMessages={[error]}/>}
                    <Loading
                        loading={isBusy}
                        type={"cover"}
                    >
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="description"
                                type="text"
                                label={t("Name")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Save")}
                    showCloseButton={false}
                    onSave={handleSubmit(handleAddOrUpdateTaskType)}
                    saveButtonDisabled={isBusy}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    )
}
