import {useState} from "react";
import {UrlHelper} from "../helpers/UrlHelper.ts";
import {axiosInstance} from "../config/appConfig.ts";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto.ts";
import CreateResponseDto from "../dtos/BaseResponse/CreateResponseDto.ts";
import {DateTimeHelper} from "../utils/DateTimeHelper.ts";
import {MessageDto} from "../dtos/messages/MessageDto.ts";
import {CreateMessageDto} from "../dtos/messages/CreateMessageDto.ts";
import {MessageReceiverDto} from "../dtos/messages/MessageReceiverDto.ts";

const useFetchMessages = () => {
    const [messages, setMessages] = useState<MessageDto[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [hasMandatoryMessage, setHasMandatoryMessage] = useState<boolean>(false);

    const checkMandatoryNotification = async () => {
        try {
            const response = await axiosInstance.get<ListResponseDto<MessageDto>>(
                UrlHelper.generateUrlWithParams("Messages", {
                    isMandatory: true,
                    isRead: false,
                    take: 1,
                }),
            );
            setHasMandatoryMessage(response.data.totalCount > 0);
        } catch (e: any) {
            setHasMandatoryMessage(false);
            setError(e.message);
            console.log(e);
        }
    }

    const fetchUnreadMessagesCount = async () => {
        try {
            setIsLoading(true);
            setError(undefined);
            const response = await axiosInstance.get<ListResponseDto<MessageDto>>(
                UrlHelper.generateUrlWithParams("Messages/UnreadMessagesCount", {
                    isRead: false,
                }),
            );
            setUnreadCount(response.data.totalCount);
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchMessages = async (startDate?: Date, endDate?: Date, searchText?: string, skip?: number, take?: number, isRead?: boolean) => {
        try {
            setIsLoading(true);
            setError(undefined);
            let url = UrlHelper.generateUrlWithParams("Messages", {
                startDate,
                endDate,
                searchText,
                skip,
                take,
                isRead
            });

            const response = await axiosInstance.get<ListResponseDto<MessageDto>>(url);
            setMessages(response.data.items);
            setTotalCount(response.data.totalCount);
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const createMessage = async (message: CreateMessageDto) => {
        try {
            setError(undefined);
            setIsSubmitting(true);
            const response = await axiosInstance.post<CreateResponseDto<MessageDto>>("Messages", message);
            return response.data;
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }
    }

    const markIsRead = async (messageId: string) => {
        try {
            setError(undefined);
            setIsSubmitting(true)
            const response = 
                await axiosInstance.patch<CreateResponseDto<MessageReceiverDto>>(`Messages/MarkAsRead/${messageId}`, {}, {
                    validateStatus: (status) => status < 500
                });
            return response.data?.success === true;
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }

        return false;
    }

    const updateMessages = (newMessages: MessageDto[]) => {
        setMessages(newMessages);
    }

    return {
        fetchMessages,
        fetchUnreadMessagesCount,
        createMessage,
        messages,
        updateMessages,
        isLoading,
        error,
        totalCount,
        isSubmitting,
        hasMandatoryMessage,
        checkMandatoryNotification,
        markIsRead,
        setError,
        unreadCount
    }
};

export default useFetchMessages;
