
import {t} from "i18next";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageContainer from "../../components/PageContainer/PageContainer";
import DataTable from "../../components/DataTable/DataTable";
import useFetchDepartment from "../../hooks/useFetchDepartments";
import Loading from "../../components/Loading/Loading";
import {useCallback, useEffect, useState} from "react";
import AlertDanger from "../../components/Alerts/AlertDanger";
import PlusIcon from "../../assets/svg/buttonIcons/PlusIcon";
import MainButton from "../../components/buttons/MainButton";
import {EditDepartmentModal} from "./modals/EditDepartmentModal";
import TableEditIcon from "../../assets/svg/TableEditIcon";
import DeleteItemIcon from "../../assets/svg/DeleteItemIcon";
import {CreateDepartmentDto, DepartmentDto} from "../../dtos/departments/DepartmentDto";
import useFetchData from "../../hooks/useFetchData";
import QuestionModal from "../../components/QuestionModal/QuestionModal";
import SearchInput from "../../components/inputs/SearchInput.tsx";

const DepartmentPage = () => {
    const {
        items: departments,
        error,
        deleteItem,
        fetchItems,
        isLoading,
        totalCount,
    } = useFetchData<CreateDepartmentDto, DepartmentDto, DepartmentDto>("Departments")

    const [selectedDepartmentId, setSelectedDepartmentId] = useState<string | undefined>(undefined);
    const [showEmployeeDeleteQuestionModal, setShowEmployeeDeleteQuestionModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState<string>("");

    const columns: DataTableColumn[] = [
        {
            title: t("DepartmentName"),
            field: "description"
        },
        {
            title: t("HeadOfDepartment"),
            field: "headOfDepartmentFullName"
        },
        {
            title: t("Actions"),
            field: "actions",
            render: (value: any) => (
                <div className="flex gap-2">
                    <button onClick={() => handleEditDepartment(value)}><TableEditIcon/></button>
                    <button onClick={() => handleDeleteDepartment(value)}><DeleteItemIcon/></button>
                </div>
            )
        }
    ]

    const renderMobileRow = (columns: DataTableColumn[], item: any) => {
        return (
            <>
                <div className={"flex flex-row"}>
                    <div className={"flex justify-between w-full ps-4 pe-2 font-myriad text-xs gap-3 text-black-60"}>
                        <div>{t(item.description)}</div>
                        <div>{item.id}</div>
                        <div>
                            <div className={`flex gap-1 ml-2`}>
                                <button onClick={() => handleEditDepartment(item.id)}><TableEditIcon/></button>
                                <button onClick={() => handleDeleteDepartment(item.id)}><DeleteItemIcon/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const handleOnReload = (newPageSize, newPageNumber) => {
        setPageSize(newPageSize);
        setPageNumber(newPageNumber);
    };
    const handleCloseModal = async () => {
        setShowEditModal(false);
    }

    const handleDepartmentUpdate = async () => {
        await handleCloseModal();
        await fetchData();
    };

    const handleNewDepartment = () => {
        setSelectedDepartmentId(undefined);
        setShowEditModal(true);
    };

    const handleEditDepartment = (department: DepartmentDto) => {
        setSelectedDepartmentId(department.id);
        setShowEditModal(true);
    };

    const handleDeleteDepartment = async (department: DepartmentDto) => {
        setSelectedDepartmentId(department.id);
        setShowEmployeeDeleteQuestionModal(true);
    };

    const handleDeleteDepartmentConfirmed = async () => {
        setShowEmployeeDeleteQuestionModal(false);
        if (!selectedDepartmentId) return;

        await deleteItem(selectedDepartmentId);
        await fetchData();
    };

    const fetchData = useCallback(async () => {
        await fetchItems(searchText, (pageNumber - 1) * pageSize, pageSize);
    }, [pageSize, pageNumber, searchText]);

    useEffect(() => {
        fetchData();
    }, [pageSize, pageNumber, searchText])

    return (
        <PageContainer>
            <PageHeader title={t("Departments")}>
                <div className={"flex gap-2"}>
                    <SearchInput searchText={searchText} onSearchTextChanged={setSearchText}/>
                    <MainButton
                        disabled={isLoading}
                        title={t("NewDepartment")}
                        onClick={handleNewDepartment}
                        icon={<PlusIcon/>}
                    />
                </div>
            </PageHeader>
            {error && <AlertDanger errorMessages={[error]}/>}
            <Loading loading={isLoading}>
                <div className={"mt-2"}>
                    <DataTable
                        items={departments}
                        columns={columns}
                        pageSize={pageSize}
                        currentPage={pageNumber}
                        totalCount={totalCount}
                        onReload={handleOnReload}
                        renderMobileRow={renderMobileRow}
                    />
                </div>
            </Loading>
            {showEditModal && (
                <EditDepartmentModal
                    onClose={handleCloseModal}
                    onChanged={handleDepartmentUpdate}
                    departmentId={selectedDepartmentId}
                />
            )}

            {showEmployeeDeleteQuestionModal && (
                <QuestionModal onClose={() => setShowEmployeeDeleteQuestionModal(false)}
                               onYes={handleDeleteDepartmentConfirmed}
                               title={t("AreYouSureYouWantToDeleteThisDepartment")}
                               content={t("ThisActionCannotBeUndone.")}
                               icon={<DeleteItemIcon/>} type={"danger"}
                               yesButtonText={t("Yes")}
                               noButtonText={t("No")}/>
            )}
        </PageContainer>
    );
};

export default DepartmentPage;
