const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        d="M0.811904 14.4754C0.991808 15.8116 2.09856 16.8584 3.44538 16.9203C4.57868 16.9724 5.7299 16.9996 6.99766 16.9996C8.26542 16.9996 9.41664 16.9724 10.5499 16.9203C11.8968 16.8584 13.0035 15.8116 13.1834 14.4754C13.3008 13.6034 13.3977 12.7097 13.3977 11.7996C13.3977 10.8895 13.3008 9.99586 13.1834 9.12382C13.0035 7.78763 11.8968 6.7408 10.5499 6.67888C9.41664 6.62678 8.26542 6.59961 6.99766 6.59961C5.7299 6.59961 4.57868 6.62678 3.44538 6.67888C2.09856 6.7408 0.991808 7.78763 0.811904 9.12382C0.694491 9.99586 0.597655 10.8895 0.597655 11.7996C0.597655 12.7097 0.694492 13.6034 0.811904 14.4754Z"
        stroke="#667B8F"
        strokeWidth="1.2"
      />
      <path
        d="M3.39697 6.6V4.6C3.39697 2.61178 5.00875 1 6.99697 1C8.9852 1 10.597 2.61178 10.597 4.6V6.6"
        stroke="#667B8F"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99512 11.7998H7.0023"
        stroke="#667B8F"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
