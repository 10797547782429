import {t} from "i18next";
import {CreateDepartmentDto, DepartmentDto} from "../../../dtos/departments/DepartmentDto";
import useFetchData from "../../../hooks/useFetchData";
import Loading from "../../../components/Loading/Loading";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import AlertDanger from "../../../components/Alerts/AlertDanger";
import BaseModal from "../../../components/Modals/BaseModal";
import {FormProvider, useForm} from "react-hook-form";
import React, {useCallback, useEffect} from "react";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import {toast} from "react-hot-toast";
import FormTextInput from "../../../components/FormControls/FormTextInput";
import useFetchEmployees from "../../../hooks/useFetchEmployees.ts";
import {FormHelper} from "../../../utils/FormHelper.ts";
import FormSelectControl from "../../../components/FormControls/FormSelectControl.tsx";

type Props = {
    onClose: () => void;
    onChanged: () => void;
    departmentId?: string;
}

type EditDepartmentFormModel = {
    id?: string,
    description: string,
    headOfDepartment?: SelectControlOption<string>,
}

export const EditDepartmentModal: React.FC<Props> = ({
                                                         onClose,
                                                         departmentId,
                                                         onChanged,
                                                     }) => {
    const {
        createItem,
        updateItem,
        fetchItem,
        error: departmentsError,
        isLoading,
        isSubmitting
    } = useFetchData<CreateDepartmentDto, DepartmentDto, DepartmentDto>("Departments");

    const {
        isLoading: isLoadingEmployees,
        error: employeesError,
        fetchEmployees,
        employeesOptions
    } = useFetchEmployees();

    const isBusy = isLoading || isLoadingEmployees || isSubmitting;


    const handleAddOrUpdateDepartment = useCallback(async (data: EditDepartmentFormModel) => {
        if (!departmentId) {
            const newDepartment: CreateDepartmentDto = {
                description: data.description,
                headOfDepartmentId: data.headOfDepartment?.value,
            };
            await createItem(newDepartment);
        } else {
            const updatedDepartment: DepartmentDto = {
                id: departmentId,
                description: data.description,
                headOfDepartmentId: data.headOfDepartment?.value,
            };
            await updateItem(departmentId, updatedDepartment);
        }

        onChanged();
        toast.success(t("DepartmentSavedSuccessfully"));
    }, [departmentId, onChanged]);

    const hookForm = useForm<EditDepartmentFormModel>();
    const {reset, handleSubmit} = hookForm;

    const fetchData = useCallback(async () => {
        const employees = await fetchEmployees(undefined, undefined, undefined, "Supervisor");
        if (departmentId) {
            const department = await fetchItem(departmentId);
            if (department) {
                reset({
                    id: department.id,
                    description: department.description,
                    headOfDepartment: FormHelper.getSelectedOption<string>(department, employees, "headOfDepartmentId", "fullName"),
                });
            }
        }
    }, [departmentId]);

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={!departmentId ? (t("AddDepartment")) : (t("EditDepartment"))}
                                     onClose={onClose}/>
                    <AlertDanger errorMessages={[departmentsError, employeesError]}/>
                    <Loading
                        loading={isBusy}
                        type={"cover"}
                    >
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="description"
                                type="text"
                                label={t("Name")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl options={employeesOptions} 
                                               fieldName={"headOfDepartment"}
                                               label={t("HeadOfDepartment")}
                            />
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Save")}
                    showCloseButton={false}
                    onSave={handleSubmit(handleAddOrUpdateDepartment)}
                    saveButtonDisabled={isBusy}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    )
}
