import React, {FC, PropsWithChildren, useEffect} from 'react';
import SelectControl from "../SelectControl/SelectControl.tsx";
import {Controller, useFormContext} from "react-hook-form";
import {FormControlBaseProps} from "../../types/Forms/FormControlBaseProps.ts";
import {useTranslation} from "react-i18next";

type Props = {
    options: SelectControlOption<any>[];
    isRequired?: boolean;
    multiSelect?: boolean;
    clearable?: boolean;
    isDisabled?: boolean
} & FormControlBaseProps;

const FormSelectControl: FC<PropsWithChildren<Props>> = ({
                                                             fieldName,
                                                             label,
                                                             options,
                                                             isRequired,
                                                             multiSelect,
                                                             clearable,
                                                             isDisabled,
                                                         }) => {

    const {control, formState: {errors}} = useFormContext();
    const {t} = useTranslation();


    return (
        <>
            <Controller rules={{required: isRequired}} render={(f) => {
                const handleMultiValueSelect = (e: SelectControlOption<string>[]) => {
                    f.field.onChange(e);
                };

                return <SelectControl
                    options={options}
                    label={label}
                    clearable={clearable}
                    onSingleValueSelect={f.field.onChange}
                    onMultiValueSelect={(e) => handleMultiValueSelect(e)}
                    isMulti={multiSelect}
                    values={f.field.value}
                    isDisabled={isDisabled}
                />

            }} name={fieldName} control={control} defaultValue={null}/>
            {errors[fieldName] && <span className={"text-red-500 text-xs"}>This field is required</span>}
        </>
    );
};

export default FormSelectControl;