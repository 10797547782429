import React from "react";
import ReactDOM from "react-dom/client";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Provider} from "react-redux";
import {store} from "./redux/store.tsx";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import MainRouter from "./router/MainRouter.tsx";
import {Toaster} from "react-hot-toast";
import {languages} from "./i18n/languages.ts";
import {initReactI18next} from "react-i18next";
import { register } from './serviceWorker';
import i18n from "i18next";
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient();


i18n.use(initReactI18next).init({
    resources: languages,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

Sentry.init({
    dsn: "https://27260dad02f982cf7f120d7a0052cf98@o1357990.ingest.us.sentry.io/4507443679657984",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/dreamland-tracking.everridge\.co\.uk\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <Toaster/>
                    <MainRouter/>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>,
);

register();