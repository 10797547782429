import React, {FC, PropsWithChildren, ReactNode} from 'react';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import OrdersIcon from '../../../assets/svg/sidebarIcons/OrdersIcon';
import MainButton from '../../../components/buttons/MainButton';

type Props = {
    isActive: boolean;
    title: string;
    onClick: () => void;
    icon?: ReactNode;
}

const TaskTypeButton: FC<PropsWithChildren<Props>> = ({
                                                          onClick,
                                                          isActive,
                                                          title,
                                                          icon
                                                      }) => {
    return (
        <>
            {isActive ? (
                
            <MainButton
                title={title}
                icon={icon}
                onClick={onClick}
                extraClassName="stroke-white"
            />
            ) : (
                <SecondaryButton
                title={title}
                onClick={onClick}
                icon={icon}
                extraClassName="active-svg"
            />
            )}
        </>
    );
};

export default TaskTypeButton;