function generateUrlWithParams(
    uri: string = "",
    params: { [key: string]: string | number | boolean | Date | null | undefined | (string | number | boolean | Date)[] }
): string {
    let urlWithParams: string = uri;
    const queryParams: string[] = [];

    Object.entries(params).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
                value.forEach(item => {
                    if (item !== null && item !== undefined) {
                        queryParams.push(`${key}=${encodeURIComponent(item.toString())}`);
                    }
                });
            } else if (value instanceof Date) {
                queryParams.push(`${key}=${encodeURIComponent(value.toISOString())}`);
            } else {
                queryParams.push(`${key}=${encodeURIComponent(value.toString())}`);
            }
        }
    });

    if (queryParams.length > 0) {
        urlWithParams += `?${queryParams.join("&")}`;
    }

    return urlWithParams;
}


// function generateUrlWithParams(
//     uri: string = "",
//     params: { [key: string]: string | number | boolean | Date | null | undefined }
// ): string {
//     let urlWithParams: string = uri;
//     const queryParams: string = Object.entries(params)
//         .filter(([_, value]): boolean => value !== null && value !== undefined)
//         .map(([key, value]): string => {
//             if (value instanceof Date) {
//                 return `${key}=${encodeURIComponent(value.toISOString())}`;
//             } else {
//                 // By this point, TypeScript knows that value is not null or undefined.
//                 return `${key}=${encodeURIComponent(value!.toString())}`;
//             }
//         })
//         .join("&");
//
//     if (queryParams) {
//         urlWithParams += `?${queryParams}`;
//     }
//
//     return urlWithParams;
// }
//


// function generateUrlWithParams(
//     uri = "",
//     params: {[key: string]: string | number | boolean | null | undefined},
// ): string {
//     let urlWithParams = uri;
//     const queryParams = Object.entries(params)
//         .filter(([_, value]) => value !== null && value !== undefined)
//         .map(
//             ([key, value]) =>
//                 `${key}=${encodeURIComponent(
//                     (value as string | number | boolean).toString(),
//                 )}`,
//         )
//         .join("&");
//
//     if (queryParams) {
//         urlWithParams += `?${queryParams}`;
//     }
//
//     return urlWithParams;
// }

export const UrlHelper = {
    generateUrlWithParams,
};
