import React, {FC, PropsWithChildren} from 'react';

type Props = {}

const PageContainer: FC<PropsWithChildren<Props>> = ({children}) => {
    return (
        <div className="mt-2 mx-4 my-6 md:mx-0 md:my-0">
            {children}
        </div>
    );
};

export default PageContainer;