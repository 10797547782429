import React, {FC, PropsWithChildren, useEffect} from 'react';
import BaseModal from "../../../components/Modals/BaseModal.tsx";
import BaseModalContent from "../../../components/Modals/BaseModalContent.tsx";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader.tsx";
import {useTranslation} from "react-i18next";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons.tsx";
import CancelButton from "../../../components/buttons/CancelButton.tsx";
import useFetchCalendar from "../../../hooks/useFetchCalendar.ts";
import {EventTypeEnum} from "../../../enums/EventTypeEnum.ts";
import Loading from "../../../components/Loading/Loading.tsx";
import AlertDanger from "../../../components/Alerts/AlertDanger.tsx";
import {format} from "date-fns";
import toast from "react-hot-toast";

type Props = {
    onClose: () => void;
    eventId: string;
    eventType: EventTypeEnum;
}

const CalendarEventModal: FC<PropsWithChildren<Props>> = ({onClose, eventId, eventType}) => {
    const {t} = useTranslation();
    const {fetchCalendarEvent, isLoading, error, calendarEvent, cancelReservation, isSubmitting} = useFetchCalendar();

    useEffect(() => {
        fetchCalendarEvent(eventId, eventType);
    }, [eventId, eventType]);

    const handleCancelEvent = async () => {
        const success = await cancelReservation(eventId);
        if (success) {
            toast.success(t("Event cancelled successfully"));
            onClose();
        }
    };

    const eventTypeName = (eventType?: EventTypeEnum) => {
        switch (eventType) {
            case EventTypeEnum.Reservation:
                return t("Reservation");
            case EventTypeEnum.Accommodation:
                return t("Accommodation");
            case EventTypeEnum.InHouse:
                return t("InHouse");
            default:
                return "";
        }
    }

    return (
        <>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={t("EventDetails")} onClose={onClose}/>
                    {error && <AlertDanger errorMessages={[error]}/>}
                    <Loading loading={isLoading} type={"cover"}>
                        <div className={"font-myriad text-sm border-t-[1px]"}>
                            <div className={"grid grid-cols-2 py-2 border-b-[1px] mb-2 gap-4"}>
                                <div className={"text-black"}>{t("EventType")}</div>
                                <div className={"text-end"}>{t(eventTypeName(calendarEvent?.type))}</div>
                                <div className={"text-black"}>{t("CheckIn")}</div>
                                <div
                                    className={"text-end"}>{calendarEvent?.startDate && format(calendarEvent?.startDate, "dd/MM/yyyy")}</div>
                                <div className={"text-black"}>{t("CheckOut")}</div>
                                <div
                                    className={"text-end"}>{calendarEvent?.endDate && format(calendarEvent?.endDate, "dd/MM/yyyy")}</div>
                                <div className={"text-black"}>{t("GuestFullName")}</div>
                                <div className={"text-end"}>{calendarEvent?.guestFullName}</div>
                            </div>
                            <div className={"grid grid-cols-2 py-2 border-b-[1px] mb-2 gap-4"}>
                                <div className={"text-black"}>{t("RoomNumber")}</div>
                                <div className={"text-end"}>{calendarEvent?.roomNo}</div>
                                <div className={"text-black"}>{t("Price")}</div>
                                <div className={"text-end"}>{calendarEvent?.amount.toFixed(2)}</div>
                            </div>
                            <div className={"border-b-[1px] mb-2 py-2"}>
                                <div className={"text-black"}>{t("Remarks")}</div>
                                <div className={"text-black-60 mt-2"}>
                                    {calendarEvent?.remarks ?? t("-")}
                                </div>
                            </div>
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons onClose={onClose} showSaveButton={false}>
                    {eventType === EventTypeEnum.Reservation && <CancelButton title={t("Cancel this event")}
                                                                              onClick={handleCancelEvent}
                                                                              inProgress={isSubmitting}
                                                                              disabled={isSubmitting}
                                                                              extraClassName={"w-full sm:w-auto"}
                    />}
                </BaseModalButtons>
            </BaseModal>
        </>
    );
};

export default CalendarEventModal;