import { useState } from "react";
import CheckedIcon from "../../assets/svg/LoginIcons/CheckedIcon";

type Props = {
  label: string;
  onChange?: (checked: boolean) => void;
};

const CheckBox = ({ label, onChange, ...props }: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    if (onChange) {
      onChange(!isChecked);
    }
  };

  return (
    <label
      className="flex cursor-pointer items-center"
      onClick={toggleCheckbox}
    >
      <div
        className={`flex h-[15px] w-[15px] items-center justify-center rounded-[5px] border-2 border-black-40 ${isChecked ? "border-none bg-dark-green" : "bg-black-secondary-strong"} `}
        {...props}
      >
        {isChecked && <CheckedIcon />}
      </div>
      <div className="ms-2 font-myriad text-sm font-regular text-black-40">
        {label}
      </div>
    </label>
  );
};

export default CheckBox;
