import {FC, forwardRef, PropsWithChildren, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import useFetchCalendarDates from "../../hooks/useFetchCalendar.ts";
import {endOfMonth, format, startOfMonth, startOfYear} from "date-fns";
import {EventTypeEnum} from "../../enums/EventTypeEnum.ts";
import DataTable from "../../components/DataTable/DataTable.tsx";
import {useTranslation} from "react-i18next";
import {CalendarEventDto} from "../../dtos/calendar/CalendarEventDto.ts";
import {CalendarEventFilter} from "./types.ts";
import {useAppDispatch, useAppSelector} from "../../redux/hooks/hooks.ts";
import {selectCalendarFilter} from "../../redux/slices/appSlice.ts";

type Props = {
    currentMonth: Date;
    onDoubleClickEvent: (event: CalendarEventDto) => void;
    onChangeMonth: (newMonth: Date) => void;
}

export interface CalendarEventsRef {
    fetchData: () => Promise<void>;
}

const CalendarEvents = forwardRef<CalendarEventsRef, Props>((props, ref) => {
    const {t} = useTranslation();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const {currentMonth, onDoubleClickEvent, onChangeMonth} = props;

    const filter = useAppSelector(selectCalendarFilter);
    const dispatch = useAppDispatch();

    const {
        calendarEvents, fetchCalendarEvents, totalCount, isLoading
    } = useFetchCalendarDates();

    const fetchData = useCallback(async () => {
        const apartmentsIds = filter.apartments.map(a => a.value);
        await fetchCalendarEvents(startOfMonth(currentMonth), endOfMonth(currentMonth), undefined, (currentPage - 1) * pageSize, pageSize, apartmentsIds, filter.allEvents);
    }, [currentMonth, currentPage, pageSize, filter]);

    useEffect(() => {
        fetchData();
    }, [currentMonth, currentPage, pageSize, filter]);
    
    // Binding fetchData to the ref
    useImperativeHandle(ref, () => ({
        fetchData
    }));

    const renderEventName = (event: CalendarEventDto) => {
        switch (event.type) {
            case EventTypeEnum.Reservation:
                return "Reservation";
            case EventTypeEnum.Accommodation:
                return "Accommodation";
            case EventTypeEnum.InHouse:
                return "House Use";
            default:
                return "Unknown";
        }
    }

    const renderEventStatus = (event: CalendarEventDto) => {
        if (!event.statusName) return null;

        let color = "text-black-30";
        if (event.statusName === "Cancelled") {
            color = "text-red-primary";
        }
        
        return (
            <span className={`font-myriad text-xs ${color}`}>({event.statusName})</span>
        )
    };

    const columns: DataTableColumn[] = [
        {
            title: "Event",
            field: "event",
            render: (event: CalendarEventDto) => {
                return (
                    <div className={"flex justify-between"}>
                        <div>
                            <div>{`${renderEventName(event)} `}{renderEventStatus({
                                ...event,
                                statusName: "Cancelled"
                            })}</div>
                            <div className="flex flex-row items-center gap-1">
                                <div>{format(event.startDate, "dd/MM/yyyy")}</div>
                                <div>to</div>
                                <div>{format(event.endDate, "dd/MM/yyyy")}</div>
                            </div>
                        </div>
                        <div>
                            <div>{`${t("Room")} ${event.roomNo}`}</div>
                            <div>${event.amount?.toFixed(2)}</div>
                        </div>
                    </div>
                )
            },
        }
    ];

    const renderMobileRow = (columns: DataTableColumn[], event: any) => {
        return (
            <div
                className="flex flex-row items-center justify-between border-b-[1px] border-black-10 px-6 py-4 font-myriad text-sm font-regular text-black-60 md:h-[72px]"
                key={event.id}
            >
                <div>
                    <div>{`${renderEventName(event)} ${renderEventStatus(event)}`}</div>
                    <div className="flex flex-row items-center gap-1">
                        <div>{format(event.startDate, "dd/MM/yyyy")}</div>
                        <div>to</div>
                        <div>{format(event.endDate, "dd/MM/yyyy")}</div>
                    </div>
                </div>
                <div>${event.amount?.toFixed(2)}</div>
            </div>
        )
    };

    useEffect(() => {
        fetchData();
    }, [currentMonth, pageSize, currentPage]);

    const handleReload = (newPageSize: number, newPageNumber: number) => {
        setPageSize(newPageSize);
        setCurrentPage(newPageNumber);
    };

    return (
        <>
            <DataTable columns={columns}
                       pageSize={pageSize}
                       currentPage={currentPage}
                       totalCount={totalCount}
                       items={calendarEvents}
                       onDoubleClick={(item: CalendarEventDto) => onDoubleClickEvent(item)}
                       onReload={(newPageSize, newPageNumber) => handleReload(newPageSize, newPageNumber)}
                       renderMobileRow={(columns, item) => renderMobileRow(columns, item)}/>
        </>
    );
});

export default CalendarEvents;