import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IApartmentModalState {
  isModalOpen: boolean;
}

const initialState: IApartmentModalState = {
  isModalOpen: false,
};

export const apartmentModalSlice = createSlice({
  name: "ApartmentModal",
  initialState,
  reducers: {
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
  },
});

export const { setIsModalOpen } = apartmentModalSlice.actions;

export const apartmentModalReducer = apartmentModalSlice.reducer;
