const redPrimary = "#FF5A4F";
const greenPrimary = "#00C853";
const mainColor = "#00A9B5";
const whiteColor = "#FFFFFF";
const disabledColor = "#BDBDBD";
const orangePrimary = "#FFB31F";
const orangeSecondary = "#FFD54F";
const blackColor = "#000000";

export const ThemeColors = {
    redPrimary,
    greenPrimary,
    mainColor,
    whiteColor,
    disabledColor,
    orangePrimary,
    orangeSecondary,
    blackColor
}