import {useCallback, useEffect, useState} from "react";
import useFetchMessages from "../../../hooks/useFetchMessages";
import MainButton from "../../buttons/MainButton.tsx";
import BaseModal from "../../Modals/BaseModal.tsx";
import BaseModalContent from "../../Modals/BaseModalContent.tsx";
import BaseModalButtons from "../../Modals/BaseModalButtons.tsx";
import BaseModalHeader from "../../Modals/BaseModalHeader.tsx";
import {useTranslation} from "react-i18next";
import Loading from "../../Loading/Loading.tsx";
import AlertDanger from "../../Alerts/AlertDanger.tsx";
import toast from "react-hot-toast";

type Props = {
    onClose: () => void;
};

const MandatoryNotification = ({onClose}: Props) => {
    const {t} = useTranslation();
    const {setError, markIsRead, fetchMessages, messages, error, isLoading} = useFetchMessages();
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const filteredMessages = messages?.filter(
        (message) => message.isMandatory,
    );
    const currentMessage = filteredMessages[currentMessageIndex];

    const fetchData = async () => {
        await fetchMessages(undefined, undefined, undefined, undefined, undefined, true);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleIUnderstandClick = useCallback(async () => {
        try {
            await markIsRead(filteredMessages[currentMessageIndex].id);
        } catch (e: any) {
            setError("Error marking message as read");
            console.log(e);
            return;
        }

        if (filteredMessages) {
            if (currentMessageIndex < filteredMessages.length - 1) {
                setCurrentMessageIndex(currentMessageIndex + 1);
            } else {
                onClose();
            }
        }
    }, [filteredMessages, markIsRead, error]);

    return (
        <BaseModal>
            <BaseModalContent>
                <BaseModalHeader title={t("MandatoryMessage")}></BaseModalHeader>
                {error && <AlertDanger errorMessages={[`Error loading messages: ${error}`]} onReloadClick={fetchData}/>}
                <Loading type={"cover"} loading={isLoading}>
                    <div className="text-sm text-black-60">
                        {currentMessage?.messageContent}
                    </div>
                </Loading>
            </BaseModalContent>
            <BaseModalButtons showSaveButton={false} showCloseButton={!!error || !messages || messages.length === 0}
                              onClose={onClose}>
                {!error && messages && messages.length > 0 &&
                    <div className={"w-full flex align-middle justify-center"}>
                        <MainButton disabled={isLoading}
                                    title={t("IUnderstand")}
                                    onClick={handleIUnderstandClick}
                        />
                    </div>}
            </BaseModalButtons>
        </BaseModal>
    )
        ;
};

export default MandatoryNotification;
