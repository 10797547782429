import { useTranslation } from "react-i18next";
import { DashboardFilter } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import BaseModal from "../../../components/Modals/BaseModal";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import Loading from "../../../components/Loading/Loading";
import FormDateInput from "../../../components/FormControls/FormDateInput";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import FilterApartmentsIcon from "../../../assets/svg/apartmentIcons/FilterApartmentsIcon";
import { ThemeColors } from "../../../theme/colors";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import ClearFilterIcon from "../../../assets/svg/ClearFilterIcon";

type Props = {
    onClose: () => void,
    onApplyFilter?: (newFilter: DashboardFilter) => void,
    filter?: DashboardFilter
}

const PeriodFilterModal: React.FC<Props> = ({
                                                             onClose,
                                                             onApplyFilter,
                                                             filter,
                                                         }) => {
    const {t} = useTranslation();
    const hookForms = useForm<DashboardFilter>({
        defaultValues: filter,
    });
    const {setValue, watch, reset, handleSubmit} = hookForms;

    const onClear = () => {
        reset();
        onApplyFilter && onApplyFilter({
            periodType: "Monthly"
        });
        onClose();
    };

    const handleApplyFilter = (newFilter: DashboardFilter) => {
        if (onApplyFilter) {
            onApplyFilter(newFilter);
            onClose();
        }
    };

    useEffect(() => {
        const dateFrom = watch("dateFrom");
        const dateTo = watch("dateTo");
        if (dateFrom && dateTo) {
            if (dateFrom > dateTo) {
                setValue("dateTo", dateFrom);
            }
        }
    }, [watch("dateFrom"), watch("dateTo")]);

    return (
        <FormProvider {...hookForms}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={t("Filter")} onClose={onClose}/>
                        <div className="grid grid-cols-2 gap-3">
                            <div>
                                <FormDateInput fieldName={"dateFrom"} label={t("DateFrom")}/>
                            </div>
                            <div>
                                <FormDateInput fieldName={"dateTo"} label={t("DateTo")}/>
                            </div>
                        </div>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Apply")}
                    onSave={handleSubmit(handleApplyFilter)}
                    showCloseButton={false}
                    saveButtonIcon={
                        <FilterApartmentsIcon
                            disabled={false}
                            strokeColor={ThemeColors.whiteColor}
                            color={ThemeColors.mainColor}
                        />
                    }>
                    <SecondaryButton
                        title={t("Clear")}
                        onClick={onClear}
                        icon={<ClearFilterIcon strokeColor={"black"} color={"#00A9B5"}/>}
                    />
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    );
};


export default PeriodFilterModal;