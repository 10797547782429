import {useTranslation} from "react-i18next";
import CloseIcon from "../../../assets/svg/CloseIcon";
import BasicInput from "../../inputs/BasicInput";
import MainButton from "../../buttons/MainButton.tsx";
import view from "../../../assets/svg/LoginIcons/View.svg";
import viewOn from "../../../assets/svg/LoginIcons/State=On.svg";
import {useState} from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import BaseModal from "../BaseModal.tsx";
import BaseModalContent from "../BaseModalContent.tsx";
import BaseModalHeader from "../BaseModalHeader.tsx";
import BaseModalButtons from "../BaseModalButtons.tsx";
import {FormProvider, useForm} from "react-hook-form";
import FormTextInput from "../../FormControls/FormTextInput.tsx";
import AlertDanger from "../../Alerts/AlertDanger.tsx";
import {axiosInstance} from "../../../config/appConfig.ts";
import toast from "react-hot-toast";

type Props = {
    show: boolean;
    onClose: () => void;
};

type ChangePasswordFormModel = {
    oldPassword: string;
    newPassword: string;
}

const ChangePasswordModal = ({onClose}: Props) => {
    const {t} = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const hookForm = useForm<ChangePasswordFormModel>();
    const {handleSubmit} = hookForm;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const showPasswordToggler = () => {
        setShowPassword(!showPassword);
    };

    const handleSave = () => {
        // onClose(true, newPassword);
    };

    const handleChangePassword = async (data: ChangePasswordFormModel) => {
        try {
            setIsSubmitting(true);
            setError(undefined);
            const response = await axiosInstance.post("identity/UpdatePassword", data)
            if (response.status === 200 && response.data?.success) {
                toast.success(t("PasswordChanged"));
                onClose();
                return;
            }
            toast.error(response.data?.errorMessage ?? t("PasswordChangeFailed"));
        } catch (e: any) {
            setError(e.message);
            toast.error(t("PasswordChangeFailed"));
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={t("ChangePassword")} onClose={onClose}/>
                    {error && <AlertDanger errorMessages={[error]}/>}
                    <div>
                        <FormTextInput fieldName={"oldPassword"} label={t("OldPassword")} type={"password"}
                                       extraCssClass={"w-full"} isRequired={true}/>
                    </div>
                    <div>
                        <FormTextInput fieldName={"newPassword"} label={t("NewPassword")} type={"password"}
                                       extraCssClass={"w-full"} isRequired={true}/>
                    </div>
                </BaseModalContent>
                <BaseModalButtons onSave={handleSubmit(handleChangePassword)}
                                  inProgress={isSubmitting}
                                  saveButtonText={t("ChangePassword")}
                                  onClose={onClose}></BaseModalButtons>
            </BaseModal>
        </FormProvider>
    );
};

export default ChangePasswordModal;


// <BasicInput
//     label="Current password"
//     placeholder="password"
//     width={isMobile ? "100%" : 280}
//     height={44}
//     type={showPassword ? "password" : "text"}
//     icon={
//         <img
//             src={showPassword ? viewOn : view}
//             alt="view"
//             onClick={showPasswordToggler}
//             className="cursor-pointer"
//         />
//     }
//     value={currentPassword}
//     onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
//         setCurrentPasssword(e.target.value)
//     }
// />
// <BasicInput
//     label="New password"
//     placeholder="password"
//     width={isMobile ? "100%" : 280}
//     height={44}
//     type={showPassword ? "password" : "text"}
//     icon={
//         <img
//             src={showPassword ? viewOn : view}
//             alt="view"
//             onClick={showPasswordToggler}
//             className="cursor-pointer"
//         />
//     }
//     value={newPassword}
//     onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
//         setNewPassword(e.target.value)
//     }
// />