import {FolioTypeEnum} from "../enums/FolioTypeEnum.ts";
import {EmployeeTaskStatusEnum} from "../enums/EmployeeTaskStatusEnum.ts";
import NewTaskIcon from "../assets/svg/tasksIcons/NewTaskIcon.tsx";
import InProgressTaskIcon from "../assets/svg/tasksIcons/InProgressIcon.tsx";
import AssignedTaskIcon from "../assets/svg/tasksIcons/AssignedTaskIcon.tsx";
import InspectedTaskIcon from "../assets/svg/tasksIcons/InspectedTaskIcon.tsx";
import DoneTaskIcon from "../assets/svg/tasksIcons/DoneTaskIcon.tsx";
import {LuCheck, LuCheckCheck} from "react-icons/lu";
import {ThemeColors} from "../theme/colors.ts";

const getFolioTypeKey = (type?: FolioTypeEnum): string => {
    switch (type) {
        case FolioTypeEnum.Property:
            return "Property";
        case FolioTypeEnum.Bracelets:
            return "Bracelets";
        case FolioTypeEnum.OtherServices:
            return "OtherServices";
        default:
            return "Unknown";
    }
}

const getEmployeeTaskStatusKey = (status?: EmployeeTaskStatusEnum): string => {
    switch (status) {
        case EmployeeTaskStatusEnum.New:
            return "New";
        case EmployeeTaskStatusEnum.OnGoing:
            return "OnGoing";
        case EmployeeTaskStatusEnum.Assigned:
            return "Assigned";
        case EmployeeTaskStatusEnum.Closed:
            return "Closed";
        case EmployeeTaskStatusEnum.Inspected:
            return "Inspected";
        default:
            return "Unknown";
    }
}

const getEmployeeTaskStatusIcon = (status?: EmployeeTaskStatusEnum) => {
    switch (status) {
        case EmployeeTaskStatusEnum.New:
            return <NewTaskIcon color={ThemeColors.mainColor}/>;
        case EmployeeTaskStatusEnum.OnGoing:
            return <div className={"animate-pulse"}><InProgressTaskIcon color={ThemeColors.mainColor}/></div>;
        case EmployeeTaskStatusEnum.Assigned:
            return <AssignedTaskIcon color={ThemeColors.mainColor}/>;
        case EmployeeTaskStatusEnum.Closed:
            return <LuCheck color={ThemeColors.mainColor} size={20}/>;
        case EmployeeTaskStatusEnum.Inspected:
            return <LuCheckCheck color={ThemeColors.greenPrimary} size={20}/>
        default:
            return "Unknown";
    }
}

const getEmployeeTaskStatusOptions = (): SelectControlOption<EmployeeTaskStatusEnum>[] => {
    return [
        {value: EmployeeTaskStatusEnum.New, label: getEmployeeTaskStatusKey(EmployeeTaskStatusEnum.New)},
        {value: EmployeeTaskStatusEnum.OnGoing, label: getEmployeeTaskStatusKey(EmployeeTaskStatusEnum.OnGoing)},
        {value: EmployeeTaskStatusEnum.Assigned, label: getEmployeeTaskStatusKey(EmployeeTaskStatusEnum.Assigned)},
        {value: EmployeeTaskStatusEnum.Closed, label: getEmployeeTaskStatusKey(EmployeeTaskStatusEnum.Closed)},
        {value: EmployeeTaskStatusEnum.Inspected, label: getEmployeeTaskStatusKey(EmployeeTaskStatusEnum.Inspected)},
    ];
}

const getNextStatus = (status?: EmployeeTaskStatusEnum): EmployeeTaskStatusEnum | undefined => {
    switch (status) {
        case EmployeeTaskStatusEnum.New:
            return EmployeeTaskStatusEnum.Assigned;
        case EmployeeTaskStatusEnum.Assigned:
            return EmployeeTaskStatusEnum.OnGoing;
        case EmployeeTaskStatusEnum.OnGoing:
            return EmployeeTaskStatusEnum.Closed;
        case EmployeeTaskStatusEnum.Closed:
            return EmployeeTaskStatusEnum.Inspected;
        default:
            return undefined;
    }
}

const getNextStatusKey = (status?: EmployeeTaskStatusEnum): string | undefined => {
    const nextStatus = getNextStatus(status);
    return getEmployeeTaskStatusKey(nextStatus);
}

export const EnumHelper = {
    getFolioTypeKey,
    getEmployeeTaskStatusKey,
    getEmployeeTaskStatusOptions,
    getEmployeeTaskStatusIcon,
    getNextStatus,
    getNextStatusKey
}