import React, {FC, PropsWithChildren, useCallback, useEffect} from 'react';
import Indicator from "../../../components/Indicator/Indicator.tsx";
import {useAppSelector} from "../../../redux/hooks/hooks.ts";
import {useTranslation} from "react-i18next";
import useFetchDashboard from "../../../hooks/useFetchDashboard.ts";
import {DashboardHelper} from "../../../utils/DashboardHelper.ts";
import DashboardCardDifferenceIcon from "../../../components/Dashboard/DashboardCardDifferenceIcon.tsx";
import DashboardCard from "../../../components/Dashboard/DashboardCard.tsx";
import DashboardCardPosition from "../../../types/DashboardCardPosition.ts";
import {selectTasksRefreshIndex} from "../../../redux/slices/tasksSlice.ts";
import {selectDashboardFilter} from "../../../redux/slices/appSlice.ts";

type Props = {
    cardType?: DashboardCardPosition,
    cssClass?: string
}

const DashboardCardAllTasks: FC<PropsWithChildren<Props>> = ({
                                                                 cssClass,
                                                                 cardType = "middle"
                                                             }) => {
    const {t} = useTranslation();
    const tasksRefreshIndex = useAppSelector(selectTasksRefreshIndex);

    const filter = useAppSelector(selectDashboardFilter);

    const {loadDashboardAllTasks, allTasks} =
        useFetchDashboard({});

    const fetchData = useCallback(async () => {
        await loadDashboardAllTasks(filter);
    }, [tasksRefreshIndex, filter]);

    useEffect(() => {
        fetchData()
    }, [tasksRefreshIndex, filter]);


    return (
        <>
            <DashboardCard
                cssClass={cssClass}
                title={t("AllTasks")}
                mainInfo={`${allTasks?.taskCount}`}
                icon={
                    <></>
                    // <Indicator
                    //     backgroundColor={DashboardHelper.renderBackgroundColor(allTasks?.difference)}
                    //     text={allTasks?.difference?.toFixed(2) ?? "0.00" + "%"}
                    //     textColor={DashboardHelper.renderTextColor(allTasks?.difference ?? 0)}
                    //     icon={<DashboardCardDifferenceIcon difference={allTasks?.difference}/>}
                    // />
                }
            />
        </>
    );
};

export default DashboardCardAllTasks;