import { t } from 'i18next';
import React, {FC, PropsWithChildren} from 'react';

type Props = {}

const NotFound: FC<PropsWithChildren<Props>> = ({}) => {
    return (
        <>
            {t("PageNotFound")}
        </>
    );
};

export default NotFound;