import React, {FC, PropsWithChildren} from 'react';
import {ThemeText} from "../../theme/text.ts";
import FilterByDateDropdown from "../DropDowns/FilterByDateDropdown/FilterByDateDropdown.tsx";
import LeftArrow from "../../assets/svg/CalendarIcons/LeftArrow.tsx";
import PrevIcon from "../../assets/svg/PrevIcon.tsx";
import {useNavigate} from "react-router-dom";

type Props = {
    title?: string;
    showBackButton?: boolean;
}

const PageHeader: FC<PropsWithChildren<Props>> = ({children, title, showBackButton}) => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };
    
    return (
        <>
            <div className={"flex justify-between pt-3 sm:pt-0 pb-3 px-4 sm:px-0"}>
                <div className={"flex"}>
                    {showBackButton && <div onClick={handleGoBack}
                        className={"flex justify-center items-center text-dark-green bg-white hover:bg-dark-green-10 border-[1px] rounded-md w-8 h-8 me-2 cursor-pointer "}>
                        <PrevIcon/>
                    </div>}
                    <div className={ThemeText.pageTitleClass}>
                        {title}
                    </div>
                </div>
                {children}
            </div>

        </>
    );
};

export default PageHeader;