import React, {FC, PropsWithChildren} from 'react';
import {format} from "date-fns";
import {FormControlBaseProps} from "../../types/Forms/FormControlBaseProps.ts";
import {Controller, useFormContext} from "react-hook-form";

type Props = {} & FormControlBaseProps

const FormDateInput: FC<PropsWithChildren<Props>> = ({label, fieldName, isRequired, placeholder}) => {
    const {control, formState: {errors}} = useFormContext();
    return (
        <>
            {label && <label className={"font-myriad font-regular text-xs text-black-primary"}>{label}</label>}
            <Controller render={({field: {value, onChange}}) => {
                const handleDateChange = (date?: string) => {
                    if (!date) {
                        onChange(undefined);
                        return;
                    }
                    onChange(new Date(date));
                }
                
                return (
                    <input placeholder={placeholder}
                           value={value ? format(value, "yyyy-MM-dd HH:mm") : ""}
                           onChange={(e) => handleDateChange(e.target.value)}
                           className={`w-full flex h-[36px] font- mt-2 text-xs font-regular items-center 
                           justify-center border-[1px] border-black-10 rounded-lg md:rounded-lg text-black 
                           dark:[color-scheme:light] dark:text-black`}
                           type={"datetime-local"}
                    />
                )
            }} name={fieldName} control={control} rules={{required: isRequired}}/>
            {errors[fieldName] && <span className={"text-red-500 text-xs"}>This field is required</span>}
            {/*<input {...register(fieldName, {required: isRequired})} placeholder={placeholder}*/}
            {/*       className={`w-full flex h-[36px] font-myriad text-xs font-regular items-center justify-center border-[1px] border-black-10 rounded-lg md:rounded-lg`}*/}
            {/*       type={"date"}*/}
            {/*       value={value ? format(value, "yyyy-MM-dd") : ""}*/}
            {/*       onChange={(e) => handleDateChange(e.target.value)}*/}
            {/*/>*/}
        </>
    );
};

export default FormDateInput;