import useMediaQuery from "../../../hooks/useMediaQuery";

const PlusIcon = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${isMobile ? "9.6" : "14"}`}
      height={`${isMobile ? "9.6" : "14"}`}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 1V12.9999"
        stroke="#FCFDFD"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7H12.9999"
        stroke="#FCFDFD"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
