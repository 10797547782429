import {t} from "i18next";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageContainer from "../../components/PageContainer/PageContainer";
import DataTable from "../../components/DataTable/DataTable";
import Loading from "../../components/Loading/Loading";
import {useCallback, useEffect, useState} from "react";
import AlertDanger from "../../components/Alerts/AlertDanger";
import PlusIcon from "../../assets/svg/buttonIcons/PlusIcon";
import MainButton from "../../components/buttons/MainButton";
import TableEditIcon from "../../assets/svg/TableEditIcon";
import DeleteItemIcon from "../../assets/svg/DeleteItemIcon";
import {CreateDepartmentDto, DepartmentDto} from "../../dtos/departments/DepartmentDto";
import useFetchData from "../../hooks/useFetchData";
import QuestionModal from "../../components/QuestionModal/QuestionModal";
import {TaskSourceDto} from "../../dtos/taskSource/TaskSourceDto";
import {EditPredefinedTaskModal} from "./modal/EditPredefinedTaskModal.tsx";
import SearchInput from "../../components/inputs/SearchInput.tsx";

const PredefinedTaskDescription = () => {
    const {
        items: taskDescriptions,
        error,
        deleteItem,
        fetchItems,
        isLoading,
        totalCount,
    } = useFetchData<CreateDepartmentDto, DepartmentDto, DepartmentDto>("PredefinedEmployeeTaskDescriptions");

    const [selectedTaskDescriptionId, setSelectedTaskDescriptionId] = useState<string | undefined>(undefined);
    const [showTaskDescriptionDeleteQuestionModal, setShowTaskDescriptionDeleteQuestionModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState<string>("");

    const columns: DataTableColumn[] = [
        {
            title: t("TaskDescription"),
            field: "description"
        },
        {
            field: "departmentDescription",
            title: t("Department")
        },
        {
            field: "approximatedTime",
            title: t("ApproximatedTime")
        },
        {
            title: t("Actions"),
            field: "actions",
            render: (value: any) => (
                <div className="flex gap-2">
                    <button onClick={() => handleEditTaskDescription(value)}><TableEditIcon/></button>
                    <button onClick={() => handleDeleteTaskDescription(value)}><DeleteItemIcon/></button>
                </div>
            )
        }
    ]

    const handleOnReload = (newPageSize, newPageNumber) => {
        setPageSize(newPageSize);
        setPageNumber(newPageNumber);
    };
    const handleCloseModal = async () => {
        setShowEditModal(false);
    }

    const handleTaskDescriptionUpdate = async () => {
        await handleCloseModal();
        await fetchData();
    };

    const handleNewTaskDescription = () => {
        setSelectedTaskDescriptionId(undefined);
        setShowEditModal(true);
    };

    const handleEditTaskDescription = (department: DepartmentDto) => {
        setSelectedTaskDescriptionId(department.id);
        setShowEditModal(true);
    };

    const handleDeleteTaskDescription = async (taskSource: TaskSourceDto) => {
        setSelectedTaskDescriptionId(taskSource.id);
        setShowTaskDescriptionDeleteQuestionModal(true);
    };

    const handleDeleteTaskDescriptionConfirmed = async () => {
        setShowTaskDescriptionDeleteQuestionModal(false);
        if (!selectedTaskDescriptionId) return;

        await deleteItem(selectedTaskDescriptionId);
        await fetchData();
    };

    const fetchData = useCallback(async () => {
        await fetchItems(searchText, (pageNumber - 1) * pageSize, pageSize);
    }, [pageSize, pageNumber, searchText]);

    useEffect(() => {
        fetchData();
    }, [pageSize, pageNumber, searchText])

    return (

        <PageContainer>
            <PageHeader title={t("TaskDescription")}>
                <div className={"flex gap-2"}>
                    <SearchInput searchText={searchText} onSearchTextChanged={setSearchText}/>
                    <MainButton
                        disabled={isLoading}
                        title={t("NewDescription")}
                        onClick={handleNewTaskDescription}
                        icon={<PlusIcon/>}
                    />
                </div>
            </PageHeader>
            {error && <AlertDanger errorMessages={[error]}/>}
            <Loading loading={isLoading}>
                <div className={"mt-2"}>
                    <DataTable
                        showPagination={true}
                        items={taskDescriptions}
                        columns={columns}
                        pageSize={pageSize}
                        currentPage={pageNumber}
                        totalCount={totalCount}
                        onReload={handleOnReload}
                    />
                </div>
            </Loading>
            {
                showEditModal && (
                    <EditPredefinedTaskModal
                        onClose={handleCloseModal}
                        onChanged={handleTaskDescriptionUpdate}
                        descriptionId={selectedTaskDescriptionId}
                    />
                )
            }

            {
                showTaskDescriptionDeleteQuestionModal && (
                    <QuestionModal onClose={() => setShowTaskDescriptionDeleteQuestionModal(false)}
                                   onYes={handleDeleteTaskDescriptionConfirmed}
                                   title={t("AreYouSureYouWantToDeleteThisDescription")}
                                   content={t("ThisActionCannotBeUndone")}
                                   icon={<DeleteItemIcon/>} type={"danger"}
                                   yesButtonText={t("Yes")}
                                   noButtonText={t("No")}/>
                )
            }

        </PageContainer>
    );
};

export default PredefinedTaskDescription;
