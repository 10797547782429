import React, {FC, PropsWithChildren} from 'react';
import GlobeIcon from "../../assets/svg/GlobeIcon.tsx";
import BaseModalButtons from './BaseModalButtons.tsx';
import CloseButton from '../buttons/CloseButton.tsx';
import CloseIcon from "../../assets/svg/CloseIcon.tsx";
import {ThemeColors} from "../../theme/colors.ts";

type Props = {
    title?: string;
    description?: string;
    icon?: React.ReactNode;
    onClose?: () => void;
}

const BaseModalHeader: FC<PropsWithChildren<Props>> = ({title, description, icon, onClose}) => {
    return (
        <>
            <div className="mb-4 w-full sm:flex sm:items-start">
                {icon && (
                    <div
                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:mr-4 sm:h-10 sm:w-10">
                        {icon}
                    </div>
                )}
                <div className="flex w-full justify-between text-center sm:text-left items-center">
                    <h3
                        className="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                    >
                        {title}
                    </h3>
                    <p className="text-sm text-black-secondary-strong">{description}</p>
                    <div onClick={onClose} className={"p-2 hover:bg-black-10 rounded-full cursor-pointer"}>
                        <CloseIcon color={ThemeColors.blackColor} strokeColor={ThemeColors.blackColor}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BaseModalHeader;