import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

//Firebase Config values imported from .env file
export const firebaseConfig = {
    apiKey: import.meta.env.VITE_APP_API_KEY,
    authDomain: import.meta.env.VITE_APP_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_APP_PROJECT_ID,
    storageBucket: import.meta.env.VITE_APP_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_APP_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_APP_ID,
    measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);

// // src/firebase-config.js
//
// import firebase from 'firebase/app';
// import 'firebase/messaging';
//
// const firebaseConfig = {
//     apiKey: "<YOUR_API_KEY>",
//     authDomain: "<YOUR_AUTH_DOMAIN>",
//     projectId: "<YOUR_PROJECT_ID>",
//     storageBucket: "<YOUR_STORAGE_BUCKET>",
//     messagingSenderId: "<YOUR_MESSAGING_SENDER_ID>",
//     appId: "<YOUR_APP_ID>",
//     measurementId: "<YOUR_MEASUREMENT_ID>"
// };
//
// firebase.initializeApp(firebaseConfig);
//
// const messaging = firebase.messaging();
//
// export const getToken = async () => {
//     try {
//         const currentToken = await messaging.getToken({ vapidKey: '<YOUR_PUBLIC_VAPID_KEY>' });
//         if (currentToken) {
//             console.log('Device token:', currentToken);
//             return currentToken;
//         } else {
//             console.log('No registration token available. Request permission to generate one.');
//             return null;
//         }
//     } catch (err) {
//         console.error('An error occurred while retrieving token. ', err);
//         return null;
//     }
// };
