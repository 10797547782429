import {Link, useNavigate} from "react-router-dom";
import {AdminPanelMenu} from "../../components/layouts/AdminPanel/AdminPanelMenu";
import PageHeader from "../../components/PageHeader/PageHeader";
import {t} from "i18next";
import PageContainer from "../../components/PageContainer/PageContainer";
import PageCard from "../../components/PageCard/PageCard.tsx";
import {IMenuItemsDto} from "../../dtos/IMenuItems/IMenuItemsDto.ts";

const AdminPanel = () => {
    const navigate = useNavigate();

    const handleOpenPage = (menuItem: IMenuItemsDto) => {
        navigate(menuItem.path);
    };

    return (
        <PageContainer>
            <PageHeader title={t("AdminPanel")}></PageHeader>
            <PageCard>
                {AdminPanelMenu.map((menuItem, index) => (
                    <div onClick={() => handleOpenPage(menuItem)}
                         key={index}
                         className={`select-none cursor-pointer hover:bg-dark-green-20 ${index === 0 ? "hover:rounded-t-2xl" : ""} ${index === AdminPanelMenu.length - 1 ? "hover:rounded-b-2xl" : ""} font-myriad text-sm font-regular`}>
                        {index > 0 && <hr/>}
                        <div className={"px-6 py-6"}>
                            <div>{t(menuItem.title)}</div>
                        </div>
                    </div>
                ))}
            </PageCard>
        </PageContainer>
    );
};

export default AdminPanel;