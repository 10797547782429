import React, {FC, PropsWithChildren, useState} from 'react';
import BaseModal from "../../../components/Modals/BaseModal.tsx";
import BaseModalContent from "../../../components/Modals/BaseModalContent.tsx";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader.tsx";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons.tsx";
import SelectDateControl from "../../../components/SelectDateControl/SelectDateControl.tsx";
import {useTranslation} from "react-i18next";
import FilterApartmentsIcon from "../../../assets/svg/apartmentIcons/FilterApartmentsIcon.tsx";
import {ThemeColors} from "../../../theme/colors.ts";
import {MessageFilterSettings} from "../types.ts";
import SecondaryButton from "../../../components/buttons/SecondaryButton.tsx";
import ClearFilterIcon from "../../../assets/svg/ClearFilterIcon.tsx";

type Props = {
    onClose: () => void;
    onApply: (newFilterSettings: MessageFilterSettings) => void;
    settings: MessageFilterSettings;
}

const MessageFilterModal: FC<PropsWithChildren<Props>> = ({onClose, onApply, settings}) => {
    const {t} = useTranslation();
    const [startDate, setStartDate] = useState<Date | undefined>(settings.startDate);
    const [endDate, setEndDate] = useState<Date | undefined>(settings.endDate);

    const handleApplyFilters = () => {
        const newFilterSettings: MessageFilterSettings = {
            startDate,
            endDate
        }
        onApply(newFilterSettings);
    };

    return (
        <>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={t("Filter")} onClose={onClose}/>
                    <div className={"mt-2 flex"}>
                        <div className={"me-1 flex-1"}>
                            <SelectDateControl onDateChanged={(date) => setStartDate(date)}
                                               value={startDate}
                                               label={t("From")}/>
                        </div>
                        <div className={"ms-1 flex-1"}>
                            <SelectDateControl onDateChanged={(date) => setEndDate(date)}
                                               value={endDate}
                                               label={t("To")}/>
                        </div>
                    </div>
                </BaseModalContent>
                <BaseModalButtons onClose={onClose}
                                  saveButtonText={"Apply"}
                                  saveButtonIcon={<FilterApartmentsIcon strokeColor={ThemeColors.whiteColor}
                                                                        color={ThemeColors.whiteColor}/>}
                                  onSave={handleApplyFilters}>
                    <SecondaryButton title={t("Clear")}
                                     icon={<ClearFilterIcon color={ThemeColors.mainColor}/>}
                                     onClick={() => {
                                         onApply({startDate: undefined, endDate: undefined});
                                     }}/>
                </BaseModalButtons>
            </BaseModal>
        </>
    );
};

export default MessageFilterModal;