import {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import CloseIcon from "../../assets/svg/CloseIcon.tsx";
import {ThemeColors} from "../../theme/colors.ts";

type Props = {
    onClick?: () => void;
    disabled?: boolean;
    extraClassName?: string;
    isSubmitting?: boolean;
    isFullButton?: boolean;
    title?: string;
};

const CloseButton = ({
                         extraClassName,
                         onClick,
                         disabled,
                         isSubmitting,
                         isFullButton,
                         title
                     }: Props) => {
    const {t} = useTranslation();
    const iconColor = isSubmitting === true || disabled ? ThemeColors.whiteColor : ThemeColors.mainColor;

    return (
        <button
            type="button"
            className={
                `
                select-none flex flex-row justify-center sm:justify-start items-center 
                gap-2 rounded-lg font-myriad text-sm font-regular text-dark-green bg-black-secondary-strong 
                disabled:text-black-secondary-strong disabled:bg-black-20 hover:bg-dark-green-10 
                ${isFullButton ? ("border-[1px] border-black-20 pl-[9px] pr-[13px] h-[36px]") : ("")} 
                ${extraClassName}
              `
            }
            disabled={isSubmitting === true || disabled === true}
            onClick={onClick}
        >
            <CloseIcon color={iconColor} strokeColor={iconColor}/>
            {isFullButton && (
                <>
                    {title ?? t("Close")}
                </>
            )}
        </button>
    );
};

export default CloseButton;
