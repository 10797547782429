import React, {
    FC,
    forwardRef,
    PropsWithChildren,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import {addDays, endOfDay, endOfMonth, format, getWeek, startOfDay, startOfMonth, startOfWeek} from 'date-fns';
import PrevIcon from "../../assets/svg/PrevIcon.tsx";
import LeftArrow from "../../assets/svg/CalendarIcons/LeftArrow.tsx";
import NextArrow from "../../assets/svg/NextArrow.tsx";
import RightArrow from "../../assets/svg/CalendarIcons/RightArrow.tsx";
import useMediaQuery from "../../hooks/useMediaQuery.ts";
import {EventTypeEnum} from "../../enums/EventTypeEnum.ts";
import ListResponseDto from "../../dtos/BaseResponse/ListResponseDto.ts";
import './TestCalndar.css';
import {CalendarEventDto} from '../../dtos/calendar/CalendarEventDto.ts';
import useFetchCalendar from "../../hooks/useFetchCalendar.ts";
import {useTranslation} from "react-i18next";
import {CalendarSchedulerEventsModal} from "../../components/Modals/CalendarModal/CalendarSchedulerEventsModal.tsx";

type Props = {
    currentMonth: Date;
    onMonthChanged: (date: Date) => void;
}

type Day = {
    date: Date;
    events: CalendarEventDto[];
}

export interface ModalForDayEvents {
    [key: string]: boolean;
}

const EVENT_COLORS = {
    RESERVATION: "bg-dark-green",
    ACCOMMODATION: "accommodation",
    IN_HOUSE: "in-house"
};

function getFirstDayOfWeek(date: Date) {
    const dayOfWeek = date.getDay();
    const difference = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // If it's Sunday (0), we need to subtract 6 days to get to Monday
    console.log("dayOfWeek", dayOfWeek)
    console.log("difference", difference);
    return new Date(date.setDate(date.getDate() + difference));
}

export interface CalendarEventsRef {
    fetchData: () => Promise<void>;
}

// const events: CalendarEventDto[] =
//     [
//         {
//             id: "0",
//             startDate: new Date(2024, 4, 1),
//             endDate: new Date(2024, 4, 8),
//             type: EventTypeEnum.Accommodation,
//             amount: 1,
//             roomId: "0",
//             roomNo: "123",
//             guestFullName: 'Joe Doel',
//             statusName: 'in',
//         },
//         {
//             id: "1",
//             startDate: new Date(2024, 4, 5),
//             endDate: new Date(2024, 4, 15),
//             type: EventTypeEnum.Reservation,
//             amount: 2,
//             roomId: "1",
//             roomNo: "134",
//             guestFullName: 'Josh Noe',
//             statusName: 'sas',
//         },
//         {
//             id: "2",
//             startDate: new Date(2024, 4, 7),
//             endDate: new Date(2024, 4, 15),
//             type: EventTypeEnum.InHouse,
//             amount: 3,
//             roomId: "2",
//             roomNo: "132",
//             guestFullName: 'Aren Nest',
//             statusName: "as",
//         },
//         {
//             id: "3",
//             startDate: new Date(2024, 4, 20),
//             endDate: new Date(2024, 4, 25),
//             type: EventTypeEnum.Accommodation,
//             amount: 3,
//             roomId: "0",
//             roomNo: "123",
//             guestFullName: 'Aren gf',
//             statusName: "as",
//         },
//         {
//             id: "4",
//             startDate: new Date(2024, 4, 15),
//             endDate: new Date(2024, 4, 26),
//             type: EventTypeEnum.Reservation,
//             amount: 3,
//             roomId: "1",
//             roomNo: "134",
//             guestFullName: 'Aren SDA',
//             statusName: "as",
//         },
//
//         {
//             id: "5",
//             startDate: new Date(2024, 4, 16),
//             endDate: new Date(2024, 4, 27),
//             type: EventTypeEnum.InHouse,
//             amount: 3,
//             roomId: "2",
//             roomNo: "132",
//             guestFullName: 'Aren Nest',
//             statusName: "as",
//         },
//         {
//             id: "6",
//             startDate: new Date(2024, 4, 25),
//             endDate: new Date(2024, 4, 29),
//             type: EventTypeEnum.InHouse,
//             amount: 3,
//             roomId: "0",
//             roomNo: "140",
//             guestFullName: 'Aren Nest',
//             statusName: "as",
//         },
//         {
//             id: "7",
//             startDate: new Date(2024, 4, 30),
//             endDate: new Date(2024, 4, 31),
//             type: EventTypeEnum.InHouse,
//             amount: 3,
//             roomId: "0",
//             roomNo: "140",
//             guestFullName: 'Aren Nest',
//             statusName: "as",
//         },
//         {
//             id: "8",
//             startDate: new Date(2024, 5, 2),
//             endDate: new Date(2024, 5, 3),
//             type: EventTypeEnum.InHouse,
//             amount: 3,
//             roomId: "0",
//             roomNo: "143",
//             guestFullName: 'Aren Nest',
//             statusName: "as",
//         },
//     ]

const CalendarScheduler = forwardRef<CalendarEventsRef, Props>((props, ref) => {
    const {t} = useTranslation();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const isScreenWidthLessThan640 = useMediaQuery("(max-width: 640px)");
    const [days, setDays] = React.useState<Day[]>([]);
    const [modalForDayEvents, setModalForDayEvents] = useState<ModalForDayEvents | null>(null);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEventDto | null>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const {currentMonth, onMonthChanged} = props;

    const {fetchCalendarEvents, calendarEvents, isLoading, error} = useFetchCalendar();

    console.log(calendarEvents)

    const date = new Date();
    const weekStart = startOfWeek(date);
    const daysOfWeek = Array.from({length: 7}, (_, i) => addDays(weekStart, i));

    const onPreviousMonth = () => {
        onMonthChanged(
            new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1),
        );
    };
    const onNextMonth = () => {
        onMonthChanged(
            new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1),
        );
    };

    const fetchData = async () => {
        await fetchCalendarEvents(startOfMonth(currentMonth), endOfMonth(currentMonth));
    }

    useEffect(() => {
        fetchData();
    }, [currentMonth]);

    useImperativeHandle(ref, () => ({
        fetchData
    }));

    const fillCalendar = useCallback(() => {
        if (!calendarEvents) return;

        const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const firstDayOfTheWeek = getFirstDayOfWeek(firstDayOfMonth);

        // initial days
        const newDays: Day[] = [];
        for (let i = 0; i < 42; i++) {
            const date = new Date(firstDayOfTheWeek);
            date.setDate(date.getDate() + i);
            newDays.push({
                date,
                events: [],
            });
        }

        console.log("calendarEvents", calendarEvents)
        newDays.forEach(day => {
            console.log(11)
            day.events = calendarEvents.filter(event => {
                console.log("day.date", day.date)
                console.log("----------------------- begin -----------------------");
                console.log(startOfDay(day.date).getTime())
                console.log(startOfDay(event.startDate).getTime())
                console.log(startOfDay(event.endDate).getTime())
                console.log("------------------------ end ------------------------");
                return (startOfDay(day.date).getTime() >= startOfDay(event.startDate).getTime() &&
                        endOfDay(day.date).getTime() <= endOfDay(event.endDate).getTime());
            }) ?? [];

            if (day.events.length > 0) {
                console.log("day.events", day.events)
            }
            // day.event = calendarEvents.items?.find(event => {
            //     return startOfDay(day.date).getTime() > startOfDay(event.startDate).getTime() &&
            //         endOfDay(day.date).getTime() < endOfDay(event.endDate).getTime();
            // });
            //
            // day.startEvent = calendarEvents.items?.find(event => {
            //     return startOfDay(day.date).getTime() === startOfDay(event.startDate).getTime();
            // });
            //
            // day.endEvent = calendarEvents.items?.find(event => {
            //     return endOfDay(day.date).getTime() === endOfDay(event.endDate).getTime();
            // });

            // The console logs here can help you debug
            // console.log("day", day);
        });

        console.log("newDays", newDays)
        
        setDays(newDays);
    }, [calendarEvents, currentMonth]);

    useEffect(() => {
        fillCalendar();
    }, [currentMonth, calendarEvents]);
    const getDayCssClass = (day: Day) => {
        let css = "cursor-pointer items-center justify-center text-black-40";
        //
        // if (day.startEvent && !day.endEvent) {
        //     css += " border-l  border-r-0 "
        // } else if (day.endEvent && !day.startEvent) {
        //     css += " border-l-0 "
        // } else if (day.startEvent && day.endEvent) {
        //     css += " border-l-0 border-r-0"
        // } else if (day.event) {
        //     console.log("day.event", day)
        //     if (day.date.getTime() > day.event?.startDate.getTime()) {
        //         css += " border border-l-0 "
        //     }
        //     if (day.date.getTime() < day.event?.endDate.getTime()) {
        //         css += " border border-r-0 "
        //     }
        // } else {
        //     css += " border  "
        // }

        return css;
    }

    const getEventCssClass = (eventType?: number) => {
        let css = "";

        switch (eventType) {
            case EventTypeEnum.Reservation:
                css += `${EVENT_COLORS.RESERVATION} `;
                break;
            case EventTypeEnum.Accommodation:
                css += `${EVENT_COLORS.ACCOMMODATION} `;
                break;
            case EventTypeEnum.InHouse:
                css += `${EVENT_COLORS.IN_HOUSE} `;
                break;
            default:
                break;
        }

        return css;
    }

    const handleOpenEventsModal = (day: Day, event?: CalendarEventDto) => {
        setModalForDayEvents(prevState => ({
            ...prevState,
            [day.date.toISOString()]: true
        }));

        if (event) {
            setSelectedEvent(event);
        }
    };

    useEffect(() => {
        const handleClickOutside = () => {
            if (divRef.current) {
                setSelectedEvent(null);
                setModalForDayEvents({});
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef]);

    const renderEvent = (day: Day) => {
        const currentYear = date.getFullYear();
        const currentMonth = date.getMonth() + 1;
        const currentDay = date.getDate();

        const formattedDate = format(day.date, "yyyy-MM-dd");
        const [year, month, dayOfMonth] = formattedDate.split("-").map(Number);

        const currentWeakDay = format(day.date, 'EEEE');

        return (
            <>
                <div className={"flex h-[90px] w-full flex-col sm:h-[124px]"}>
                    <div className={"flex items-center justify-center px-px"}>
                        <div
                            className={`mb-0.5 mr-1 flex items-center justify-center rounded-lg
                          ${
                                currentYear === year &&
                                currentMonth === month &&
                                currentDay === dayOfMonth
                                    ? "w-[24px] bg-dark-green-20 text-dark-green-60"
                                    : ""
                            } 
                          `}
                        >
                            {format(day.date, "d")}
                        </div>
                        {day.events.length > 3 && (
                            <button
                                className={
                                    "flex h-[19px] items-center rounded bg-blue-500 p-0.5 text-sm text-white"
                                }
                                onClick={() => handleOpenEventsModal(day)}
                            >
                                {`+${day.events.length - 3}`}
                            </button>
                        )}
                    </div>
                    <div className="relative h-full">
                        {day.events.slice(0, 3).map((event, index) => {
                            const firstEventDay = event.startDate.valueOf();
                            const lastEventDay = event.endDate.valueOf();
                            const secondEventDay = event.startDate
                                ? firstEventDay + 1000 * 60 * 60 * 24
                                : null;

                            const isFirstDay = firstEventDay === day.date.getTime();
                            const isSecondEventDay =
                                secondEventDay === day.date.getTime();
                            const isLastDay = lastEventDay === day.date.getTime();
                            const topValue = isScreenWidthLessThan640 ? 20 : 28;
                            const eventsWithSameOrder = calendarEvents.filter(
                                (e) =>
                                    +e.roomId === +event.roomId &&
                                    e.endDate.valueOf() === event.startDate.valueOf(),
                            );

                            const cssClass = getEventCssClass(event.type);
                            return (
                                <>
                                    <div
                                        key={index}
                                        className=
                                            {`
                                ${cssClass} 
                                absolute
                                flex h-[18px] w-full items-center
                                text-white duration-300 sm:h-[24px]
                                ${isFirstDay ? "event-range right-0 rounded-l-lg" : ""}
                                ${
                                                isSecondEventDay &&
                                                eventsWithSameOrder.length > 0 &&
                                                day.events.length >= 3
                                                    ? "rounded-l-lg"
                                                    : ""
                                            }
                                ${isLastDay ? "event-range left-0 rounded-r-lg" : ""}
                              `}
                                        onClick={() => handleOpenEventsModal(day, event)}
                                        ref={divRef}
                                        style={{
                                            top: `${+event.roomId * topValue}px`,
                                        }}
                                    >
                                        {isSecondEventDay && (
                                            <span
                                                className={`
                                          absolute z-3 flex w-[80px] items-center text-xs md:text-base
                                          ${
                                                    event.endDate.valueOf() === secondEventDay
                                                    && currentWeakDay !== "Monday"
                                                    && isSecondEventDay
                                                        ? "-left-3"
                                                        : "-left-0.5 ml-0.5"}
                                        `}
                                            >
                            {event.roomNo}
                          </span>
                                        )}
                                    </div>

                                    {selectedEvent && (
                                        <div
                                            className={`absolute m-1 shadow-2xl
                                  ${
                                                currentWeakDay === "Sunday" ||
                                                currentWeakDay === "Saturday" ||
                                                currentWeakDay === "Friday"
                                                    ? "right-2"
                                                    : "left-2"
                                            } 
                                  z-50 rounded-lg
                                  `}
                                        >
                                            {modalForDayEvents &&
                                                modalForDayEvents[day.date.toISOString()] && (
                                                    <CalendarSchedulerEventsModal
                                                        key={selectedEvent.id}
                                                        event={selectedEvent}
                                                        cssClass={getEventCssClass(selectedEvent.type)}
                                                    />
                                                )}
                                        </div>
                                    )}

                                    <div
                                        className={`absolute shadow-2xl
                                  ${
                                            currentWeakDay === "Sunday" ||
                                            currentWeakDay === "Saturday" ||
                                            currentWeakDay === "Friday"
                                                ? "right-2"
                                                : "left-2"
                                        } 
                                  z-50 flex max-h-44 flex-col overflow-x-auto rounded-lg
                                  `}
                                    >
                                        {!selectedEvent &&
                                            calendarEvents
                                                .slice(3)
                                                .map(
                                                    (modalEvents, modalIndex) =>
                                                        modalForDayEvents &&
                                                        modalForDayEvents[day.date.toISOString()] && (
                                                            <CalendarSchedulerEventsModal
                                                                key={modalIndex}
                                                                event={modalEvents}
                                                                cssClass={getEventCssClass(
                                                                    modalEvents.type,
                                                                )}
                                                            />
                                                        ),
                                                )}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </>
        );
        // if (day.startEvent && !day.endEvent) {
        //     const cssClass = getEventCssClass(day.startEvent.type);
        //     return (
        //         <div className={"flex min-h-full"}>
        //             <div className={"flex-1"}>
        //             </div>
        //             <div className={`${cssClass} flex-1 flex align-middle justify-center`}>
        //                 {format(day.date, "d")}
        //             </div>
        //         </div>
        //     )
        // }
        //
        // if (day.endEvent && !day.startEvent) {
        //     const cssClass = getEventCssClass(day.endEvent.type);
        //     return (
        //         <div className={"flex min-h-full"}>
        //             <div className={`${cssClass} flex-1 flex align-middle justify-center`}>
        //                 {format(day.date, "d")}
        //             </div>
        //             <div className={"flex-1"}>
        //             </div>
        //         </div>
        //     )
        // }
        //
        // if (day.startEvent && day.endEvent) {
        //     if (day.startEvent.id === day.endEvent.id) {
        //         const cssClass = getEventCssClass(day.startEvent.type);
        //         return (
        //             <div className={`${cssClass} flex align-middle justify-center`}
        //                  style={{minWidth: 50, minHeight: 50}}>
        //                 {format(day.date, "d")}
        //             </div>
        //         )
        //     }
        //
        //     const startEventCssClass = getEventCssClass(day.startEvent.type);
        //     const endEventCssClass = getEventCssClass(day.endEvent.type);
        //     return (
        //         <div className={"flex min-h-full"}>
        //             <div className={`${endEventCssClass} flex-1 flex align-middle justify-center me-1`}>
        //                 {format(day.date, "d")}
        //             </div>
        //             <div className={`${startEventCssClass} flex-1 flex align-middle justify-center`}>
        //                 {format(day.date, "d")}
        //             </div>
        //         </div>
        //     )
        // }
        //
        // const cssClass = getEventCssClass(day.event?.type);
        //
        // return (
        //     <div className={`${cssClass} flex align-middle justify-center`} style={{minWidth: 50, minHeight: 50}}>
        //         {format(day.date, "d")}
        //     </div>
        // )
    }

    return (
        <>
            <div className="w-full rounded-2xl border-[1px] border-black-10 bg-black-secondary-strong select-none">
                <div
                    className="flex flex-row items-center justify-between border-b-[1px] border-black-10 px-3 py-3 md:h-[72px] md:px-6 md:py-5"
                >
                    <div
                        className="px-5 py-2.5 hover:cursor-pointer hover:rounded-lg hover:bg-selected-black"
                        onClick={onPreviousMonth}
                    >
                        {isMobile ? <PrevIcon/> : <LeftArrow/>}
                    </div>
                    <div className="font-myriad text-sm font-regular text-black-80 md:text-lg">
                        {format(currentMonth, 'MMMM')} {currentMonth.getFullYear()}
                    </div>
                    <div
                        className="px-5 py-2.5 hover:cursor-pointer hover:rounded-lg hover:bg-selected-black"
                        onClick={onNextMonth}
                    >
                        {isMobile ? <NextArrow/> : <RightArrow/>}
                    </div>
                </div>
                <div className="grid grid-cols-7">
                    {daysOfWeek.map((day, index) => (
                        <div key={index} className="flex justify-center leading-6 my-6">
                            {format(day, 'EEEEEE')}
                        </div>
                    ))}
                </div>
                <div className="grid grid-cols-7">
                    {days.map((day, index) => {
                        const cssClass = getDayCssClass(day);
                        return (
                            <div
                                className={cssClass}
                                key={index}>
                                {renderEvent(day)}
                            </div>
                        )
                    })}
                </div>
                <div
                    className="flex flex-row flex-wrap items-center justify-center border-t-[1px] border-black-10 px-3 py-3 md:h-[72px] md:px-6 md:py-5 gap-[10px] sm:gap-[24px]">
                    <div className="flex items-center">
                        <span className={`mr-1 h-[8px] w-[8px] rounded-full ${EVENT_COLORS.RESERVATION}`}></span>
                        <p className="text-sm sm:text-base whitespace-nowrap">
                            {t("Reservation")}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <span className={`mr-1 h-[8px] w-[8px] rounded-full ${EVENT_COLORS.ACCOMMODATION}`}></span>
                        <p className="text-sm sm:text-base whitespace-nowrap">
                            {t("Accommodation")}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <span className={`mr-1 h-[8px] w-[8px] rounded-full ${EVENT_COLORS.IN_HOUSE}`}></span>
                        <p className="text-sm sm:text-base whitespace-nowrap">
                            {t("InHouse")}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
});

export default CalendarScheduler;
