import React, {FC, PropsWithChildren} from 'react';
import MainButton from "../buttons/MainButton.tsx";
import SecondaryButton from "../buttons/SecondaryButton.tsx";
import CloseIcon from "../../assets/svg/CloseIcon.tsx";
import SaveIcon from "../../assets/svg/SaveIcon.tsx";
import CloseButton from "../buttons/CloseButton.tsx";
import ClearButton from '../buttons/ClearButton.tsx';

type Props = {
    onClose?: () => void;
    onSave?: () => void;
    showSaveButton?: boolean;
    showCloseButton?: boolean;
    saveButtonText?: string;
    saveButtonIcon?: React.ReactNode;
    closeButtonText?: string;
    inProgress?: boolean;
    saveButtonDisabled?: boolean;
    showFullButton?: boolean;
    separateButtons?: boolean;
    extraClassName?: string;
}

const BaseModalButtons: FC<PropsWithChildren<Props>> = ({
                                                            inProgress,
                                                            saveButtonDisabled,
                                                            children,
                                                            onClose,
                                                            onSave,
                                                            showSaveButton = true,
                                                            saveButtonText = "Save",
                                                            saveButtonIcon,
                                                            showCloseButton = false,
                                                            showFullButton = true,
                                                            closeButtonText,
                                                            separateButtons = true,
                                                            extraClassName,
                                                        }) => {

    const renderSaveButtonIcon = () => {
        if (saveButtonIcon) {
            return <>{saveButtonIcon}</>;
        }
        return <SaveIcon/>;
    }
    return (
        <>
            <div className={`bg-black-secondary-strong mb-4 flex px-5 ${extraClassName}`}>
                {showCloseButton && <CloseButton disabled={inProgress}
                                                 isSubmitting={inProgress}
                                                 title={closeButtonText}
                                                 onClick={onClose}
                                                 extraClassName={"w-auto sm:w-auto justify-center"}
                                                 isFullButton={showFullButton}/>}
                {children}
                {separateButtons && <span className={"flex-grow"}/>}
                {showSaveButton && <MainButton disabled={saveButtonDisabled || inProgress} inProgress={inProgress}
                                               icon={renderSaveButtonIcon()} title={saveButtonText} onClick={onSave}
                                               extraClassName={"w-auto sm:w-auto justify-center"}/>}
            </div>
        </>
    );
};

export default BaseModalButtons;