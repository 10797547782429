import { Outlet } from "react-router-dom";
import Header from "../../components/layouts/Header/Header";

const Auth = () => {
  return (
    <div className="relative grid h-screen grid-cols-1 grid-rows-[72px_1fr]">
      <div className="fixed top-0 z-50 w-full">
        <Header isOnDashboard={false} />
      </div>

      <div className="relative col-span-1 row-start-2 flex h-full items-center justify-center overflow-hidden overflow-y-auto bg-black-secondary-strong md:bg-black-0">
        <Outlet />
      </div>
    </div>
  );
};

export default Auth;
