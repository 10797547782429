import React, {FC, PropsWithChildren, useState} from 'react';
import BaseModal from "../../../components/Modals/BaseModal.tsx";
import BaseModalContent from "../../../components/Modals/BaseModalContent.tsx";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader.tsx";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons.tsx";
import Loading from "../../../components/Loading/Loading.tsx";
import toast from "react-hot-toast";
import {axiosInstance} from "../../../config/appConfig.ts";
import FormFileInput from "../../../components/FormControls/FormFileInput.tsx";

type Props = {
    onUploaded: () => void;
    onClose: () => void;
    id: string;
}

const EditFileModal: FC<PropsWithChildren<Props>> = ({id, onClose, onUploaded}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const handleSaveFile = async () => {
        if (!file) {
            toast.error("Please select a file to upload.");
            return;
        }

        try {
            setIsSubmitting(true);
            const formData = new FormData();
            formData.append("file", file as Blob);
            formData.append("id", id);

            const response = await axiosInstance.post(`FileAttachment`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            if (response.status === 200) {
                toast.success("File uploaded successfully.");
                onUploaded && onUploaded();
            } else {
                toast.error("An error occurred while uploading the file.");
            }
        } catch (e) {
            console.log(e);
            toast.error("An error occurred while uploading the file.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const changeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFile(e.target.files ? e.target.files[0] : null);
    };

    return (
        <>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={"Edit File"}/>
                    <Loading type={"cover"} loading={isSubmitting}>
                        <div>
                            <FormFileInput onChange={(e) => changeFile(e)}/>
                            {/*<input onChange={(e) => changeFile(e)}*/}
                            {/*       type="file"*/}
                            {/*       accept={"application/pdf"}*/}
                            {/*       className="block w-full text-sm text-slate-500         */}
                            {/*           file:mr-4 file:py-2 file:px-4 file:rounded-md*/}
                            {/*           file:border-0 file:text-sm file:font-semibold*/}
                            {/*           file:bg-dark-green-20 file:text-dark-green*/}
                            {/*           hover:file:bg-dark-green hover:file:text-black-secondary-strong"*/}
                            {/*/>*/}
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons saveButtonDisabled={isSubmitting} inProgress={isSubmitting} onClose={onClose}
                                  showSaveButton={true} onSave={handleSaveFile}></BaseModalButtons>
            </BaseModal>
        </>
    );
};

export default EditFileModal;