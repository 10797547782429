import {ApexOptions} from 'apexcharts';
import React, {useCallback, useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import {useTranslation} from "react-i18next";
import Loading from '../../../components/Loading/Loading';
import useFetchEmployeeTasks from '../../../hooks/useFetchEmployeeTasks.ts';
import {useAppSelector} from '../../../redux/hooks/hooks.ts';
import useFetchDashboard from '../../../hooks/useFetchDashboard.ts';
import {selectTasksRefreshIndex} from "../../../redux/slices/tasksSlice.ts";
import {selectDashboardFilter} from '../../../redux/slices/appSlice.ts';

type Props = {
    title: string;
}

const initialOptions: ApexOptions = {
    series: [44, 55, 41, 17, 15],
    chart: {
        type: 'donut',
        height: 350,
        fontFamily: "font-myriad",
        events: {
            mounted(chart: any, options?: any) {
                chart.windowResizeHandler();
            }
        }
    }

    // options: {
    //     chart: {
    //         type: 'donut',
    //         height: 350,
    //         fontFamily: "font-regular",
    //     },
    //     plotOptions: {
    //         bar: {
    //             borderRadius: 4,
    //             borderRadiusApplication: 'end',
    //             horizontal: true,
    //         }
    //     },
    //     dataLabels: {
    //         enabled: false
    //     },
    //     xaxis: {
    //         categories: [],
    //     }
    // }
};

const DashboardCardCircleChartTaskBySources: React.FC<Props> = ({
                                                                    title,
                                                                }) => {
    const [chartData, setChartData] = useState(initialOptions);
    const {t} = useTranslation();

    const tasksRefreshIndex = useAppSelector(selectTasksRefreshIndex);

    const {isLoading, loadDashboardTaskBySources} = useFetchDashboard({});
    const filter = useAppSelector(selectDashboardFilter);

    const fetchData = useCallback(async () => {
        const tasksBySource = await loadDashboardTaskBySources(filter);
        const items = tasksBySource?.diagramItems ?? [];
        const series = items.map((item: any) => item.value);
        const label = items.map((item: any) => item.label);

        setChartData((prevState) => ({
            ...prevState,
            series: series,
            labels: label,
        }));

    }, [tasksRefreshIndex, filter]);

    useEffect(() => {
        fetchData();
    }, [tasksRefreshIndex, filter]);

    return (
        <div className="flex flex-col items-start md:flex-row md:gap-6 flex-1">
            <div
                className="w-full border-b-[1px] border-black-10 bg-black-secondary-strong sm:px-4 
                py-6 md:rounded-2xl md:border-[1px] md:px-0 md:py-0">
                <div
                    className="flex justify-between items-center py-4 sm:px-4 font-myriad text-lg text-black-80 md:border-b-[1px]">
                    <p>{t(title)}</p>
                </div>
                <Loading loading={isLoading}>
                    <div className="sm:p-2">
                        <ReactApexChart width={"100%"}
                                        height={550}
                                        options={chartData}
                                        series={chartData.series}
                                        type="donut"
                        />
                    </div>
                </Loading>
            </div>
        </div>
    );
    ;
};

export default DashboardCardCircleChartTaskBySources;