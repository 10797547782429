import React, {useCallback, useEffect, useMemo, useState} from "react";

import useFetchMessages from "../../hooks/useFetchMessages";

import {formatTime} from "../../helpers/formatTime";

import MandatoryIcon from "../../assets/svg/MessagesIcons/MandatoryIcon";
import NotificationsIcon from "../../assets/svg/MessagesIcons/NotificationsIcon";
import MainButton from "../../components/buttons/MainButton.tsx";
import {useTranslation} from "react-i18next";
import EditMessageIcon from "../../assets/svg/EditMessageIcon";
import DataTable from "../../components/DataTable/DataTable.tsx";
import MessageModal from "./modals/MessageModal.tsx";
import {StringHelper} from "../../utils/StringHelper.ts";
import AlertDanger from "../../components/Alerts/AlertDanger.tsx";
import MessageFilterModal from "./modals/MessageFilterModal.tsx";
import Loading from "../../components/Loading/Loading.tsx";
import FilterButton from "../../components/buttons/FilterButton.tsx";
import {format, formatDate} from "date-fns";
import {MessageFilterSettings} from "./types.ts";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks/hooks.ts";
import {selectAuthUserDetails, selectIsAdmin} from "../../redux/slices/authSlice.ts";
import {MessageDto} from "../../dtos/messages/MessageDto.ts";
import {defaultDateFormat} from "../../config/appConfig.ts";
import PageContainer from "../../components/PageContainer/PageContainer.tsx";
import PageHeader from "../../components/PageHeader/PageHeader.tsx";
import {debounceAsync} from "../../helpers/debounce.tsx";
import {DateTimeHelper} from "../../utils/DateTimeHelper.ts";
import TasksDetailModal from "../TasksPage/modals/TasksDetailsModal.tsx";
import {setNotificationsRefreshIndex} from "../../redux/slices/appSlice.ts";

const Messages = () => {
    const isAdmin = useAppSelector(selectIsAdmin);
    const {messageId} = useParams();
    const {t} = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const {
        messages,
        updateMessages,
        error,
        isLoading,
        fetchMessages,
        markIsRead,
        totalCount
    } = useFetchMessages();
    const [activeTabIndex, setActiveTabIndex] = useState(1);
    const [selectedMessageId, setSelectedMessageId] = useState<string | undefined>(undefined);
    const [showNewMessageModal, setShowNewMessageModal] = useState(false);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    
    const [filterSettings, setFilterSettings] = useState<MessageFilterSettings>({});
    const dispatch = useAppDispatch();

    const handleOnReload = (pageSize: number, pageNumber: number) => {
        setPageSize(pageSize);
        setCurrentPage(pageNumber);
    }

    const userDetails = useAppSelector(selectAuthUserDetails);

    // useEffect(() => {
    //     if (messages && selectedMessage) {
    //         const msg = messages.find((message) => message.id === selectedMessage.id);
    //         if (msg) {
    //             setSelectedMessage(msg);
    //             return;
    //         }
    //     }
    //
    //     if (messages && messageId) {
    //         const msg = messages.find((message) => message.id === messageId);
    //         if (msg) {
    //             setSelectedMessage(msg);
    //             return;
    //         }
    //     }
    //
    //     if (!selectedMessage) {
    //         if (messages && messages && messages.length > 0) {
    //             setSelectedMessage(messages[0]);
    //         } else {
    //             setSelectedMessage(undefined);
    //         }
    //     }
    // }, [messages, messageId, selectedMessage]);

    const handleShowTaskModal = useCallback(() => {
        setShowTaskModal(true);
    }, [selectedMessageId]);

    const fetchData = async () => {
        await fetchMessages(filterSettings.startDate, filterSettings.endDate, undefined,
            (currentPage - 1) * pageSize, pageSize, undefined);
    }

    useEffect(() => {
        fetchData();
    }, [pageSize, currentPage, activeTabIndex, filterSettings]);

    const renderMobileRow = (message: MessageDto) => {
        console.log("message", message)
        const currentUser = message.receivers.find(user => user.userId === userDetails?.id);

        return (
            <div
                className={`flex flex-col gap-2 select-none font-myriad text-sm 
                ${currentUser?.isRead ? "font-regular" : "font-bold"}`}
                onClick={() => handleClickMessage(message.id)}
            >
                <div className={"flex justify-between text-black"}>
                    <div className="">
                        {message.authorName}
                    </div>
                    <div>
                        {format(message.messageDate, "dd.MM.yyyy HH:mm")}
                    </div>
                </div>
                <div className="text-black-40 flex flex-row justify-between">
                    <div>
                        {StringHelper.cutStringByLengthAddDots(message.messageContent ?? "", 100)}
                    </div>
                    <div>
                        {currentUser?.isRead
                            ? <span className={"text-black-30"}>{t("Read")}</span>
                            : <span className={"text-dark-green"}>{t("Unread")}</span>}
                    </div>
                </div>
            </div>
        );
    }

    const columns: DataTableColumn[] = [
        {
            title: "Message",
            field: "messageContent",
            render: (message: MessageDto) => renderMobileRow(message),
        }
    ];

    const messageDate = useMemo(() => messages?.find(
        (message) => message.id === selectedMessageId,
    )?.messageDate, [selectedMessageId, messages]);

    const messageText = useMemo(() => messages
        ? messages?.find((message) => message.id === selectedMessageId)
        ?.messageContent || "no text"
        : "no text", [selectedMessageId, messages]);

    const messageEmployeeTaskDescription = useMemo(() => messages
        ? messages?.find((message) => message.id === selectedMessageId)
        ?.employeeTaskDescription || "no text"
        : "no text", [selectedMessageId, messages]);

    const messageEmployeeTaskId = useMemo(() => messages
        ? messages?.find((message) => message.id === selectedMessageId)
        ?.employeeTaskId || "no text"
        : "no text", [selectedMessageId, messages]);

    const callbackMarkIsRead = useCallback(async () => {
        const message = getSelectedMessage();
        console.log("message", message);
        if (!message) return;
        if (message.isRead) return;

        const success = await markIsRead(message.id);
        if (success) {
            const newMessages = messages.map((msg) => {
                if (msg.id === message.id) {
                    return {
                        ...msg, receivers: msg.receivers.map((receiver) => {
                            if (receiver.userId === userDetails?.id) {
                                return {...receiver, isRead: true}
                            }
                            return receiver;
                        })
                    }
                }
                return msg;
            });

            updateMessages(newMessages);
            dispatch(setNotificationsRefreshIndex());
        }
    }, [selectedMessageId, messages]);

    // const debounceMarkIsRead = debounceAsync(callbackMarkIsRead, 2000);

    useEffect(() => {
        callbackMarkIsRead();
    }, [selectedMessageId]);

    const handleClickMessage = async (messageId?: string) => {
        setSelectedMessageId(messageId);
    };

    const handleDoubleClickMessage = (message: MessageDto) => {
        setSelectedMessageId(message.id);
        setShowMessageModal(true);
    };

    const handleApplyFilters = (newFilterSettings: MessageFilterSettings) => {
        setShowFilterModal(false);
        setFilterSettings(newFilterSettings);
    };

    const handleCloseComposeMessageModal = (success: boolean | undefined) => {
        setShowNewMessageModal(false);
        if (success) {
            fetchData();
        }
    };

    const getSelectedMessage = useCallback(() => {
        return messages.find((message) => message.id === selectedMessageId);
    }, [messages, selectedMessageId]);

    return (
        <>
            <PageHeader title={t("Messages")}/>
            <PageContainer>
                <AlertDanger errorMessages={[error]}/>
                <div className="flex flex-col gap-6 md:flex-row">
                    <Loading type={"cover"} loading={isLoading}>
                        <DataTable columns={columns}
                                   extraClassName={"border-x-2 border-black-10 select-node w-full md:w-[640px]"}
                                   onReload={handleOnReload}
                                   selectedItem={getSelectedMessage()}
                                   allowSelection={false}
                                   pageSize={pageSize}
                                   currentPage={currentPage}
                                   totalCount={totalCount}
                                   items={messages}
                                   onItemSelected={(item) => handleClickMessage(item)}
                                   onDoubleClick={(item) => handleDoubleClickMessage(item)}
                                   renderMobileRow={(columns, item) => renderMobileRow(item)}
                                   showPagination={true}
                                   showHeader={true}
                        />
                    </Loading>
                    <div
                        className="hidden h-50 w-full flex-grow rounded-2xl border-2 border-black-10 bg-black-secondary-strong p-6 md:block md:w-[640px]">
                        {!selectedMessageId && <div className="flex flex-col items-center justify-center h-full">
                            {t("NoMessageSelected")}
                        </div>}
                        <Loading type={"cover"} loading={isLoading}>
                            {selectedMessageId &&
                                <>
                                    <div
                                        className="flex flex-row items-center justify-between pb-4 font-myriad text-sm font-regular text-black-40">
                                        <div className="font-myriad text-sm font-regular text-black-40">
                                            {messageDate ? formatDate(messageDate, defaultDateFormat) : t("NoDate")}
                                        </div>
                                        <div className="font-myriad text-sm font-regular text-black-40">
                                            {messageDate ? formatTime(messageDate) : t("NoTime")}
                                        </div>
                                    </div>
                                    {messageEmployeeTaskDescription &&
                                        <div className="pb-2 font-myriad text-sm font-regular text-black-primary">
                                            {`${t("Task: ")} `} <a href={"#"}
                                                                   className={"underline cursor-pointer text-dark-green"}
                                                                   onClick={handleShowTaskModal}>{messageEmployeeTaskDescription}</a>
                                        </div>}
                                    <div className="pb-2 font-myriad text-sm font-regular text-black-primary">
                                        {t("Message")}
                                    </div>
                                    <div className="font-myriad text-sm font-regular text-black-40">
                                        {t(messageText)}
                                    </div>
                                </>}
                        </Loading>
                    </div>
                </div>
                {(showMessageModal && selectedMessageId) && (
                    <MessageModal message={getSelectedMessage()} onClose={() => setShowMessageModal(false)}
                                  onOpenEmployeeTask={handleShowTaskModal}/>
                )}
                {showFilterModal && (
                    <MessageFilterModal settings={filterSettings} onClose={() => setShowFilterModal(false)}
                                        onApply={handleApplyFilters}/>
                )}
            </PageContainer>

            {showTaskModal && (
                <TasksDetailModal
                    taskId={messageEmployeeTaskId}
                    onClose={() => setShowTaskModal(false)}
                />)}
        </>
    );
};

export default Messages;
