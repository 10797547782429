import {FormProvider, useForm} from "react-hook-form"
import {v4 as uuidv4} from 'uuid';
import {t} from "i18next"

import BaseModal from "../../../components/Modals/BaseModal"
import BaseModalContent from "../../../components/Modals/BaseModalContent"
import BaseModalHeader from "../../../components/Modals/BaseModalHeader"
import Loading from "../../../components/Loading/Loading"
import BaseModalButtons from "../../../components/Modals/BaseModalButtons"
import useFetchEmployees from "../../../hooks/useFetchEmployees";
import React, {useCallback, useEffect} from "react";
import FormTextInput from "../../../components/FormControls/FormTextInput";
import FormSelectControl from "../../../components/FormControls/FormSelectControl";
import {CreateEmployeeDto, EmployeeDto} from "../../../dtos/employees/EmployeeDto.ts";
import useFetchData from "../../../hooks/useFetchData.ts";
import toast from "react-hot-toast";
import {CreateDepartmentDto, DepartmentDto} from "../../../dtos/departments/DepartmentDto.ts";
import {FormHelper} from "../../../utils/FormHelper.ts";
import AlertDanger from "../../../components/Alerts/AlertDanger.tsx";

type Props = {
    onClose: () => void;
    onChanged: () => void;
    employeeId?: string | undefined;
}

type EditEmployeeFormModel = {
    id?: string,
    userName: string,
    email: string,
    phoneNumber: string,
    fullName: string,
    department?: SelectControlOption<string>,
    userType: SelectControlOption<string>,
}

export const EditEmployeeModal: React.FC<Props> = ({
                                                       onClose,
                                                       employeeId,
                                                       onChanged,
                                                   }) => {
    const {
        createItem,
        updateItem,
        item,
        fetchItem,
        error: usersError,
        isLoading,
        isSubmitting
    } = useFetchData<CreateEmployeeDto, EmployeeDto, EmployeeDto>("Users");

    const {
        itemOptions: departments,
        fetchItems: fetchDepartments,
        isLoading: isLoadingDepartments,
        error: departmentsError
    } = useFetchData<CreateDepartmentDto, DepartmentDto, DepartmentDto>("Departments", "description", "id");

    const isBusy = isLoading || isLoadingDepartments || isSubmitting;

    const userTypeOptions: SelectControlOption<string>[] = [
        {value: "ADMIN", label: t("Admin")},
        {value: "SUPERVISOR", label: t("Supervisor")},
        {value: "EMPLOYEE", label: t("Employee")},
    ];

    const handleAddOrUpdateEmployee = useCallback(async (data: EditEmployeeFormModel) => {
        if (!employeeId) {
            const newEmployee: CreateEmployeeDto = {
                userName: data.userName,
                email: data.email,
                fullName: data.fullName,
                departmentId: data.department?.value,
                userType: data.userType.value,
                phoneNumber: data.phoneNumber
            };
            await createItem(newEmployee);
        } else {
            const updatedEmployee: EmployeeDto = {
                id: employeeId,
                userName: data.userName,
                email: data.email,
                fullName: data.fullName,
                departmentId: data.department?.value,
                userType: data.userType.value,
                phoneNumber: data.phoneNumber
            };
            await updateItem(employeeId, updatedEmployee);
        }

        onChanged();
        toast.success(t("EmployeeSavedSuccessfully"));
    }, [employeeId, onChanged]);

    const hookForm = useForm<EditEmployeeFormModel>();
    const {reset, handleSubmit} = hookForm;

    const fetchData = useCallback(async () => {
        const departments = await fetchDepartments();

        if (employeeId) {
            const employee = await fetchItem(employeeId);
            if (employee) {
                reset({
                    id: employee.id,
                    userName: employee.userName,
                    fullName: employee.fullName,
                    email: employee.email,
                    department: FormHelper.getSelectedOption<string>(employee, departments, "departmentId", "description"),
                    userType: userTypeOptions.find(u => u.value === employee.userType),
                    phoneNumber: employee.phoneNumber
                });
            }
        }
    }, [employeeId]);

    useEffect(() => {
        fetchData();
    }, [employeeId]);


    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={!employeeId ? (t("AddEmployee")) : (t("EditEmployee"))}
                                     onClose={onClose}/>
                    <AlertDanger errorMessages={[usersError, departmentsError]}/>
                    <Loading
                        loading={isBusy}
                        type={"cover"}
                    >
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="userName"
                                type="text"
                                label={t("UserName")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                        <div className="mt-2">
                            <FormSelectControl
                                options={departments}
                                fieldName="department"
                                label={t("Department")}
                                isRequired={false}
                            />
                        </div>
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="fullName"
                                type="text"
                                label={t("FullName")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="email"
                                type="text"
                                label={t("Email")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="phoneNumber"
                                type="text"
                                label={t("PhoneNumber")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                        <div className="mt-2">
                            <FormSelectControl
                                options={userTypeOptions}
                                fieldName="userType"
                                label={t("UserType")}
                                isRequired={true}
                            />
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Save")}
                    showCloseButton={false}
                    onSave={handleSubmit(handleAddOrUpdateEmployee)}
                    saveButtonDisabled={isBusy}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    )
}