import { animated, useSpring } from "react-spring";
import usflag from "../../../assets/png/flags/Flag US/Flag=US.png";
import kaflag from "../../../assets/png/flags/Flag Geo/Flag=GE.png";
import ruflag from "../../../assets/png/flags/Flag Ru/Flag=RU.png";
import CheckIcon from "../../../assets/svg/sidebarIcons/CheckIcon";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hooks";
import { RootState } from "../../../redux/store";
import {
  selectFlag,
  setIsDropdownOpen,
} from "../../../redux/slices/LanguageDropdownSlice";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { useRef } from "react";

type Props = {
  showText?: boolean;
};

const MobileLanguageDropdown = ({ showText }: Props) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const dropdownRef = useRef<HTMLDivElement>(null);

  const flags = [
    { id: "us", flag: usflag, text: "EN", fullText: "English" },
    { id: "ka", flag: kaflag, text: "GE", fullText: "Georgian" },
    { id: "ru", flag: ruflag, text: "RU", fullText: "Russian" },
  ];

  const isDropdownOpen = useAppSelector(
    (state: RootState) => state.languageDropdown.isDropdownOpen,
  );
  const selectedFlag = useAppSelector(
    (state: RootState) => state.languageDropdown.selectedFlag,
  );

  const toggleDropdown = () => {
    dispatch(setIsDropdownOpen(!isDropdownOpen));
  };

  const handleSelectFlag = (flag: string, language: string) => {
    dispatch(selectFlag({ flag, language }));
    console.log("click");
  };

  const animationStyles = useSpring({
    opacity: isDropdownOpen ? 1 : 0,
    transform: isDropdownOpen ? "translateY(0%)" : "translateY(100%)",
    config: { duration: 300 },
  });

  return (
    <div
      className={`${isDropdownOpen && isMobile ? "dropdown-overlay" : ""}`}
      ref={dropdownRef}
      onClick={toggleDropdown}
    >
      <animated.div
        className="slide-modal-dropdown top-container block h-auto w-full rounded-tl-2xl	rounded-tr-2xl bg-black-secondary-strong p-[16px] md:hidden"
        style={animationStyles}
      >
        {flags.map((flag) => (
          <div
            key={flag.id}
            className={`flex w-full cursor-pointer flex-row items-center justify-between ${showText ? "px-[8px]" : "px-[10px]"} py-[10px] hover:rounded-md hover:bg-black-0 ${selectedFlag === flag.flag ? "rounded-md bg-dark-green-10" : ""}  z-50`}
            onClick={() => handleSelectFlag(flag.flag, flag.fullText)}
          >
            <div className="z-50 flex flex-row items-center gap-[10px]">
              <img src={flag.flag} alt="flag" />
              {flag.fullText}
            </div>
            {selectedFlag === flag.text.toLowerCase() && <CheckIcon />}

          </div>
        ))}
      </animated.div>
    </div>
  );
};

export default MobileLanguageDropdown;
