import svgLogo from "../../../assets/svg/logo/newLogo.svg";
import HamburgerMenu from "../../../assets/svg/sidebarIcons/HamburgerMenu";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/hooks";
import {setIsOpen} from "../../../redux/slices/SideBarSlice";
import LanguagesDropdown from "../../DropDowns/languagesDropdown/LanguagesDropdown";
import notificationIcon from "../UserProfile/OwnerNotifications/res/Notification.svg";
import React, {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useFetchMessages from "../../../hooks/useFetchMessages.ts";
import {selectNotificationsRefreshIndex} from "../../../redux/slices/appSlice.ts";

const HeaderMobile = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        unreadCount,
        messages: rawMessages,
        fetchUnreadMessagesCount,
        fetchMessages,
        isLoading,
        error
    } = useFetchMessages();
    const notificationsRefreshIndexer = useAppSelector(selectNotificationsRefreshIndex);

    const fetchData = useCallback(async () => {
        await fetchUnreadMessagesCount();
        await fetchMessages(undefined, undefined, undefined, 0, 5, false);
    }, [notificationsRefreshIndexer]);

    useEffect(() => {
        fetchData();
    }, [notificationsRefreshIndexer]);
    
    const sidebarHandler = (event: any) => {
        event.stopPropagation();
        dispatch(setIsOpen(true));
    };
    const handleOpenMessages = () => {
        navigate("/messages");
    };

    return (
        <div className="flex flex-row items-center justify-between border-b-[1px] border-black-10 p-4">
            <div
                // className={`svg-container ${sidebarIsOpen ? "opacity-0" : "opacity-100"}`}
                className={`svg-container`}
                onClick={sidebarHandler}
            >
                <HamburgerMenu/>
            </div>
            <img
                src={svgLogo}
                width={128.54}
                height={40}
                alt="logo"
                draggable="false"
            />
            <div className={"flex"}>
                <div className="relative">
                    <div
                        className="flex h-[40px] w-[40px] cursor-pointer flex-col items-center"
                        onClick={handleOpenMessages}
                    >
                        {unreadCount === 0 && <img src={notificationIcon} className="p-2.5" alt="notification"/>}
                        {unreadCount > 0 && <span className="relative inline-block">                    
                    <img src={notificationIcon} className="p-2.5" alt="notification"/>
                    <span
                        className="animate-bounce absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-2/2 -translate-y-2/2 bg-dark-green rounded-full">
                        {unreadCount < 100 ? unreadCount : "99+"}
                    </span>
                    </span>
                        }
                    </div>
                </div>
                <LanguagesDropdown
                    showIcon={true}
                    showText={true}
                    dropdownTop={false}
                    showFullText={false}
                />
            </div>
        </div>
    );
};

export default HeaderMobile;
