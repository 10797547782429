import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

import MainButton from "../../components/buttons/MainButton.tsx";
import FormTextInput from "../../components/inputs/FormTextInput";
import leftArrow from "../../assets/svg/arrow-left.svg";
import email from "../../assets/svg/LoginIcons/mail-at-sign-02.svg";
import { useState } from "react";
import { t } from "i18next";

const LoginScheme = z.object({
  email: z.string().min(1, t("Email is needed")).email(),
});

type FormData = z.infer<typeof LoginScheme>;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [emailIsSent, setEmailIsSent] = useState(true);
  const { handleSubmit, formState, ...methods } = useForm<FormData>({
    resolver: zodResolver(LoginScheme),
  });

  const submitHandler = () => {
    setEmailIsSent(!emailIsSent);
  };

  const gobackHandler = () => {
    navigate("/");
  };

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      formState={formState}
      {...methods}
    >
      <div className="flex h-[356px] w-[392px] flex-col items-center justify-center rounded-2xl bg-black-secondary-strong px-8 py-14">
        {emailIsSent ? (
          <div>
            <div className="flex w-full items-center justify-start pb-6">
              <img
                src={leftArrow}
                alt="left-arrow"
                className="me-[12px] hover:cursor-pointer"
                onClick={gobackHandler}
              />
              <div className="text-center font-myriad text-xl font-regular text-black-primary">
                {t("ForgotPassword?")}
              </div>
            </div>
            <div className="tex-sm pb-6 text-black-40">
              {t("EnterEmailAddressAssociatedWithYourPersonalCabinetAndWeWillSendYouNewPasswordYouCanAlwaysChangePasswordLater")}
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormTextInput fieldName={"email"} placeholder="email" />
              <MainButton
                title={t("SendNewPassword")}
                className="btn w-full bg-dark-green text-black-secondary-strong hover:bg-dark-green-80"
              />
            </form>
          </div>
        ) : (
          <div className="flex w-full flex-col items-center">
            <div className="pb-[16px]">
              <img src={email} alt="email-logo" />
            </div>
            <div className="pb-[16px] text-center font-myriad text-xl font-regular  text-black-primary">
              {t("PasswordSent")}
            </div>
            <div className="tex-sm pb-6 text-center text-black-40">
              {t("NewPasswordHasBeenSentToTheProvidedEmailSuccessfully!")}
            </div>
            <div className="flex items-center justify-center">
              <MainButton
                title={t("OpenMyEmail")}
                className="btn w-full bg-transparent text-dark-green hover:bg-dark-green-10"
                onClick={submitHandler}
              />
            </div>
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default ForgotPassword;
