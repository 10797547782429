import {useState} from "react";
import {axiosInstance} from "../config/appConfig.ts";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto.ts";
import UserDto from "../dtos/users/UserDto.ts";
import {UrlHelper} from "../helpers/UrlHelper.ts";

const useFetchUsers = () => {
    const [users, setUsers] = useState<UserDto[]>([]);
    const [employees, setEmployees] = useState<UserDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const usersOptions: SelectControlOption<string>[] = users.map((user) => ({
        value: user.id,
        label: user.fullName
    })) || [];

    const employeesOptions: SelectControlOption<string>[] = employees.map((employee) => ({
        value: employee.id,
        label: employee.fullName
    })) || [];

    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            setError(undefined);

            const response = await axiosInstance.get<ListResponseDto<UserDto>>("Users");
            setUsers(response.data.items);

        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchEmployees = async (departmentId?: string) => {
        try {
            setIsLoading(true);
            setError(undefined);

            const url = UrlHelper.generateUrlWithParams("Users", {
                employeeRole: "Employee",
                departmentId,
            });
            
            const response = await axiosInstance.get<ListResponseDto<UserDto>>(url);
            setEmployees(response.data.items);

            return response.data.items;
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return {
        users,
        isLoading,
        error,
        fetchUsers,
        fetchEmployees,
        usersOptions,
        employees,
        employeesOptions
    };
};

export default useFetchUsers;
