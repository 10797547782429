import React, {FC, PropsWithChildren} from 'react';
import UserIcon from "../../../../assets/svg/Profile-picture.svg";
import AccountMenuItem from "./AccountMenuItem.tsx";
import {useNavigate} from "react-router-dom";
import FAQIcon from "./res/FAQIcon.tsx";
import {
    doLogout,
    selectAuthUserDetails,
    selectIsSupervisor,
    selectUserType
} from "../../../../redux/slices/authSlice.ts";
import LogOutIcon from "./res/LogOutIcon.tsx";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks.ts";
import MainIcon from "../../UserProfile/res/user-profile-picture.png";
import SettingsIcon from "./res/SettingsIcon.tsx";
import OwnerUserIcon from "./res/OwnerUserIcon.tsx";

type Props = {}

const ownerId = "c0219f47-b4bf-4e71-9e9d-4f824b75fa01"; // for the owner it doesn't matter what id is used, it will always return only owner records

const AccountMenu: FC<PropsWithChildren<Props>> = ({}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isOwner = useAppSelector(selectIsSupervisor);
    const userDetails = useAppSelector(selectAuthUserDetails);
    const userType = useAppSelector(selectUserType);

    const handleCloseDropdown = () => {
        const elem: any = document.activeElement;
        if (elem) {
            elem?.blur();
        }
    }

    const handleMoveToPage = (url: string) => {
        handleCloseDropdown();
        navigate(url);
    }

    const handleLogout = () => {
        handleCloseDropdown();
        console.log("Logging out");
        dispatch(doLogout());
        navigate("/");
    }

    return (
        <>
            <div className="dropdown dropdown-bottom dropdown-end">
                <div tabIndex={0} role="button" className="m-1 cursor-pointer hover:bg-black-10 rounded-full">
                    <img
                        src={UserIcon} className="p-2.5" alt="icon"/>
                </div>
                <div tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-black-secondary-strong rounded-box">
                    <div
                        className="flex h-[164px] w-[240px] flex-col items-center gap-[10px] border-b-[1px] border-black-10 px-4 py-8">
                        <img src={userDetails?.avatarUrl ? userDetails?.avatarUrl : MainIcon} width={48} height={48} alt="icon"/>
                        <div className="flex h-[42px] flex-col justify-between">
                            <div
                                className="flex h-[20px] items-center justify-center font-myriad text-base font-regular text-black-primary">
                                {userDetails?.fullName}
                            </div>
                            <div
                                className="flex h-[20px] items-center justify-center font-myriad text-sm font-regular text-black-60">
                                <span>{userType && t(userType)}</span>
                            </div>
                        </div>
                    </div>
                    <ul>
                        {/*{isOwner && (*/}
                        {/*    <AccountMenuItem label={t("MyAccount")} icon={<OwnerUserIcon/>}*/}
                        {/*                     onClick={() => handleMoveToPage(`/owners/${ownerId}`)}/>*/}
                        {/*)}*/}
                        <AccountMenuItem label={t("Settings")} icon={<SettingsIcon/>}
                                         onClick={() => handleMoveToPage("/settings")}/>
                        <AccountMenuItem label={t("Log out")} icon={<LogOutIcon/>} onClick={handleLogout}/>
                    </ul>
                </div>
            </div>

        </>
    );
};

export default AccountMenu;