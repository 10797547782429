import {t} from "i18next";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageContainer from "../../components/PageContainer/PageContainer";
import DataTable from "../../components/DataTable/DataTable";
import Loading from "../../components/Loading/Loading";
import {useCallback, useEffect, useState} from "react";
import AlertDanger from "../../components/Alerts/AlertDanger";
import PlusIcon from "../../assets/svg/buttonIcons/PlusIcon";
import MainButton from "../../components/buttons/MainButton";
import TableEditIcon from "../../assets/svg/TableEditIcon";
import DeleteItemIcon from "../../assets/svg/DeleteItemIcon";
import {CreateDepartmentDto, DepartmentDto} from "../../dtos/departments/DepartmentDto";
import useFetchData from "../../hooks/useFetchData";
import QuestionModal from "../../components/QuestionModal/QuestionModal";
import {TaskSourceDto} from "../../dtos/taskSource/TaskSourceDto";
import {EditTaskTypeModal} from "./modal/EditTaskTypeModal";
import SearchInput from "../../components/inputs/SearchInput.tsx";

const TaskTypePage = () => {
    const {
        items: taskTypes,
        error,
        deleteItem,
        fetchItems,
        isLoading,
        totalCount,
    } = useFetchData<CreateDepartmentDto, DepartmentDto, DepartmentDto>("EmployeeTaskTypes")

    const [selectedTaskTypeId, setSelectedTaskTypeId] = useState<string | undefined>(undefined);
    const [showTaskTypeDeleteQuestionModal, setShowTaskTypeDeleteQuestionModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState<string>("");

    const columns: DataTableColumn[] = [
        {
            title: t("TaskTypeName"),
            field: "description"
        },
        {
            title: t("Code"),
            field: "id"
        },
        {
            title: t("Actions"),
            field: "actions",
            render: (value: any) => (
                <div className="flex gap-2">
                    <button onClick={() => handleEditTaskType(value)}><TableEditIcon/></button>
                    <button onClick={() => handleDeleteTaskType(value)}><DeleteItemIcon/></button>
                </div>
            )
        }
    ]

    const renderMobileRow = (columns: DataTableColumn[], item: any) => {
        return (
            <>
                <div className={"flex flex-row"}>
                    <div className={"flex justify-between w-full ps-4 pe-2 font-myriad text-xs gap-3 text-black-60"}>
                        <div>{t(item.description)}</div>
                        <div>{item.id}</div>
                        <div>
                            <div className={`flex gap-1 ml-2`}>
                                <button onClick={() => handleEditTaskType(item.id)}><TableEditIcon/></button>
                                <button onClick={() => handleDeleteTaskType(item.id)}><DeleteItemIcon/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const handleOnReload = (newPageSize, newPageNumber) => {
        setPageSize(newPageSize);
        setPageNumber(newPageNumber);
    };
    const handleCloseModal = async () => {
        setShowEditModal(false);
    }

    const handleTaskTypeUpdate = async () => {
        setShowEditModal(false);
        await fetchData();
    };

    const handleNewTaskType = () => {
        setSelectedTaskTypeId(undefined);
        setShowEditModal(true);
    };

    const handleEditTaskType = (department: DepartmentDto) => {
        setSelectedTaskTypeId(department.id);
        setShowEditModal(true);
    };

    const handleDeleteTaskType = async (taskSource: TaskSourceDto) => {
        setSelectedTaskTypeId(taskSource.id);
        setShowTaskTypeDeleteQuestionModal(true);
    };

    const handleDeleteTaskTypeConfirmed = async () => {
        setShowTaskTypeDeleteQuestionModal(false);
        if (!selectedTaskTypeId) return;

        await deleteItem(selectedTaskTypeId);
        await fetchData();
    };

    const fetchData = useCallback(async () => {
        await fetchItems(searchText, (pageNumber - 1) * pageSize, pageSize);
    }, [pageSize, pageNumber, searchText]);

    useEffect(() => {
        fetchData();
    }, [pageSize, pageNumber, searchText])

    return (
        <PageContainer>
            <PageHeader title={t("TaskType")}>
                <div className={"flex gap-2"}>
                    <SearchInput searchText={searchText} onSearchTextChanged={setSearchText}/>
                    <MainButton
                        disabled={isLoading}
                        title={t("NewType")}
                        onClick={handleNewTaskType}
                        icon={<PlusIcon/>}
                    />
                </div>
            </PageHeader>
            {error && <AlertDanger errorMessages={[error]}/>}
            <Loading loading={isLoading}>
                <div className={"mt-2"}>
                    <DataTable
                        items={taskTypes}
                        columns={columns}
                        pageSize={pageSize}
                        currentPage={pageNumber}
                        totalCount={totalCount}
                        onReload={handleOnReload}
                        // renderMobileRow={renderMobileRow}
                    />
                </div>
            </Loading>
            {showEditModal && (
                <EditTaskTypeModal
                    onClose={handleCloseModal}
                    onChanged={() => handleTaskTypeUpdate()}
                    taskTypeId={selectedTaskTypeId}
                />
            )}

            {showTaskTypeDeleteQuestionModal && (
                <QuestionModal onClose={() => setShowTaskTypeDeleteQuestionModal(false)}
                               onYes={handleDeleteTaskTypeConfirmed}
                               title={"AreYouSureYouWantToDeleteThisType"}
                               content={"ThisActionCannotBeUndone"}
                               icon={<DeleteItemIcon/>} type={"danger"}
                               yesButtonText={"Yes"}
                               noButtonText={"No"}/>
            )}
        </PageContainer>
    );
};

export default TaskTypePage;
