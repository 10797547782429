const LeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="12"
      viewBox="0 0 26 12"
      fill="none"
    >
      <path
        d="M1 6H25"
        stroke="#667B8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99996 11C5.99996 11 1.00001 7.31756 1 5.99996C0.999989 4.68237 6 1 6 1"
        stroke="#667B8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeftArrow;
