import {useDetectClickOutside} from "react-detect-click-outside";

import usflag from "../../../assets/png/flags/Flag US/Flag=US.png";
import kaflag from "../../../assets/png/flags/Flag Geo/Flag=GE.png";
import ruflag from "../../../assets/png/flags/Flag Ru/Flag=RU.png";
import globe from "../../../assets/png/flags/globe.png";

import CheckIcon from "../../../assets/svg/sidebarIcons/CheckIcon";
import ArrowDownIcon from "../../../assets/svg/sidebarIcons/ArrowDownIcon";
import "./LanguageDropdown.css";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/hooks";
import {
    selectFlag,
    setIsDropdownOpen,
} from "../../../redux/slices/LanguageDropdownSlice";
import {RootState} from "../../../redux/store";
import useMediaQuery from "../../../hooks/useMediaQuery";
import {useTranslation} from "react-i18next";
import BaseModal from "../../Modals/BaseModal.tsx";
import {useEffect, useRef, useState} from "react";
import { selectAppCurrency, setAppCurrency } from "../../../redux/slices/appSlice.ts";
import { Currency, CurrencyOption } from "../../../types/Currency.ts";
import { GelIcon } from "../../../assets/svg/GEL_Icon.tsx";
import { UsdIcon } from "../../../assets/svg/USD_Icon.tsx";

type Props = {
    showIcon?: boolean;
    dropdownTop?: boolean;
    showText?: boolean;
    showFullText?: boolean;
    isLoginPage?: boolean;
    isOpen?: boolean;
};

const languageDropdownOptions = [
  "Language",
  "Currency"
];

const currencyOptions: CurrencyOption[] = [
  {
    id: 1,
    type: "USD",
  },
  {
    id: 2,
    type: "GEL",
  }
];

const LanguagesDropdown = ({
                               showIcon,
                               dropdownTop,
                               showText,
                               showFullText,
                               isLoginPage = true,
                               isOpen = false,
                           }: Props) => {
    const {t, i18n} = useTranslation();
    const {language: selectedLanguage} = i18n;
   
    const isMobile = useMediaQuery("(max-width: 400px)");
    const [showLanguageSelectorPanel, setShowLanguageSelectorPanel] = useState(false);

    const closeDropdown = () => {
        setShowLanguageSelectorPanel(false);
    };


    const flags = [
        {id: "en", flag: usflag, text: "EN", fullText: "English"},
        {id: "ka", flag: kaflag, text: "GE", fullText: "Georgian"},
        {id: "ru", flag: ruflag, text: "RU", fullText: "Russian"},
    ];


    const handleSelectFlag = async (flag: string, language: string) => {
        await i18n.changeLanguage(language);
        console.log(flag);
        console.log(language);
    };

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setShowLanguageSelectorPanel(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref.current]);

    const renderMobileVersion = () => {
      const [selectedOption, setSelectedOption] = useState("Language");

      const dispatch = useAppDispatch();
      const appCurrency = useAppSelector(selectAppCurrency);

      const handleChangeDropdownOption = (option: string) => {
        setSelectedOption(option);
      };

      const handleChangeCurrency = (currency: Currency) => {
        dispatch(setAppCurrency(currency));
      };

      return (
        <>
          {showLanguageSelectorPanel && (
            <BaseModal isForModal={false}>
              <div
                className="flex h-full sm:w-[300px] w-full flex-col justify-between"
                ref={ref}
              >
                {selectedOption === "Language" ? (
                  <div className="flex h-full w-full cursor-pointer flex-col items-start justify-between">
                    {flags.map((flag) => (
                      <div
                        key={flag.id}
                        className={`
                          flex w-full cursor-pointer flex-row items-center justify-between duration-300 p-3
                          py-[10px] px-[20px] hover:rounded-md hover:bg-black-0
                          ${selectedLanguage === flag.id ? "rounded-md bg-dark-green-10" : ""}
                        `}
                        onClick={() => handleSelectFlag(flag.flag, flag.id)}
                      >
                        <div className="z-50 flex flex-row items-center gap-[10px]">
                          <img src={flag.flag} alt="flag" />
                          {showText && <>{flag.text}</>}
                          {dropdownTop && <>{flag.fullText}</>}
                        </div>
                        {selectedLanguage === flag.id && <CheckIcon />}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex w-full cursor-pointer flex-col items-start justify-between">
                    {currencyOptions.map((currency, index) => (
                      <div
                        key={index}
                        className={`
                        flex w-full cursor-pointer flex-row items-center justify-between duration-300 p-3
                        py-[10px] px-[20px] hover:rounded-md hover:bg-black-0
                          ${appCurrency === currency.type ? "rounded-md bg-dark-green-10" : ""}  
                        `}
                        onClick={() => handleChangeCurrency(currency.type)}
                      >
                        <div className="flex items-center gap-1">
                          {currency.type === "GEL" ? <GelIcon /> : <UsdIcon />}
                          <div>{currency.type}</div>
                        </div>
                        <div className="flex items-center">
                          {appCurrency === currency.type && <CheckIcon />}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </BaseModal>
          )}
        </>
      );
    };

    // const renderDesktopVersion = () => {
    //     return (
    //         <>
    //             <div
    //                 className={`top-container absolute ${isLoginPage ? "flex w-full flex-col items-start justify-between" : "flex h-[136px] w-[208px] flex-col items-center justify-end  "} rounded-md bg-black-secondary-strong  shadow ${dropdownTop ? "bottom-full mb-2" : "top-full mb-2"} ${isOpen ? "left-0" : ""} hidden md:block`}
    //             >
    //                 {flags.map((flag) => (
    //                     <div
    //                         key={flag.id}
    //                         className={`flex w-full cursor-pointer flex-row items-center justify-between ${showText ? "px-[8px]" : "px-[10px]"} py-[10px] hover:rounded-md hover:bg-black-0 ${selectedLanguage === flag.id ? "rounded-md bg-dark-green-10" : ""}  z-50`}
    //                         onClick={() => handleSelectFlag(flag.flag, flag.id)}
    //                     >
    //                         <div className="z-50 flex flex-row items-center gap-[10px]">
    //                             <img src={flag.flag} alt="flag"/>
    //                             {showText && <>{flag.text}</>}
    //                             {dropdownTop && <>{flag.fullText}</>}
    //                         </div>
    //                         {selectedLanguage === flag.id && dropdownTop && <CheckIcon/>}
    //                     </div>
    //                 ))}
    //             </div>
    //         </>
    //     )
    // }

    const getFlagByLanguage = (language: string) => {
        switch (language) {
            case "us":
                return usflag;
            case "ka":
                return kaflag;
            case "ru":
                return ruflag;
            default:
                return usflag;
        }
    }

    return (
        <>
            <div onClick={() => setShowLanguageSelectorPanel(true)} className={"flex p-2 cursor-pointer hover:bg-black-10 hover:rounded-lg"}>
                <img
                    src={getFlagByLanguage(selectedLanguage)}
                    alt="Selected flag"
                />
                {renderMobileVersion()}
            </div>
        </>
    );
};

export default LanguagesDropdown;
