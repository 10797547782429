import {useState} from "react";
import {UrlHelper} from "../helpers/UrlHelper.ts";
import {axiosInstance} from "../config/appConfig.ts";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto.ts";
import DetailsResponseDto from "../dtos/BaseResponse/DetailsResponseDto.ts";

const useFetchData = <TCreate, TDto, TListDto>(baseUrl: string, fieldLabel: string | undefined = "id", fieldValue: string | undefined = "id") => {
    const [items, setItems] = useState<TListDto[] | undefined>(undefined);
    const [item, setItem] = useState<TDto | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [totalCount, setTotalCount] = useState<number>(0);

    const itemOptions: SelectControlOption<TListDto>[] = items?.map(a => ({
        label: (a as any)[fieldLabel] ?? "",
        value: (a as any)[fieldValue] ?? ""
    })) ?? [];

    const fetchItems = async (searchText?: string, skip?: number, take?: number,
                              params?: {
                                  [key: string]: string | number | boolean | Date | null | undefined | (string | number | boolean | Date)[]
                              }): Promise<TListDto[] | undefined> => {
        try {
            setIsLoading(true);
            setError(undefined);

            const url = UrlHelper.generateUrlWithParams(baseUrl, {
                searchText,
                skip,
                take,
                ...params,
            });

            const response = await axiosInstance.get<ListResponseDto<TListDto>>(url);
            setItems(response.data.items);
            setTotalCount(response.data.totalCount);
            return response.data.items;
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        } finally {
            setIsLoading(false);
        }

        return undefined;
    };

    const fetchItem = async (id: string): Promise<TDto | undefined> => {
        try {
            setIsLoading(true);
            setError(undefined);
            const url = `${baseUrl}/${id}`;
            const response = await axiosInstance.get<DetailsResponseDto<TDto>>(url, {
                validateStatus: (status) => status < 500
            });
            if (response.data?.success !== true) {
                setError(response.data.errorMessage);
                return undefined;
            }
            setItem(response.data.item);
            return response.data.item;
        } catch (e: any) {
            setError(e.message);
        } finally {
            setIsLoading(false);
        }

        return undefined;
    }

    const createItem = async (item: TCreate) => {
        try {
            setIsSubmitting(true);
            setError(undefined);

            const response = await axiosInstance.post<TDto>(baseUrl, item);
            setItem(response.data);
        } catch (e: any) {
            setError(e.message);
            console.log(e);
            throw e;
        } finally {
            setIsSubmitting(false);
        }
    }

    const updateItem = async (id: string, item: TDto) => {
        try {
            setIsSubmitting(true);
            setError(undefined);

            const response = await axiosInstance.put<TDto>(`${baseUrl}/${id}`, item);
            setItem(response.data);
        } catch (e: any) {
            setError(e.message);
            console.log(e);
            throw e;
        } finally {
            setIsSubmitting(false);
        }
    }

    const deleteItem = async (id: string) => {
        try {
            setIsSubmitting(true);
            setError(undefined);

            await axiosInstance.delete(`${baseUrl}/${id}`);
            setItem(undefined);
        } catch (e: any) {
            setError(e.message);
            console.log(e);
            throw e;
        } finally {
            setIsSubmitting(false);
        }
    }

    return {
        fetchItems,
        fetchItem,
        createItem,
        updateItem,
        deleteItem,
        items,
        item,
        isLoading,
        isSubmitting,
        error,
        totalCount,
        itemOptions
    };
}

export default useFetchData;