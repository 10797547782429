import {v4 as uuidv4} from 'uuid';
import {t} from "i18next";

import useFetchEmployeeTasks from "../../../hooks/useFetchEmployeeTasks.ts";
import {Control, FormProvider, useForm, useWatch} from "react-hook-form";
import BaseModal from "../../../components/Modals/BaseModal";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import Loading from "../../../components/Loading/Loading";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import React, {useCallback, useEffect} from 'react';
import FormDateInput from '../../../components/FormControls/FormDateInput';
import AlertDanger from "../../../components/Alerts/AlertDanger.tsx";
import FormSelectControl from '../../../components/FormControls/FormSelectControl.tsx';
import useFetchApartments from "../../../hooks/useFetchApartments.ts";
import useFetchTaskTypes from "../../../hooks/useFetchTaskTypes.ts";
import useFetchTaskSources from "../../../hooks/useFetchTaskSources.ts";
import useFetchUsers from "../../../hooks/useFetchUsers.ts";
import useFetchData from "../../../hooks/useFetchData.ts";
import CreateEmployeeTaskDto from '../../../dtos/EmployeeTask/CreateEmployeeTaskDto.ts';
import useFetchDepartment from '../../../hooks/useFetchDepartments.ts';
import {toast} from 'react-hot-toast';
import EmployeeTaskDto from '../../../dtos/EmployeeTask/EmployeeTaskDto.ts';
import {FormHelper} from '../../../utils/FormHelper.ts';
import {selectAuthUserDetails, selectIsAdmin} from "../../../redux/slices/authSlice.ts";
import {useAppSelector} from "../../../redux/hooks/hooks.ts";
import {EnumHelper} from "../../../helpers/enumHelper.tsx";
import {EmployeeTaskStatusEnum} from "../../../enums/EmployeeTaskStatusEnum.ts";
import {addDays, addMinutes} from "date-fns";
import {CreatePredefinedTaskDto, PredefinedTaskDto} from "../../../dtos/predefinedTasks/PredefinedTaskDto.ts";
import FormTextAreaInput from "../../../components/FormControls/FormTextAreaInput.tsx";


type Props = {
    onClose: () => void;
    onChanged: () => void;
    taskId: string;
}

type EditTaskFormModel = {
    id?: string,
    code: number;
    source: SelectControlOption<string>;
    type: SelectControlOption<string>;
    assignedEmployee?: SelectControlOption<string>;
    apartment?: SelectControlOption<string>;
    predefinedDescription?: SelectControlOption<string>;
    department?: SelectControlOption<string>;
    location?: SelectControlOption<string>;
    deadline: Date;
    availableFrom: Date;
    status?: SelectControlOption<EmployeeTaskStatusEnum>;
    remarks?: string;
};

export const EditTaskModal: React.FC<Props> = ({
                                                   onClose,
                                                   onChanged,
                                                   taskId,
                                               }) => {

    const {validationErrors} = useFetchEmployeeTasks();
    const isAdmin = useAppSelector(selectIsAdmin);
    const userDetails = useAppSelector(selectAuthUserDetails);

    const {
        isLoading,
        isSubmitting,
        createItem,
        updateItem,
        error,
        fetchItem,
    } = useFetchData<CreateEmployeeTaskDto, EmployeeTaskDto, EmployeeTaskDto>("EmployeeTasks")

    const hookForm = useForm<EditTaskFormModel>()
    const {getValues, setValue, handleSubmit, reset, control, watch} = hookForm;

    const apartment = useWatch({
        control,
        name: "apartment",
    });

    const location = useWatch({
        control,
        name: "location",
    });

    const isApartmentSelected = !!(apartment);
    const isLocationSelected = !!(location);

    useEffect(() => {
        if (isApartmentSelected) {
            setValue("location", undefined);
        }
        if (isLocationSelected) {
            setValue("apartment", undefined);
        }
    }, [isApartmentSelected, isLocationSelected]);

    const {
        fetchApartments,
        apartmentOptions,
        isLoading: isApartmentsLoading,
        error: apartmentsError
    } = useFetchApartments();

    const {
        fetchEmployeeTaskSources,
        employeeTaskSourceOptions,
        isLoading: isEmployeeTaskSourcesLoading,
        error: employeeTaskSourcesError
    } = useFetchTaskSources();

    const {
        fetchEmployeeTaskTypes,
        employeeTaskTypeOptions,
        isLoading: isEmployeeTaskTypesLoading,
        error: employeeTaskTypesError
    } = useFetchTaskTypes();

    const {
        fetchEmployees,
        employeesOptions,
        isLoading: isEmployeesLoading,
        error: employeesError
    } = useFetchUsers();

    const {
        departmentOptions,
        departments,
        fetchDepartments,
        isLoading: isDepartmentsLoading
    } = useFetchDepartment()

    const {
        isLoading: isPredefinedEmployeeTaskDescriptionsLoading,
        fetchItems: fetchPredefinedEmployeeTaskDescriptions,
        error: predefinedEmployeeTaskDescriptionsError,
        itemOptions: predefinedEmployeeTaskDescriptionsOptions,
        items: predefinedEmployeeTaskDescriptions,
        fetchItem: fetchPredefinedEmployeeTaskDescription,
    } = useFetchData<CreatePredefinedTaskDto, PredefinedTaskDto, PredefinedTaskDto>("PredefinedEmployeeTaskDescriptions", "description", "id");

    const {
        isLoading: isLocationLoading,
        itemOptions: locationOptions,
        fetchItems: fetchLocations
    } = useFetchData("Locations", "name", "id")

    const isBusy =
        isLoading
        || isApartmentsLoading
        || isEmployeeTaskSourcesLoading
        || isEmployeeTaskTypesLoading
        || isEmployeesLoading
        || isPredefinedEmployeeTaskDescriptionsLoading
        || isLocationLoading
        || isDepartmentsLoading;

    const handleAddTask = useCallback(async (data: EditTaskFormModel) => {
        if (!taskId) {
            const newTask: CreateEmployeeTaskDto = {
                sourceId: data.source.value,
                locationId: data.location?.value,
                typeId: data.type.value,
                predefinedDescriptionId: data.predefinedDescription?.value,
                assignedEmployeeId: data.assignedEmployee?.value,
                departmentId: data.department?.value,
                apartmentId: data.apartment?.value,
                availableFrom: data.availableFrom ? new Date(data.availableFrom) : new Date(),
                deadline: data.deadline ? new Date(data.deadline) : new Date(),
                status: data.status?.value,
                remarks: data.remarks,
            };

            await createItem(newTask);
            toast.success(t("Employee task saved successfully"))
        } else {
            const updatedTask: EmployeeTaskDto = {
                id: taskId,
                code: 0,
                sourceId: data.source.value,
                locationId: data.location?.value,
                typeId: data.type.value,
                predefinedDescriptionId: data.predefinedDescription?.value,
                assignedEmployeeId: data.assignedEmployee?.value,
                departmentId: data.department?.value,
                apartmentId: data.apartment?.value,
                availableFrom: data.availableFrom ? new Date(data.availableFrom) : new Date(),
                deadline: data.deadline ? new Date(data.deadline) : new Date(),
                status: data.status?.value,
                remarks: data.remarks,
            };

            await updateItem(taskId, updatedTask);
            toast.success(t("Employee task edited successfully"))
        }

        onChanged();
    }, [createItem]);

    const fetchData = useCallback(async () => {
        const apartments = await fetchApartments();
        const departments = await fetchDepartments();
        const taskTypes = await fetchEmployeeTaskTypes();
        const taskSource = await fetchEmployeeTaskSources();
        const predefinedTaskDescription = await fetchPredefinedEmployeeTaskDescriptions();
        const users = await fetchEmployees();
        const location = await fetchLocations();

        if (taskId) {
            const task = await fetchItem(taskId);
            if (task) {
                reset({
                    id: task.id,
                    code: task.code,
                    apartment: FormHelper.getSelectedOption<string>(task, apartments, "apartmentId", "roomNo"),
                    department: FormHelper.getSelectedOption<string>(task, departments, "departmentId", "description"),
                    location: FormHelper.getSelectedOption<string>(task, location, "locationId", "name"),
                    type: FormHelper.getSelectedOption<string>(task, taskTypes, "typeId", "description"),
                    source: FormHelper.getSelectedOption<string>(task, taskSource, "sourceId", "description"),
                    predefinedDescription: FormHelper.getSelectedOption<string>(task, predefinedTaskDescription, "predefinedDescriptionId", "description"),
                    assignedEmployee: FormHelper.getSelectedOption<string>(task, users, "assignedEmployeeId", "fullName"),
                    status: EnumHelper.getEmployeeTaskStatusOptions().find(s => s.value === task.status),
                    deadline: task.deadline ? new Date(task.deadline) : undefined,
                    availableFrom: task.availableFrom ? new Date(task.availableFrom) : undefined,
                    remarks: task.remarks,
                });
            }
        } else {
            const userDepartment =
                {
                    id: userDetails?.departmentId,
                }

            reset({
                department: FormHelper.getSelectedOption<string>(userDepartment, departments, "id", "description"),
                status: EnumHelper.getEmployeeTaskStatusOptions().find(s => s.value === EmployeeTaskStatusEnum.New),
                availableFrom: new Date(),
            })
        }
    }, [taskId])

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchEmployees(watch("department")?.value);
    }, [watch("department")]);

    useEffect(() => {
        const fetchPredefinedTaskDepartment = async () => {
            if (watch("predefinedDescription")) {
                const predefinedTask = await fetchPredefinedEmployeeTaskDescription(watch("predefinedDescription")!.value);
                if (predefinedTask) {
                    const newDepartment = FormHelper.getSelectedOption<string>(predefinedTask, departments, "departmentId", "description");
                    setValue("department", newDepartment);
                }
            }
        }

        fetchPredefinedTaskDepartment();
    }, [watch("predefinedDescription")]);

    const updateDeadline = (predefinedTaskId?: string, availableFrom?: Date, deadline?: Date) => {
        if (!predefinedTaskId) return;
        if (!predefinedEmployeeTaskDescriptions) return;

        if (deadline === undefined && availableFrom !== undefined) {
            const predefinedTask = predefinedEmployeeTaskDescriptions.find(p => p.id === predefinedTaskId);

            const deadline = addMinutes(availableFrom, predefinedTask?.approximatedTime ?? 0);
            setValue("deadline", deadline);
        }
    }

    useEffect(() => {
        updateDeadline(watch("predefinedDescription.value"), watch("availableFrom"), getValues("deadline"));
    }, [watch("predefinedDescription"), watch("availableFrom")]);

    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={taskId ? (`Edit Task - ${watch("code") ?? ""}`) : (("Add Task"))}
                                     onClose={onClose}/>
                    {<AlertDanger
                        errorMessages={[error ?? "", ...validationErrors, apartmentsError, employeeTaskSourcesError, employeeTaskTypesError, employeesError]}/>}
                    <Loading
                        loading={isBusy || isSubmitting}
                        type={"cover"}
                    >
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={apartmentOptions}
                                fieldName={"apartment"}
                                label={t("Room")}
                                isRequired={!isLocationSelected}
                                isDisabled={isLocationSelected}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={locationOptions}
                                fieldName={"location"}
                                label={t("Location")}
                                isRequired={!isApartmentSelected}
                                isDisabled={isApartmentSelected}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={employeeTaskTypeOptions}
                                fieldName="type"
                                label={t("Type")}
                                isRequired={true}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={employeeTaskSourceOptions}
                                fieldName="source"
                                label={t("Source")}
                                isRequired={true}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={predefinedEmployeeTaskDescriptionsOptions}
                                fieldName="predefinedDescription"
                                label={t("PredefinedDescription")}
                                extraCssClass={"w-full"}
                                isRequired={true}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={departmentOptions}
                                fieldName="department"
                                label={t("Department")}
                                isRequired={true}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={employeesOptions}
                                fieldName="assignedEmployee"
                                label={t("Assignee")}
                                isRequired={false}
                            />
                        </div>
                        {isAdmin && <div className={"mt-2"}>
                            <FormSelectControl options={EnumHelper.getEmployeeTaskStatusOptions()}
                                               label={t("Status")}
                                               fieldName={"status"}/>
                        </div>}
                        <div className={"mt-2 flex"}>
                            <div className={"me-1 flex-1"}>
                                <FormDateInput fieldName={"availableFrom"}
                                               label={t("Available From")}
                                               isRequired={false}
                                />
                            </div>
                            <div className={"ms-1 flex-1"}>
                                <FormDateInput fieldName={"deadline"}
                                               label={t("Deadline")}
                                               isRequired={false}
                                />
                            </div>
                        </div>
                        <div className={"mt-2"}>
                            <FormTextAreaInput fieldName={"remarks"} label={t("Remarks")} width={"full"}/>
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Save")}
                    showCloseButton={false}
                    onSave={handleSubmit(handleAddTask)}
                    inProgress={isBusy || isSubmitting}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    )
}