import { t } from "i18next";
import useFetchData from "../../../hooks/useFetchData";
import Loading from "../../../components/Loading/Loading";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import AlertDanger from "../../../components/Alerts/AlertDanger";
import BaseModal from "../../../components/Modals/BaseModal";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import { toast } from "react-hot-toast";
import FormTextInput from "../../../components/FormControls/FormTextInput";
import { CreateTaskSourceDto, TaskSourceDto } from "../../../dtos/taskSource/TaskSourceDto";

type Props = {
    onClose: () => void;
    onChanged: () => void;
    taskSourceId?: string;
}

type EditTaskSourceFormModel = {
    id?: string,
    description: string,
}

export const EditTaskSourceModal: React.FC<Props> = ({
                                                       onClose,
                                                       taskSourceId,
                                                       onChanged,
                                                   }) => {
    const {
        createItem,
        updateItem,
        item,
        fetchItem,
        error,
        isLoading,
        isSubmitting
    } = useFetchData<CreateTaskSourceDto, TaskSourceDto, TaskSourceDto>("EmployeeTaskSources");

    const isBusy = isLoading || isSubmitting;

    const handleAddOrUpdateTaskSource = useCallback(async (data: EditTaskSourceFormModel) => {
        if (!taskSourceId) {
            const newTAskSource: CreateTaskSourceDto = {
                description: data.description,
            };
            await createItem(newTAskSource);
        } else {
            const updateTaskSource: TaskSourceDto = {
                id: taskSourceId,
                description: data.description,
            };
            await updateItem(taskSourceId, updateTaskSource);
        }

        onChanged();
        toast.success(t("TaskSourceSavedSuccessfully"));
    }, [taskSourceId, onChanged]);

    const hookForm = useForm<EditTaskSourceFormModel>();
    const {reset, handleSubmit} = hookForm;

    const fetchData = useCallback(async () => {

        if (taskSourceId) {
            const taskSource = await fetchItem(taskSourceId);
            if (taskSource) {
                reset({
                    id: taskSource.id,
                    description: taskSource.description,
                });
            }
        }
    }, [taskSourceId]);

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={!taskSourceId ? (t("AddTaskSource")) : (t("EditTaskSource"))}
                                     onClose={onClose}/>
                    {error && <AlertDanger errorMessages={[error]}/>}
                    <Loading
                        loading={isBusy}
                        type={"cover"}
                    >
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="description"
                                type="text"
                                label={t("Name")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Save")}
                    showCloseButton={false}
                    onSave={handleSubmit(handleAddOrUpdateTaskSource)}
                    saveButtonDisabled={isBusy}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    )
}