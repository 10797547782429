import TasksList from "./TasksList.tsx";
import {useAppDispatch, useAppSelector} from "../../redux/hooks/hooks.ts";
import React, {useEffect, useMemo} from "react";
import {selectIsSupervisor} from "../../redux/slices/authSlice.ts";
import {EmployeeTaskStatusEnum} from "../../enums/EmployeeTaskStatusEnum.ts";
import {useParams} from "react-router-dom";
import {selectTasksFilter, setTasksFilter} from "../../redux/slices/appSlice.ts";

const TasksPage: React.FC = () => {
    const {taskPageStatus} = useParams();
    const taskFilter =  useAppSelector(selectTasksFilter);
    const dispatch = useAppDispatch();

    const getEnumStatus = (taskPageStatus?: string): SelectControlOption<EmployeeTaskStatusEnum> | undefined => {
        switch (taskPageStatus) {
            case "0":
                return {
                    label: "New",
                    value: EmployeeTaskStatusEnum.New
                }
            case "1":
                return {
                    label: "Assigned",
                    value: EmployeeTaskStatusEnum.Assigned
                }
            case "2":
                return {
                    label: "In Progress",
                    value: EmployeeTaskStatusEnum.OnGoing
                }
            case "3":
                return {
                    label: "Closed",
                    value: EmployeeTaskStatusEnum.Closed
                }
            case "4":
                return {
                    label: "Inspected",
                    value: EmployeeTaskStatusEnum.Inspected
                }
            default:
                return undefined;
        }
    };
    
    useEffect(()=> {
        dispatch(setTasksFilter({...taskFilter, taskStatus: getEnumStatus(taskPageStatus)}));
    }, [taskPageStatus])

    return (
        <TasksList />
    );
}

export default TasksPage;