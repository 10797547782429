import MessageIcon from "../../../assets/svg/sidebarIcons/MessageIcon";
import ApartmentIcon from "../../../assets/svg/sidebarIcons/ApartmentIcon";
import DashboardIcon from "../../../assets/svg/sidebarIcons/DashboardIcon";
import OrdersIcon from "../../../assets/svg/sidebarIcons/OrdersIcon";
import {IMenuItemsDto} from "../../../dtos/IMenuItems/IMenuItemsDto";
import SettingsIcon from "../UserProfile/Account/res/SettingsIcon.tsx";
import ArchiveTaskIcon from "../../../assets/svg/tasksIcons/ArchiveTaskIcon.tsx";
import AllTasksIcon from "../../../assets/svg/tasksIcons/AllTasksIcon.tsx";

export const AdminSidebarMenu: IMenuItemsDto[] = [
    {
        path: "/dashboard",
        title: "Dashboard",
        active: false,
        icon: <DashboardIcon/>,
        items: [],
    },
    {
        path: "/tasks",
        title: "AllTasks",
        active: false,
        icon: <AllTasksIcon/>,
        items: [],
    },
    {
        path: "/tasks/4",
        title: "TasksArchive",
        active: false,
        icon: <ArchiveTaskIcon/>,
        items: [],
    },
    {
        path: "/admin-panel",
        title: "AdminPanel",
        active: false,
        icon: <SettingsIcon/>,
        items: [],
    },
];

export const SupervisorSidebarMenu: IMenuItemsDto[] = [
    {
        path: "/dashboard",
        title: "Dashboard",
        active: false,
        icon: <DashboardIcon/>,
        items: [],
    },
    {
        path: "/tasks",
        title: "AllTasks",
        active: false,
        icon: <AllTasksIcon/>,
        items: [],
    },
    {
        path: "/tasks/4",
        title: "TasksArchive",
        active: false,
        icon: <ArchiveTaskIcon/>,
        items: [],
    },
];

export const EmployeeSidebarMenu: IMenuItemsDto[] = [
    {
        path: "/dashboard",
        title: "Dashboard",
        active: false,
        icon: <DashboardIcon/>,
        items: [],
    },
    // {
    //     path: "/calendar",
    //     title: "Calendar",
    //     active: false,
    //     icon: <OwnerCalendarIcon/>,
    //     items: [],
    // },
    {
        path: "/tasks",
        title: "AllTasks",
        active: false,
        icon: <AllTasksIcon/>,
        items: [],
    },
    {
        path: "/tasks/4",
        title: "TasksArchive",
        active: false,
        icon: <ArchiveTaskIcon/>,
        items: [],
    },
];
