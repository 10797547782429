import React, {FC, Fragment, PropsWithChildren} from 'react';
import TaskTypeButton from "./TaskTypeButton.tsx";
import NewTaskIcon from "../../../assets/svg/tasksIcons/NewTaskIcon.tsx";
import {t} from "i18next";
import {EmployeeTaskStatusEnum} from "../../../enums/EmployeeTaskStatusEnum.ts";
import AssignedTaskIcon from "../../../assets/svg/tasksIcons/AssignedTaskIcon.tsx";
import InProgressTaskIcon from "../../../assets/svg/tasksIcons/InProgressIcon.tsx";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/hooks.ts";
import {selectTasksFilter, setTasksFilter} from "../../../redux/slices/appSlice.ts";
import {TaskFilterModal} from "../types.ts";
import AllTaskFilterIcon from '../../../assets/svg/tasksIcons/AllTaskFilterIcon.tsx';
import DoneTaskIcon from '../../../assets/svg/tasksIcons/DoneTaskIcon.tsx';
import { LuCheck, LuListChecks } from 'react-icons/lu';
import { ThemeColors } from '../../../theme/colors.ts';

type Props = {}

const TaskFilterButtons: FC<PropsWithChildren<Props>> = ({}) => {
    const tasksFilter = useAppSelector(selectTasksFilter);
    const dispatch = useAppDispatch();

    const handleSetTasksFilter = (newFilter: TaskFilterModal) => {
        dispatch(setTasksFilter(newFilter));
    }

    return (
        <>
            <TaskTypeButton icon={<LuListChecks size={20} />} title={t("All")} onClick={() => handleSetTasksFilter({
                ...tasksFilter, 
                taskStatus: undefined,
            })} isActive={tasksFilter?.taskStatus?.value === undefined}/>
            <TaskTypeButton icon={<NewTaskIcon/>} title={t("New")} onClick={() => handleSetTasksFilter({
                ...tasksFilter,
                taskStatus: {
                    label: t("New"),
                    value: EmployeeTaskStatusEnum.New
                }
            })} isActive={tasksFilter?.taskStatus?.value === EmployeeTaskStatusEnum.New}/>
            <TaskTypeButton icon={<AssignedTaskIcon/>} title={t("Assigned")}
                            onClick={() => handleSetTasksFilter({
                                ...tasksFilter,
                                taskStatus: {
                                    label: t("Assigned"),
                                    value: EmployeeTaskStatusEnum.Assigned
                                }
                            })}
                            isActive={tasksFilter?.taskStatus?.value === EmployeeTaskStatusEnum.Assigned}/>
            <TaskTypeButton icon={<InProgressTaskIcon/>} title={t("Ongoing")}
                            onClick={() => handleSetTasksFilter({
                                ...tasksFilter,
                                taskStatus: {
                                    label: t("Ongoing"),
                                    value: EmployeeTaskStatusEnum.OnGoing
                                }
                            })}
                            isActive={tasksFilter?.taskStatus?.value === EmployeeTaskStatusEnum.OnGoing}/>
            <TaskTypeButton icon={<LuCheck size={20}/>} title={t("Closed")}
                            onClick={() => handleSetTasksFilter({
                                ...tasksFilter,
                                taskStatus: {
                                    label: t("Closed"),
                                    value: EmployeeTaskStatusEnum.Closed
                                }
                            })}
                            isActive={tasksFilter?.taskStatus?.value === EmployeeTaskStatusEnum.Closed}/>
        </>
    );
};

export default TaskFilterButtons;