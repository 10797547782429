import {Route, Routes} from "react-router-dom";
import Auth from "../pages/AuthPage/Auth";
import App from "../App";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import CalendarPage from "../pages/Calendar/CalendarPage";
import Messages from "../pages/Messages/Messages";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import LoginPage from "../pages/LoginPage/LoginPage";
import ProtectedRoutes from "./ProtectedRoutes";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import NotFound from "../pages/NotFound/NotFound.tsx";
// import OwnerPage from "../pages/Users/OwnerPage.tsx";
import AgreementAttachmentPage from "../pages/Agreements/AgreementAttachmentPage.tsx";
import AdminPanel from "../pages/AdminPanel/AdminPanel.tsx";
import Employees from "../pages/Employees/EmployeesPage.tsx";
import TasksTypePage from "../pages/TaskType/TaskTypePage.tsx";
import TasksSourcesPage from "../pages/TasksSources/TasksSourcesPage.tsx";
import PredifinedTaskDecriptionPage from "../pages/PredifinedTaskDescription/PredifinedTaskDescriptionPage.tsx";
import TasksPage from "../pages/TasksPage/TasksPage.tsx";
import DepartmentPage from "../pages/Department/Department.tsx";
import ServicesPage from "../pages/ServicesPage/ServicesPage.tsx";
import LocationsPage from "../pages/LocationsPage/LocationsPage.tsx";
import NotificationsPage from "../pages/NotificationsPage/NotificationsPage.tsx";

const MainRouter = () => {
    return (
        <Routes>
            <Route path={"/"} element={<Auth/>}>
                <Route index element={<LoginPage/>}/>
                <Route index path={"forgot-password"} element={<ForgotPassword/>}/>
            </Route>
            <Route path={"/"} element={<App/>}>
                <Route
                    index
                    path={"dashboard"}
                    element={
                        <ProtectedRoutes>
                            <DashboardPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"calendar"}
                    element={
                        <ProtectedRoutes>
                            <CalendarPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"tasks"}
                    element={
                        <ProtectedRoutes>
                            <TasksPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"tasks/:taskPageStatus"}
                    element={
                        <ProtectedRoutes>
                            <TasksPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"admin-panel"}
                    element={
                        <ProtectedRoutes>
                            <AdminPanel/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"employees"}
                    element={
                        <ProtectedRoutes>
                            <Employees/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"messages/:messageId?"}
                    element={
                        <ProtectedRoutes>
                            <Messages/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"tasks-type"}
                    element={
                        <ProtectedRoutes>
                            <TasksTypePage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"tasks-sources"}
                    element={
                        <ProtectedRoutes>
                            <TasksSourcesPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"predefined-task-description"}
                    element={
                        <ProtectedRoutes>
                            <PredifinedTaskDecriptionPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"department"}
                    element={
                        <ProtectedRoutes>
                            <DepartmentPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"services"}
                    element={
                        <ProtectedRoutes>
                            <ServicesPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"locations"}
                    element={
                        <ProtectedRoutes>
                            <LocationsPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"agreement-attachment/:agreementId"}
                    element={
                        <ProtectedRoutes>
                            <AgreementAttachmentPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"settings"}
                    element={
                        <ProtectedRoutes>
                            <SettingsPage/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    index
                    path={"notifications"}
                    element={
                        <ProtectedRoutes>
                            <Messages/>
                        </ProtectedRoutes>
                    }
                />

                <Route path={"*"} element={<NotFound/>}/>
            </Route>
        </Routes>
    );
};

export default MainRouter;
