import React, {useCallback, useEffect} from 'react';
import {useParams} from "react-router-dom";
import PageHeader from "../../components/PageHeader/PageHeader.tsx";
import {useTranslation} from "react-i18next";
import {apiUrl, axiosInstance} from "../../config/appConfig.ts";
import PageHeaderSelect from "../../components/PageHeaderSelect/PageHeaderSelect.tsx";
import PageHeaderSelectOption from "../../components/PageHeaderSelect/PageHeaderSelectOption.tsx";
import EditFileModal from "./modals/EditFileModal.tsx";
import QuestionModal from "../../components/QuestionModal/QuestionModal.tsx";
import WarningIcon from "../../assets/svg/WarningIcon.tsx";
import toast from "react-hot-toast";
import PageContainer from '../../components/PageContainer/PageContainer.tsx';

const AgreementAttachmentPage = ({}) => {
    const {t} = useTranslation();
    const {agreementId} = useParams();
    const [showEditFileModal, setShowEditFileModal] = React.useState(false);
    const [reloadIndexer, setReloadIndexer] = React.useState(0);
    const [showRemoveFileModal, setShowRemoveFileModal] = React.useState(false);
    const [fileLink, setFileLink] = React.useState<string | undefined>(undefined);

    const handleUploadFile = () => {
        setShowEditFileModal(true);
    };

    const handleRemoveFileApproved = async () => {
        try {
            const response = await axiosInstance.delete(`FileAttachment/${agreementId}`);
            if (response.status === 200) {
                setReloadIndexer(reloadIndexer + 1);
            }
            toast.success("File removed successfully.");
            setShowRemoveFileModal(false);
            await fetchFileLink();
        } catch (e) {
            console.log(e);
            toast.error("An error occurred while removing the file.");
        } finally {
        }
    };

    const handleRemoveFile = async () => {
        if (!agreementId || !fileLink) {
            return;
        }
        setShowRemoveFileModal(true);
    };

    const handleCloseFileEditModal = () => {
        setShowEditFileModal(false);
    };

    const handleOnUploaded = async () => {
        setShowEditFileModal(false);
        await fetchFileLink();
        setReloadIndexer(reloadIndexer + 1);
    };

    const fetchFileLink = async () => {
        if (!agreementId) {
            return;
        }
        
        try {
            const response = await axiosInstance.get<string | undefined>(`FileAttachment/${agreementId}/FileLink`, {
                validateStatus: (status) => true
            });
            setFileLink(response.data);
        } catch (e) {
            setFileLink(undefined);
            console.log(e);
            toast.error("An error occurred while fetching the file link.");
        } finally {
        }
    }

    useEffect(() => {
        fetchFileLink();
    }, [agreementId]);

    return (
        <>
            <PageContainer>
                <PageHeader title={t("AgreementFile")} showBackButton={true}>
                    <div className={"flex"}>
                        <PageHeaderSelect label={"Actions"} width={"w-32"}>
                            <PageHeaderSelectOption label={t("Edit file")}
                                                    onItemClick={handleUploadFile} extraClassName={"z-50"}/>
                            <PageHeaderSelectOption label={t("Remove")}
                                                    onItemClick={handleRemoveFile} extraClassName={"z-50"}/>
                        </PageHeaderSelect>
                    </div>
                </PageHeader>

                <div>
                    <div className={"w-full mt-4 h-100 overflow-auto"}>
                        {fileLink && <iframe key={reloadIndexer} className="pdf w-full"
                                            src={`${apiUrl}FileAttachment/${fileLink}`}
                                            height="800">
                        </iframe>}
                        {!fileLink && <div className="text-center">{t("No file attached.")}</div>}
                    </div>
                </div>

                {showEditFileModal && <EditFileModal id={agreementId!} onUploaded={handleOnUploaded}
                                                    onClose={handleCloseFileEditModal}></EditFileModal>}
                {showRemoveFileModal &&
                    <QuestionModal title={t("DeleteFile")} icon={<WarningIcon/>}
                                content={t("You will delete this file from our server. This action cannot be undone.")}
                                onClose={() => setShowRemoveFileModal(false)} onYes={handleRemoveFileApproved}/>}
            </PageContainer>
        </>
    );
};

export default AgreementAttachmentPage;