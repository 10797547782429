import {FC, PropsWithChildren, useCallback, useEffect, useState} from "react";
import Loading from "../../../components/Loading/Loading";
import BaseModal from "../../../components/Modals/BaseModal";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import FormSelectControl from "../../../components/FormControls/FormSelectControl";
import useFetchEmployeeTasks from "../../../hooks/useFetchEmployeeTasks.ts";
import {t} from "i18next";
import FormDateInput from "../../../components/FormControls/FormDateInput";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import {FormProvider, useForm} from "react-hook-form";
import useFetchEmployees from "../../../hooks/useFetchEmployees";
import {TaskFilterModal} from "../types";
import FilterApartmentsIcon from "../../../assets/svg/apartmentIcons/FilterApartmentsIcon";
import {ThemeColors} from "../../../theme/colors";
import ClearFilterIcon from "../../../assets/svg/ClearFilterIcon";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import useFetchData from "../../../hooks/useFetchData.ts";
import CreateEmployeeTaskTypeDto from "../../../dtos/employeeTaskTypes/CreateEmployeeTaskTypeDto.ts";
import EmployeeTaskTypeDto from "../../../dtos/employeeTaskTypes/EmployeeTaskTypeDto.ts";
import EmployeeTaskSourceDto from "../../../dtos/employeeTaskSources/EmployeeTaskSourceDto.ts";
import CreateEmployeeTaskSourceDto from "../../../dtos/employeeTaskSources/CreateEmployeeTaskSourceDto.ts";


type Props = {
    onClose: () => void;
    onApply: (filter: TaskFilterModal) => void;
    filter: TaskFilterModal;
}

const TasksFilterModal: FC<PropsWithChildren<Props>> = ({
                                                            onClose,
                                                            onApply,
                                                            filter
                                                        }) => {

    const {
        fetchTasks,
        tasks,
        isLoading,
        employeeTasksStatusOptions
    } = useFetchEmployeeTasks();

    const {
        employeesOptions,
        fetchEmployees,
        isLoading: isEmployeeLoading,
    } = useFetchEmployees();
    
    const {
        isLoading: isEmployeeTaskTypeLoading,
        itemOptions: employeeTaskTypesOptions,
        fetchItems: fetchTaskTypes,
    } = useFetchData<CreateEmployeeTaskTypeDto, EmployeeTaskTypeDto, EmployeeTaskTypeDto>("EmployeeTaskTypes", "description", "id");

    const {
        isLoading: isEmployeeTaskSourceLoading,
        itemOptions: employeeTaskSourceOptions,
        fetchItems: fetchTaskSources,
    } = useFetchData<CreateEmployeeTaskSourceDto, EmployeeTaskSourceDto, EmployeeTaskSourceDto>("EmployeeTaskSources", "description", "id");

    const {
        isLoading: isLocationLoading,
        itemOptions: locationOptions,
        fetchItems: fetchLocations
    } = useFetchData("Locations", "name", "id");

    const isBusy =
        isLoading
        || isEmployeeTaskTypeLoading
        || isEmployeeTaskSourceLoading
        || isLocationLoading
        || isEmployeeLoading;

    const hookForm = useForm<TaskFilterModal>({
        defaultValues: filter
    });
    const {handleSubmit, formState: {errors}} = hookForm;

    const fetchData = useCallback(async () => {
        await fetchTaskTypes();
        await fetchTaskSources();
        await fetchEmployees(undefined, undefined, undefined, "Employee");
        await fetchTasks();
        await fetchLocations();
    }, []);

    useEffect(() => {
        fetchData()
    }, []);

    const handleApplyFilter = (filter: TaskFilterModal) => {
        onApply(filter);
    }

    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={t("TasksFilter")} onClose={onClose}/>
                    <Loading loading={isBusy}>
                        <div>
                            <FormSelectControl
                                options={employeeTaskTypesOptions}
                                fieldName={"taskType"}
                                label={t("Type")}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={locationOptions}
                                fieldName="location"
                                label={t("Location")}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormSelectControl
                                options={employeesOptions}
                                fieldName={"assigneeEmployee"}
                                label={t("Employee")}
                            />
                        </div>
                        <div className={"mb-4 mt-2"}>
                            <FormSelectControl
                                options={employeeTasksStatusOptions}
                                fieldName={"taskStatus"}
                                label={t("Status")}
                            />
                        </div>
                        <div className={"mb-4 mt-2"}>
                            <FormSelectControl
                                options={employeeTaskSourceOptions}
                                fieldName={"taskSource"}
                                label={t("Source")}
                            />
                        </div>
                        <div className={"mt-2 mb-3 flex w-full flex-row justify-between"}>
                            <div className={"flex-1"}>
                                <FormDateInput fieldName={"dateFrom"} label={t("DateFrom")}/>
                            </div>
                            <div className={"px-2"}></div>
                            <div className={"flex-1"}>
                                <FormDateInput fieldName={"dateTo"} label={t("DateTo")}/>
                            </div>
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons onClose={onClose}
                                  saveButtonText={"Apply"}
                                  inProgress={isBusy}
                                  saveButtonIcon={<FilterApartmentsIcon strokeColor={ThemeColors.whiteColor}
                                                                        color={ThemeColors.whiteColor}/>}
                                  onSave={handleSubmit(handleApplyFilter)}
                >
                    <SecondaryButton title={t("Clear")}
                                     icon={<ClearFilterIcon color={ThemeColors.mainColor}/>}
                                     onClick={onClose}
                                     isSubmitting={isBusy}
                    />
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    );
};

export default TasksFilterModal;
