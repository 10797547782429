import React, {FC, PropsWithChildren, useCallback, useEffect} from 'react';
import BaseModalHeader from "../../../components/Modals/BaseModalHeader.tsx";
import AlertDanger from "../../../components/Alerts/AlertDanger.tsx";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons.tsx";
import {t} from "i18next";
import Loading from "../../../components/Loading/Loading.tsx";
import {FormProvider, useForm} from "react-hook-form";
import {Simulate} from "react-dom/test-utils";
import BaseModalContent from "../../../components/Modals/BaseModalContent.tsx";
import BaseModal from "../../../components/Modals/BaseModal.tsx";
import useFetchUsers from "../../../hooks/useFetchUsers.ts";
import useFetchData from "../../../hooks/useFetchData.ts";
import CreateEmployeeTaskDto from "../../../dtos/EmployeeTask/CreateEmployeeTaskDto.ts";
import EmployeeTaskDto from "../../../dtos/EmployeeTask/EmployeeTaskDto.ts";
import FormSelectControl from "../../../components/FormControls/FormSelectControl.tsx";

type Props = {
    onClose: () => void;
    onChanged: () => void;
    taskId: string;
}

type AssignTaskFormModel = {
    taskId: string;
    code: string;
    assignee: SelectControlOption<string>;
}

const TaskAssigneeModal: FC<PropsWithChildren<Props>> = ({
                                                             onClose, onChanged, taskId
                                                         }) => {
    const hookForm = useForm<AssignTaskFormModel>()
    const {handleSubmit, reset, control, watch} = hookForm;

    const {
        isLoading,
        isSubmitting,
        createItem,
        updateItem,
        error,
        fetchItem,
    } = useFetchData<CreateEmployeeTaskDto, EmployeeTaskDto, EmployeeTaskDto>("EmployeeTasks")

    const {
        fetchEmployees,
        employeesOptions,
        isLoading: isEmployeesLoading,
        error: employeesError
    } = useFetchUsers();

    const isBusy = isLoading || isEmployeesLoading;

    const fetchData = useCallback(async () => {
        await fetchEmployees();
        await fetchItem(taskId);
    }, [taskId]);

    useEffect(() => {
        fetchData();
    }, [taskId]);

    const handleAddTask = (data: AssignTaskFormModel) => {
        console.log(data);
    };

    return (
        <>
            <FormProvider {...hookForm}>
                <BaseModal>
                    <BaseModalContent>
                        <BaseModalHeader title={taskId ? (`Edit Task - ${watch("code")}`) : (("Add Task"))}
                                         onClose={onClose}/>
                        <AlertDanger errorMessages={[error, employeesError]}/>
                        <Loading
                            loading={isBusy || isSubmitting}
                            type={"cover"}
                        >
                            <div className={"mt-2"}>
                                <FormSelectControl
                                    options={employeesOptions}
                                    fieldName="assignedEmployee"
                                    label={t("Assignee")}
                                    isRequired={false}
                                />
                            </div>
                        </Loading>
                    </BaseModalContent>
                    <BaseModalButtons
                        saveButtonText={t("Save")}
                        showCloseButton={false}
                        onSave={handleSubmit(handleAddTask)}
                        inProgress={isBusy || isSubmitting}
                    >
                    </BaseModalButtons>
                </BaseModal>
            </FormProvider>
        </>
    );
};

export default TaskAssigneeModal;