import React, {useEffect, useRef, useState} from "react";
import {format} from "date-fns";
import { CalendarEventDto } from "../../../dtos/calendar/CalendarEventDto";

type Props = {
    event: CalendarEventDto,
    cssClass: string,
};

export const CalendarSchedulerEventsModal: React.FC<Props> = ({
                                                                  event,
                                                                  cssClass,
                                                              }) => {
    return (
        <div
            className={`flex flex-col text-white bg-selected-black max-w-[200px] p-3 z-40`}
        >
            <div className="flex justify-start items-center w-full">
                <span className={`mr-1 h-[14px] w-[14px] rounded-full ${cssClass}`}></span>
                <p className="whitespace-nowrap text-black">Room: {event.roomNo}</p>
            </div>
            <p className="whitespace-nowrap text-sm text-black">Guest: {event.guestFullName}</p>
            <p className="text-sm whitespace-nowrap text-black">
                {`${format(event.startDate, "yyyy.MM.dd")}-${format(event.endDate, "dd/MM/yyyy")}`}
            </p>
        </div>
    );
};