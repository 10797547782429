const DashboardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1.6665 4.99999C1.6665 3.42864 1.6665 2.64297 2.15466 2.15481C2.64281 1.66666 3.42849 1.66666 4.99984 1.66666C6.57119 1.66666 7.35686 1.66666 7.84502 2.15481C8.33317 2.64297 8.33317 3.42864 8.33317 4.99999V6.66666C8.33317 8.238 8.33317 9.02368 7.84502 9.51183C7.35686 9.99999 6.57119 9.99999 4.99984 9.99999C3.42849 9.99999 2.64281 9.99999 2.15466 9.51183C1.6665 9.02368 1.6665 8.238 1.6665 6.66666V4.99999Z"
        strokeWidth="1.25"
      />
      <path
        d="M1.6665 15.8333C1.6665 15.0567 1.6665 14.6685 1.79337 14.3622C1.96253 13.9538 2.28698 13.6293 2.69536 13.4602C3.00165 13.3333 3.38993 13.3333 4.1665 13.3333H5.83317C6.60974 13.3333 6.99802 13.3333 7.30431 13.4602C7.71269 13.6293 8.03715 13.9538 8.2063 14.3622C8.33317 14.6685 8.33317 15.0567 8.33317 15.8333C8.33317 16.6099 8.33317 16.9982 8.2063 17.3045C8.03715 17.7128 7.71269 18.0373 7.30431 18.2064C6.99802 18.3333 6.60974 18.3333 5.83317 18.3333H4.1665C3.38993 18.3333 3.00165 18.3333 2.69536 18.2064C2.28698 18.0373 1.96253 17.7128 1.79337 17.3045C1.6665 16.9982 1.6665 16.6099 1.6665 15.8333Z"
        strokeWidth="1.25"
      />
      <path
        d="M11.6665 13.3333C11.6665 11.762 11.6665 10.9763 12.1547 10.4882C12.6428 10 13.4285 10 14.9998 10C16.5712 10 17.3569 10 17.845 10.4882C18.3332 10.9763 18.3332 11.762 18.3332 13.3333V15C18.3332 16.5713 18.3332 17.357 17.845 17.8452C17.3569 18.3333 16.5712 18.3333 14.9998 18.3333C13.4285 18.3333 12.6428 18.3333 12.1547 17.8452C11.6665 17.357 11.6665 16.5713 11.6665 15V13.3333Z"
        strokeWidth="1.25"
      />
      <path
        d="M11.6665 4.16666C11.6665 3.39009 11.6665 3.0018 11.7934 2.69552C11.9625 2.28714 12.287 1.96268 12.6954 1.79352C13.0016 1.66666 13.3899 1.66666 14.1665 1.66666H15.8332C16.6097 1.66666 16.998 1.66666 17.3043 1.79352C17.7127 1.96268 18.0371 2.28714 18.2063 2.69552C18.3332 3.0018 18.3332 3.39009 18.3332 4.16666C18.3332 4.94323 18.3332 5.33151 18.2063 5.6378C18.0371 6.04618 17.7127 6.37063 17.3043 6.53979C16.998 6.66666 16.6097 6.66666 15.8332 6.66666H14.1665C13.3899 6.66666 13.0016 6.66666 12.6954 6.53979C12.287 6.37063 11.9625 6.04618 11.7934 5.6378C11.6665 5.33151 11.6665 4.94323 11.6665 4.16666Z"
        strokeWidth="1.25"
      />
    </svg>
  );
};

export default DashboardIcon;
