import ApartmentDto from "../dtos/apartments/ApartmentDto.ts";
import {useState} from "react";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto.ts";
import {axiosInstance} from "../config/appConfig.ts";
import EmployeeTaskTypeDto from "../dtos/employeeTaskTypes/EmployeeTaskTypeDto.ts";

const useFetchTaskTypes = () => {
    const [employeeTaskTypes, setEmployeeTaskTypes] = useState<EmployeeTaskTypeDto[]>([]);
    const [taskType, setTaskType] = useState<EmployeeTaskTypeDto | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const employeeTaskTypeOptions: SelectControlOption<string | number>[] = employeeTaskTypes.map((employeeTaskType) => ({
        value: employeeTaskType.id,
        label: employeeTaskType.description
    })) || [];

    const fetchEmployeeTaskTypes = async () => {
        try {
            setIsLoading(true);
            setError(undefined);

            const response = await axiosInstance.get<ListResponseDto<EmployeeTaskTypeDto>>("EmployeeTaskTypes");
            setEmployeeTaskTypes(response.data.items);

            return response.data.items;
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchTaskTypeById = async (employeeId: string) => {
        try {
            setIsLoading(true);

            if (employeeId) {
                const currentTaskType = employeeTaskTypes.find(taskType => +taskType.id === +employeeId) || undefined;
                setTaskType(currentTaskType);
            }
        } catch (error: any) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    };

    return {
        employeeTaskTypes,
        fetchTaskTypeById,
        taskType,
        isLoading,
        error,
        fetchEmployeeTaskTypes,
        employeeTaskTypeOptions
    };
}

export default useFetchTaskTypes;