import {FC, PropsWithChildren} from "react";
import {ThemeColors} from "../../theme/colors.ts";

type Props = {
    color?: string;
    width?: number;
    height?: number;
    strokeColor?: string;
}

const CloseIcon: FC<PropsWithChildren<Props>> = ({
                                                     color = ThemeColors.mainColor,
                                                     width = 20,
                                                     height = 20,
                                                     strokeColor = ThemeColors.mainColor
                                                 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 20 20"
        >
            <path
                d="M15.8337 4.16656L4.16699 15.8332M4.16699 4.16656L15.8337 15.8332"
                stroke={strokeColor}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CloseIcon;
