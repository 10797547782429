import {ReactNode} from "react";
import {useTranslation} from "react-i18next";

type Props = {
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    extraClassName?: string;
    inProgress?: boolean;
    icon?: ReactNode;
};

const MainButton = ({
                        title,
                        className,
                        onClick,
                        disabled,
                        inProgress,
                        extraClassName,
                        icon,
                    }: Props) => {
    const {t} = useTranslation();
    return (
        <button
            type="button"
            className={className ? className : `select-none flex h-[36px] flex-row items-center gap-2 rounded-lg py-2 pl-[9px] pr-[13px] font-myriad text-sm font-regular text-black-secondary-strong bg-dark-green disabled:bg-black-20 hover:bg-dark-green-80 border-[1px] border-black-20 ${extraClassName}`}
            disabled={inProgress === true || disabled === true}
            onClick={onClick}
        >
            {inProgress && (
                <span className="loading loading-spinner loading-sm"></span>
            )}
            {!inProgress && icon}
            {t(title)}
        </button>
    );
};

export default MainButton;
