import {Outlet} from "react-router-dom";
import Header from "./components/layouts/Header/Header";
import Sidebar from "./components/layouts/Sidebar/Sidebar";
import MainContent from "./components/layouts/MainContent/MainContent";
import {useAppDispatch, useAppSelector} from "./redux/hooks/hooks";
import {
    doLogout, selectIsSupervisor,
    setLogoutTimer,
} from "./redux/slices/authSlice";
import {useEffect, useState} from "react";
import LogoutModal from "./components/Modals/LogoutModal/LogoutModal";
import MandatoryNotification from "./components/Notifications/MandatoryNotification/MandatoryNotification";
import useMediaQuery from "./hooks/useMediaQuery";
import HeaderMobile from "./components/layouts/Header/HeaderMobile";
import MobileSidebar from "./components/layouts/Sidebar/MobileSidebar";
import MobileLanguageDropdown from "./components/DropDowns/languagesDropdown/MobileLanguageDropdown";
import {RootState} from "./redux/store";
import useFetchMessages from "./hooks/useFetchMessages.ts";
import SignalR from "./components/SignalR/SignalR.tsx";
import {messaging} from "./firebase/firebaseConfig";
import {getToken, onMessage} from "firebase/messaging";


const App = () => {
    const isSupervisor = useAppSelector(selectIsSupervisor);
    const isDropdownOpen = useAppSelector(
        (state: RootState) => state.languageDropdown.isDropdownOpen,
    );
    const dispatch = useAppDispatch();
    const logoutTimeMs = 1600000; //added one 0 not to be signed out shortly needs to be removed
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showMandatoryNotificationsModal, setShowMandatoryNotificationsModal] = useState(false);

    const sidebarIsOpen = useAppSelector((state) => state.sidebar.isOpen);
    const isMobile = useMediaQuery("(max-width: 768px)");
    const {checkMandatoryNotification, hasMandatoryMessage} = useFetchMessages();
    const {VITE_APP_VAPID_KEY} = import.meta.env;

    async function requestPermission() {
        //requesting permission using Notification API
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: VITE_APP_VAPID_KEY,
            });

            //We can send token to server
            console.log("Token generated : ", token);
        } else if (permission === "denied") {
            //notifications are blocked
            alert("You denied for the notification");
        }
    }

    onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
    });

    useEffect(() => {
        requestPermission();
    }, []);

    const handleUserActivity = () => {
        clearTimeout(dispatch(setLogoutTimer(logoutTimeMs)).payload);

        const timer = setTimeout(() => {
            setShowLogoutModal(true);
        }, logoutTimeMs);

        dispatch(setLogoutTimer(timer));
    };

    useEffect(() => {
        document.addEventListener("mousemove", handleUserActivity);
        document.addEventListener("keydown", handleUserActivity);
        document.addEventListener("click", handleUserActivity);

        const timer = setTimeout(() => {
            // dispatch(doLogout());
            setShowLogoutModal(true);
        }, logoutTimeMs);

        dispatch(setLogoutTimer(timer));

        return () => {
            document.removeEventListener("mousemove", handleUserActivity);
            document.removeEventListener("keydown", handleUserActivity);
            document.removeEventListener("click", handleUserActivity);
            clearTimeout(timer);
        };
    }, [dispatch]);

    useEffect(() => {
        if (isSupervisor) {
            const interval = setInterval(() => {
                if (showMandatoryNotificationsModal) {
                } else {
                    checkMandatoryNotification();
                }
            }, 15000);

            return () => clearInterval(interval);
        }
    }, [checkMandatoryNotification, showMandatoryNotificationsModal, isSupervisor]);

    useEffect(() => {
        if (hasMandatoryMessage) {
            setShowMandatoryNotificationsModal(true);
        }
    }, [hasMandatoryMessage]);

    return (
        <SignalR>
            <div
                className="relative grid h-screen grid-rows-[72px_1fr]"
                style={{
                    gridTemplateColumns: isMobile ? "0fr" : `var(--sidebar-width) 1fr`,
                }}
            >
                <div className="col-start-2">
                    {isMobile ? <HeaderMobile/> : <Header isOnDashboard={true}/>}
                </div>

                {isMobile ? (
                    <div
                        className={`${isMobile && sidebarIsOpen ? "top-container-bg" : ""}`}
                    >
                        {sidebarIsOpen && <MobileSidebar/>}
                    </div>
                ) : (
                    <section className="main-content-container fixed left-0 h-full">
                        <Sidebar/>
                    </section>
                )}

                {isMobile && sidebarIsOpen && <div className="modal-overlay"></div>}
                <div className="relative col-start-2 row-start-2 h-full overflow-y-auto">
                    <MainContent>
                        <Outlet/>
                    </MainContent>
                    {showLogoutModal && (
                        <LogoutModal
                            isOpen={showLogoutModal}
                            onClose={() => dispatch(doLogout())}
                        />
                    )}
                    {showMandatoryNotificationsModal && (
                        <MandatoryNotification
                            onClose={() => setShowMandatoryNotificationsModal(false)}
                        />
                    )}
                    {isDropdownOpen && <MobileLanguageDropdown showText={true}/>}
                </div>
            </div>
        </SignalR>
    );
};

export default App;
