const Spinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M24 4
           A 20 20 0 0 1 44 24
           A 20 20 0 0 1 24 44
           A 20 20 0 0 0 24 4"
        stroke="url(#paint0_linear)"
        strokeWidth="6"
        fill="none"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="24"
          y1="44"
          x2="24"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A9B5" />
          <stop offset="1" stopColor="#00A9B5" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Spinner;
