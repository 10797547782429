import {t} from "i18next";
import useFetchData from "../../../hooks/useFetchData";
import Loading from "../../../components/Loading/Loading";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader";
import BaseModalContent from "../../../components/Modals/BaseModalContent";
import AlertDanger from "../../../components/Alerts/AlertDanger";
import BaseModal from "../../../components/Modals/BaseModal";
import {FormProvider, useForm} from "react-hook-form";
import React, {useCallback, useEffect} from "react";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons";
import {toast} from "react-hot-toast";
import FormTextInput from "../../../components/FormControls/FormTextInput";
import {CreateTaskDescriptionDto, TaskDescriptionDto} from "../../../dtos/taskDecription/TaskDecription";
import FormSelectControl from "../../../components/FormControls/FormSelectControl";
import {CreateDepartmentDto, DepartmentDto} from "../../../dtos/departments/DepartmentDto.ts";
import {FormHelper} from "../../../utils/FormHelper.ts";
import CreateEmployeeTaskTypeDto from "../../../dtos/employeeTaskTypes/CreateEmployeeTaskTypeDto.ts";
import EmployeeTaskTypeDto from "../../../dtos/employeeTaskTypes/EmployeeTaskTypeDto.ts";

type Props = {
    onClose: () => void;
    onChanged: () => void;
    descriptionId?: string;
}

type EditPredefinedTaskDescriptionModel = {
    id?: string,
    description: string;
    department?: SelectControlOption<string>;
    taskType?: SelectControlOption<string>;
    approximatedTime?: number;
}

export const EditPredefinedTaskModal: React.FC<Props> = ({
                                                             onClose,
                                                             descriptionId,
                                                             onChanged,
                                                         }) => {
    const hookForm = useForm<EditPredefinedTaskDescriptionModel>({
        defaultValues: {
            description: "",
            department: undefined,
            taskType: undefined,
            approximatedTime: 0,
        }
    });
    const {reset, handleSubmit} = hookForm;

    const {
        createItem,
        updateItem,
        item,
        fetchItem,
        error,
        isLoading,
        isSubmitting,
    } = useFetchData<CreateTaskDescriptionDto, TaskDescriptionDto, TaskDescriptionDto>("PredefinedEmployeeTaskDescriptions");

    const {
        fetchItems: fetchDepartments,
        error: errorDepartments,
        isLoading: isLoadingDepartments,
        itemOptions: departmentOptions,
    } = useFetchData<CreateDepartmentDto, DepartmentDto, DepartmentDto>("Departments", "description", "id");

    const {
        fetchItems: fetchEmployeeTasksTypes,
        error: errorEmployeeTaskTypes,
        isLoading: isLoadingEmployeeTaskTypes,
        itemOptions: employeeTaskTypesOptions,
    } = useFetchData<CreateEmployeeTaskTypeDto, EmployeeTaskTypeDto, EmployeeTaskTypeDto>("EmployeeTaskTypes", "description", "id");

    const isBusy = isLoading || isLoadingDepartments || isLoadingEmployeeTaskTypes;

    const handleAddOrUpdateTaskType = useCallback(async (data: EditPredefinedTaskDescriptionModel) => {
        if (!descriptionId) {
            const newTAskSource: CreateTaskDescriptionDto = {
                description: data.description,
                departmentId: data.department?.value,
                taskTypeId: data.taskType?.value,
                approximatedTime: data.approximatedTime ?? 0,
            };
            await createItem(newTAskSource);
        } else {
            const updateTaskDescription: TaskDescriptionDto = {
                id: descriptionId,
                description: data.description,
                departmentId: data.department?.value,
                taskTypeId: data.taskType?.value,
                approximatedTime: data.approximatedTime ?? 0,
            };
            await updateItem(descriptionId, updateTaskDescription);
        }

        onChanged();
        toast.success(t("PredefinedTaskDescriptionSavedSuccessfully"));
    }, [descriptionId, onChanged]);


    const fetchData = useCallback(async () => {
        const departments = await fetchDepartments();
        const tasksTypes = await fetchEmployeeTasksTypes();

        if (descriptionId) {
            const taskPredefinedDescription = await fetchItem(descriptionId);
            if (taskPredefinedDescription) {
                reset({
                    id: taskPredefinedDescription.id,
                    description: taskPredefinedDescription.description,
                    department: FormHelper.getSelectedOption<string>(taskPredefinedDescription.departmentId, departments, "id", "name"),
                    taskType: FormHelper.getSelectedOption<string>(taskPredefinedDescription.taskTypeId, tasksTypes, "id", "description"),
                    approximatedTime: taskPredefinedDescription.approximatedTime,
                });
            }
        }
    }, [descriptionId]);

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <BaseModalHeader title={!descriptionId ? (t("AddTaskDescription")) : (t("EditTaskDescription"))}
                                     onClose={onClose}/>
                    <AlertDanger errorMessages={[error, errorDepartments, errorEmployeeTaskTypes]}/>
                    <Loading
                        loading={isBusy || isSubmitting}
                        type={"cover"}
                    >
                        <div className="mt-2">
                            <FormTextInput
                                fieldName="description"
                                type="text"
                                label={t("Description")}
                                extraCssClass="w-full"
                                isRequired={true}
                            />
                        </div>
                        <div className="mt-2">
                            <FormSelectControl
                                fieldName="department"
                                options={departmentOptions}
                                label={t("Department")}
                                extraCssClass="w-full"
                                isRequired={false}
                            />
                        </div>
                        <div className="mt-2">
                            <FormSelectControl
                                fieldName="taskType"
                                options={employeeTaskTypesOptions}
                                label={t("TaskType")}
                                extraCssClass="w-full"
                                isRequired={false}
                            />
                        </div>
                        <div className={"mt-2"}>
                            <FormTextInput
                                fieldName="approximatedTime"
                                type="number"
                                label={t("ApproximatedTime")}
                                extraCssClass="w-full"
                                isRequired={false}
                            />
                        </div>
                    </Loading>
                </BaseModalContent>
                <BaseModalButtons
                    saveButtonText={t("Save")}
                    showCloseButton={false}
                    onSave={handleSubmit(handleAddOrUpdateTaskType)}
                    saveButtonDisabled={isBusy || isSubmitting}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    )
}
