import PageHeader from "../../components/PageHeader/PageHeader";
import {useCallback, useEffect, useState} from "react";
import {EditEmployeeModal} from "./modals/EditEmployeeModal";
import DeleteItemIcon from "../../assets/svg/DeleteItemIcon";
import TableEditIcon from "../../assets/svg/TableEditIcon";
import DataTable from "../../components/DataTable/DataTable";
import PageContainer from "../../components/PageContainer/PageContainer";
import AlertDanger from "../../components/Alerts/AlertDanger";
import MainButton from "../../components/buttons/MainButton";
import PlusIcon from "../../assets/svg/buttonIcons/PlusIcon";
import useFetchData from "../../hooks/useFetchData.ts";
import {CreateEmployeeDto, EmployeeDto} from "../../dtos/employees/EmployeeDto.ts";
import QuestionModal from "../../components/QuestionModal/QuestionModal.tsx";
import SearchInput from "../../components/inputs/SearchInput.tsx";
import searchInput from "../../components/inputs/SearchInput.tsx";
import Loading from "../../components/Loading/Loading.tsx";
import {useTranslation} from "react-i18next";

const Employees = () => {
    const {t} = useTranslation();
    const {
        items: employees,
        deleteItem: deleteEmployee,
        fetchItems: fetchEmployees,
        updateItem: updateEmployee,
        createItem: createEmployee,
        fetchItem: fetchEmployeesById,
        item: employee,
        error,
        isLoading: isLoadingEmployee,
        totalCount
    } = useFetchData<CreateEmployeeDto, EmployeeDto, EmployeeDto>("Users");

    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>(undefined);
    const [showEmployeesModal, setShowEmployeesModal] = useState(false);
    const [showEmployeeDeleteQuestionModal, setShowEmployeeDeleteQuestionModal] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState<string>("");

    const handleCloseModal = () => {
        setShowEmployeesModal(false);
    }

    const fetchData = useCallback(async () => {
        await fetchEmployees(searchText, (pageNumber - 1) * pageSize, pageSize);
    }, [pageSize, pageNumber, searchText]);

    useEffect(() => {
        fetchData();
    }, [pageSize, pageNumber, searchText])

    const handleEditEmployee = (employee: EmployeeDto) => {
        setSelectedEmployeeId(employee.id);
        setShowEmployeesModal(true);
    };

    const handleDeleteEmployee = (employee: EmployeeDto) => {
        setSelectedEmployeeId(employee.id);
        setShowEmployeeDeleteQuestionModal(true);
    };

    const columns: DataTableColumn[] = [
        {
            title: t("UserName"),
            field: "userName"
        },
        {
            title: t("FullName"),
            field: "fullName"
        },
        {
            title: t("Department"),
            field: "departmentDescription"
        },
        {
            title: t("UserType"),
            field: "userType"
        },
        {
            title: t("Actions"),
            field: "actions",
            render: (value: any) => (
                <div className="flex gap-2">
                    <button onClick={() => handleEditEmployee(value)}><TableEditIcon/></button>
                    <button onClick={() => handleDeleteEmployee(value)}><DeleteItemIcon/></button>
                </div>
            )
        }

    ]

    const handleEmployeeUpdated = async () => {
        handleCloseModal();
        await fetchData();
    };

    const handleNewEmployee = () => {
        setSelectedEmployeeId(undefined);
        setShowEmployeesModal(true);
    };

    const handleOnReload = (newPageSize, newPageNumber) => {
        setPageSize(newPageSize);
        setPageNumber(newPageNumber);
    };

    const handleDeleteEmployeeConfirmed = async () => {
        setShowEmployeeDeleteQuestionModal(false);
        if (!selectedEmployeeId) return;

        await deleteEmployee(selectedEmployeeId);
        await fetchData();
    };

    return (
        <div>
            <PageHeader title={t("Employees")}>
                <div className={"flex gap-2"}>
                    <SearchInput searchText={searchText} onSearchTextChanged={setSearchText}/>
                    <MainButton
                        disabled={isLoadingEmployee}
                        title={t("NewEmployee")}
                        onClick={handleNewEmployee}
                        icon={<PlusIcon/>}
                    />
                </div>
            </PageHeader>
            <PageContainer>
                {error && <AlertDanger errorMessages={[error]}/>}
                <Loading loading={isLoadingEmployee}>
                    <div className={"mt-2"}>
                        <DataTable
                            showPagination={true}
                            items={employees}
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={pageNumber}
                            totalCount={totalCount}
                            onReload={handleOnReload}
                        />
                    </div>
                </Loading>

                {showEmployeesModal && (
                    <EditEmployeeModal
                        onClose={handleCloseModal}
                        onChanged={() => handleEmployeeUpdated()}
                        employeeId={selectedEmployeeId}
                    />
                )}
                {showEmployeeDeleteQuestionModal && (
                    <QuestionModal onClose={() => setShowEmployeeDeleteQuestionModal(false)}
                                   onYes={handleDeleteEmployeeConfirmed}
                                   title={t("AreYouSureYouWantToDeleteThisEmployee")}
                                   content={t("ThisActionCannotBeUndone")}
                                   icon={<DeleteItemIcon/>} type={"danger"}
                                   yesButtonText={t("Yes")}
                                   noButtonText={t("No")}/>
                )}
            </PageContainer>
        </div>
    );
};

export default Employees;