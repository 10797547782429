import LogoutIcon from "../../../assets/svg/LogoutIcon";
import MainButton from "../../buttons/MainButton.tsx";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const LogoutModal = ({ isOpen, onClose }: Props) => {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className=" flex h-[264px] w-[328px] flex-col	items-center gap-[12px] rounded-2xl bg-black-secondary-strong px-10 py-6 font-myriad shadow">
        <div>
          <LogoutIcon />
        </div>
        <div className="text-xl text-black-primary">Logged out</div>
        <div className="text-center	text-sm text-black-60">
          For security reasons, you've been logged out. Simply log back in to
          access your account.
        </div>
        <MainButton
          title="Log back in"
          className=" h-[44px] w-[99px] rounded-lg bg-dark-green font-myriad text-sm font-light text-black-secondary-strong hover:bg-dark-green-80 dark:border-0"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default LogoutModal;
