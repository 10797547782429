const RightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="12"
      viewBox="0 0 26 12"
      fill="none"
    >
      <path
        d="M25 6L0.999999 6"
        stroke="#667B8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 1C20 1 25 4.68244 25 6.00004C25 7.31763 20 11 20 11"
        stroke="#667B8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightArrow;
