import React, {ReactNode} from "react";

type IProps = {
    type?: "text" | "password";
    label: string;
    placeholder?: string;
    width?: string | number;
    height?: string | number;
    icon?: ReactNode;
    disabled?: boolean;
    multiline?: boolean;
    value?: string;
    onChange?: any;
};

const BasicInput = ({
                        label,
                        placeholder,
                        width,
                        height,
                        icon,
                        disabled,
                        multiline,
                        value,
                        onChange,
                    }: IProps) => {
    const isMultilinePlaceholder = placeholder?.includes("\n");

    const textareaStyle: React.CSSProperties = {
        resize: "none",
        overflow: "hidden",
    };
    return (
        <>
            {label && <div className="mb-1.5 font-myriad text-xs text-black-primary">{label}</div>}
            <div className="relative">
                <div className="absolute right-4 top-3">{icon}</div>
                {multiline || isMultilinePlaceholder ? (
                    <textarea
                        placeholder={placeholder}
                        disabled={disabled}
                        style={{...textareaStyle, width, height}}
                        value={value}
                        onChange={onChange}
                        className={`rounded-lg border-[1px] border-black-10 px-3.5 py-3 font-myriad text-xs font-regular focus:border-dark-green focus:outline-none focus:ring-0 active:border-dark-green ${disabled && "cursor-not-allowed"}`}
                    />
                ) : (
                    <input
                        type="text"
                        placeholder={placeholder}
                        disabled={disabled}
                        style={{width, height}}
                        className={`w-full rounded-lg border-[1px] border-black-10 px-3.5 py-3 font-myriad text-xs font-regular focus:border-dark-green focus:outline-none focus:ring-0 active:border-dark-green ${disabled && "cursor-not-allowed"}`}
                        value={value}
                        onChange={onChange}
                    />
                )}
            </div>
        </>
    );
};

export default BasicInput;
