import React, {FC, PropsWithChildren} from 'react';

type Props = {
    label?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormFileInput: FC<PropsWithChildren<Props>> = ({label, onChange}) => {
    return (
        <>
            {label && <label className={"font-myriad font-regular mb-1.5 text-xs text-black-primary"}>{label}</label>}
            <input onChange={(e) => onChange(e)}
                   type={"file"}
                   accept={"application/pdf"}
                   className="mt-2 block w-full text-sm text-slate-500         
                                       file:mr-4 file:py-2 file:px-4 file:rounded-md
                                       file:border-0 file:text-sm file:font-semibold
                                       file:bg-dark-green-20 file:text-dark-green
                                        dark:[color-scheme:light]
                                       hover:file:bg-dark-green hover:file:text-black-secondary-strong"
            />
        </>
    );
};

export default FormFileInput;