const getSelectedOption = <T, >(value: any, items: any[] | undefined, fieldId: string, fieldLabel: string): SelectControlOption<T> | undefined => {
    let selectOption: SelectControlOption<T> | undefined = undefined;
    if (!items) return selectOption;
    if (!value) return undefined;

    if (value[fieldId]) {
        const item = items?.find((d: any) => d.id === value[fieldId]);
        if (item) {
            selectOption = {
                value: (item as T)["id"],
                label: item[fieldLabel]
            };
        }
    }

    return selectOption;
}

export const FormHelper = {
    getSelectedOption,
};