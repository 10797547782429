import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";

import error from "../../assets/svg/LoginIcons/error.svg";
type Props = {
  type?: "password" | "text";
  fieldName: string;
  placeholder?: string;
  width?: string | number;
  icon?: ReactNode;
  disabled?: boolean;
  value?: string;
};
const FormTextInput = ({
  type = "text",
  fieldName,
  placeholder,
  width,
  icon,
  disabled,
  value,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[fieldName]?.message as string;

  return (
    <div className="relative mb-4">
      <div>
        <div className="absolute right-4 top-3">{icon}</div>
        <input
          {...register(fieldName, { required: true })}
          type={type}
          placeholder={placeholder}
          defaultValue={value}
          width={width}
          disabled={disabled}
          className={`flex h-[44px] w-[328px] items-center justify-center gap-2 rounded-[8px] border-2 border-black-10 px-[14px] py-[12] font-myriad font-light focus:border-dark-green focus:outline-none focus:ring-0 active:border-dark-green ${errors[fieldName] ? "border-red-primary" : ""} bg-black-secondary-strong dark:border-black-10 dark:bg-black-secondary-strong`}
        />
      </div>
      <div className="flex items-center justify-start">
        {errors[fieldName] && (
          <span className="flex flex-row text-red-primary">
            <img src={error} alt="error" className="me-1" />
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  );
};

export default FormTextInput;
