import {ReactNode} from "react";
import {DashboardHelper} from "../../utils/DashboardHelper.ts";
import {ThemeText} from "../../theme/text.ts";

type Props = {
    title: string;
    mainInfo?: number | string;
    icon: ReactNode;
    cssClass?: string;
};

const DashboardCard = ({title, mainInfo, icon, cssClass}: Props) => {
    return (
        <div
            className={`h-[68px] sm:h-[166px] w-full flex flex-row sm:flex-col 
            items-start justify-between border-black-10 bg-black-secondary-strong p-4 sm:p-6 ${cssClass} 
            border-[1px] border-black-10 bg-black-secondary-strong  md:rounded-2xl md:border-[1px]`}>
            <div>
                <div className={"font-myriad text-xs sm:text-lg font-regular text-black-80"}>
                    {title}
                </div>
                <div className="font-myriad text-xs sm:text-xl font-regular text-black-80">
                    {mainInfo}
                </div>
            </div>
            <div>{icon}</div>
        </div>
    );
};

export default DashboardCard;
