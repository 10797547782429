import {useState} from "react";
import {UrlHelper} from "../helpers/UrlHelper.ts";
import {axiosInstance} from "../config/appConfig.ts";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto.ts";
import {format} from "date-fns";
import {EventTypeEnum} from "../enums/EventTypeEnum.ts";
import UpdateResponseDto from "../dtos/BaseResponse/UpdateResponseDto.ts";
import {CalendarEventDto} from "../dtos/calendar/CalendarEventDto.ts";
import DetailsResponseDto from "../dtos/BaseResponse/DetailsResponseDto.ts";

const useFetchCalendarDates = () => {
    const [calendarEvents, setCalendarEvents] = useState<CalendarEventDto[]>([]);
    const [calendarEvent, setCalendarEvent] = useState<CalendarEventDto | undefined>(undefined);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchCalendarEvents = async (startDate: Date, endDate: Date, searchText?: string, skip?: number, take?: number, apartments?: string[], allEvents?: boolean) => {
        try {
            setIsLoading(true);
            const url = UrlHelper.generateUrlWithParams(`Calendar/${format(startDate, "yyyy-MM-dd")}/${format(endDate, "yyyy-MM-dd")}`, {
                searchText,
                skip,
                take,
                apartments,
                allEvents
            });
            const response = await axiosInstance.get<ListResponseDto<CalendarEventDto>>(url);
            setCalendarEvents(response.data.items);
            setTotalCount(response.data.totalCount);
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const cancelReservation = async (eventId: string) => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.patch<UpdateResponseDto<CalendarEventDto>>(`Reservations/Cancel/${eventId}`);
            return response?.data?.success;
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const fetchCalendarEvent = async (eventId: string, eventType: EventTypeEnum) => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.get<DetailsResponseDto<CalendarEventDto>>(`Calendar/${eventId}/${eventType}`);
            setCalendarEvent(response.data.item)
        } catch (e: any) {
            setError(e.message);
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    return {
        calendarEvents,
        fetchCalendarEvents,
        error,
        isLoading,
        totalCount,
        isSubmitting,
        cancelReservation,
        fetchCalendarEvent,
        calendarEvent
    }
};

export default useFetchCalendarDates;
