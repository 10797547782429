import React, {FC, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ThemeText} from "../../theme/text.ts";
import {NavLink} from "react-router-dom";
import ApartmentCard from "../Cards/ApartmentCard.tsx";
import SquareIcon from "../../assets/svg/apartmentIcons/SquareIcon.tsx";
import AptcodeIcon from "../../assets/svg/apartmentIcons/AptcodeIcon.tsx";
import AptOwnerIcon from "../../assets/svg/apartmentIcons/AptOwnerIcon.tsx";
import DataCardsPagination from "./DataCardsPagination.tsx";

type Props<TCard> = {
    cards: TCard[];
    noItemsTitle?: string;
    showHeader?: boolean;
    headerText?: string;
    headerLink?: string;
    headerLinkText?: string;
    showPagination?: boolean;
    onRenderCard?: (card: TCard, index: number) => JSX.Element;
    totalCount?: number;
    onReload?: (pageSize: number, pageNumber: number) => void;
    pageSize: number;
    currentPage: number;
    allowedPageSizes?: number[];
}

const DataCards = <TCard extends {}>({
                                         cards,
                                         noItemsTitle,
                                         showHeader,
                                         headerText,
                                         headerLinkText,
                                         headerLink,
                                         showPagination,
                                         onRenderCard,
                                         totalCount,
                                         pageSize = 20,
                                         currentPage = 1,
                                         allowedPageSizes = [5, 10, 20, 50],
                                         onReload
                                     }: PropsWithChildren<Props<TCard>>) => {
    const {t} = useTranslation();
    const [totalPagesCount, setTotalPagesCount] = useState(0);

    useEffect(() => {
        if (totalCount) {
            setTotalPagesCount(Math.ceil(totalCount / pageSize));
        }
    }, [totalCount, pageSize, cards]);

    function handleSetCurrentPage(e: any, newPageNumber: number) {
        onReload && onReload(pageSize, newPageNumber);
    }

    function handleSetPageSize(e: any, newPageSize: number) {
        onReload && onReload(newPageSize, currentPage);
    }

    const renderHeader = useCallback(() => {
        return (
            <div className={"flex justify-between px-6 py-4 border-b-[1px] border-black-10 h-[70px]"}>
                <span className={ThemeText.cardTitle}>{headerText}</span>
                {headerLink && (
                    <NavLink className={"font-myriad font-regular text-sm link text-dark-green no-underline hover:underline"}
                             to={headerLink}>{headerLinkText}</NavLink>
                )}
            </div>
        );
    }, [headerText, headerLink, headerLinkText]);

    const renderCardCss = useCallback(() => {
        let css = "w-full bg-white border-r-[1px] h-[240px]";
        
        if (!showHeader) {
            css += " first:rounded-tl-2xl ";
            css += " last:rounded-tr-2xl ";
        }

        if (!showPagination) {
            css += " first:rounded-bl-2xl ";
            css += " last:rounded-br-2xl ";
        }

        return css;
    }, [showHeader, cards]);


    return (
        <div className={"bg-white rounded-2xl"}>
            {showHeader && renderHeader()}
            {(!cards || cards.length === 0) && <div
                className="flex items-center justify-center px-6 py-5">
                <span>{noItemsTitle ?? t("NoItems")}</span>
            </div>}
            {cards && cards.length > 0 && (
                <div className="grid grid-cols-1 gap-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    {cards.map((card, index) => (
                        <div key={index} className={renderCardCss()}>
                            {onRenderCard && onRenderCard(card, index)}
                        </div>
                    ))}
                </div>
            )}
            {showPagination && (
                <>
                    <DataCardsPagination allowedPageSizes={allowedPageSizes}
                                         currentPageSize={pageSize}
                                         currentPage={currentPage}
                                         onSetCurrentPage={handleSetCurrentPage}
                                         onSetPageSize={handleSetPageSize}
                                         totalPagesCount={totalPagesCount}/>
                </>
            )}
        </div>
    );
};

export default DataCards;