import { PropsWithChildren, useEffect } from "react";
import { useAppSelector } from "../redux/hooks/hooks";
import { selectAuthUserToken } from "../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useAppSelector(selectAuthUserToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === undefined || user === null) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  return <>{children}</>;
};

export default ProtectedRoutes;
