import React, {FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState} from 'react';
import EmployeeTaskDto from "../../../dtos/EmployeeTask/EmployeeTaskDto.ts";
import BaseModal from "../../../components/Modals/BaseModal.tsx";
import BaseModalContent from "../../../components/Modals/BaseModalContent.tsx";
import BaseModalButtons from "../../../components/Modals/BaseModalButtons.tsx";
import BaseModalHeader from "../../../components/Modals/BaseModalHeader.tsx";
import {LuCheck} from "react-icons/lu";
import {useTranslation} from "react-i18next";
import useFetchUsers from "../../../hooks/useFetchUsers.ts";
import FormSelectControl from "../../../components/FormControls/FormSelectControl.tsx";
import {FormProvider, useForm} from "react-hook-form";
import {useAppSelector} from "../../../redux/hooks/hooks.ts";
import {selectAuthUserDetails} from "../../../redux/slices/authSlice.ts";
import useFetchEmployeeTasks from "../../../hooks/useFetchEmployeeTasks.ts";
import {EmployeeTaskStatusEnum} from "../../../enums/EmployeeTaskStatusEnum.ts";

type Props = {
    onClose: () => void;
    onYes: (assigneeId?: string) => void;
    title: string;
    content?: string;
    type?: "danger" | "regular";
    icon?: ReactNode;
    taskId: string;
}

type ChangeStatusConfirmQuestionFormModel = {
    assignee?: SelectControlOption<string>;
}

const ChangeStatusConfirmQuestionModal: FC<PropsWithChildren<Props>> = ({
                                                                            icon,
                                                                            onClose,
                                                                            onYes,
                                                                            type = "regular",
                                                                            title,
                                                                            content,
                                                                            taskId,
                                                                        }) => {
    const {t} = useTranslation();
    const {employeesOptions, fetchEmployees, error, isLoading} = useFetchUsers();
    const hookForm = useForm<ChangeStatusConfirmQuestionFormModel>();
    const {handleSubmit} = hookForm;
    const {fetchTask, isLoading: fetchEmployeeTasksIsLoading, error: fetchEmployeeTasksError} = useFetchEmployeeTasks();
    const [showAssignee, setShowAssignee] = useState(false);

    const fetchData = useCallback(async () => {
        if (!taskId) {
            console.log("TaskId is not provided")
            return;
        }
        const taskResponse = await fetchTask(taskId);
        const task = taskResponse!.item;
        const showAssignee = task?.status === EmployeeTaskStatusEnum.New;
        setShowAssignee(showAssignee);
        if (showAssignee && taskId) {
            console.log(task.departmentId);
            await fetchEmployees(task.departmentId);
        }
    }, [taskId]);

    const confirmChangeStatus = (data: ChangeStatusConfirmQuestionFormModel) => {
        onYes(data.assignee?.value);
    }

    useEffect(() => {
        fetchData();
    }, [taskId]);

    console.log("task", taskId);

    return (
        <FormProvider {...hookForm}>
            <BaseModal>
                <BaseModalContent>
                    <div
                        className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 inset-x-0 bottom-0 z-50 relative">
                        <div className="md:flex items-center">
                            <div
                                className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                                {/*<i className="bx bx-error text-3xl"></i>*/}
                                {icon}
                            </div>
                            <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                                <p className="text-black font-bold text-sm">{title}</p>
                                <p className="text-sm text-gray-700 mt-1">
                                    {content}
                                </p>
                                {showAssignee && (
                                    <div className={"mt-3"}>
                                        <FormSelectControl options={employeesOptions} fieldName={"assignee"}
                                                           label={"Assignee"} isRequired={showAssignee}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </BaseModalContent>
                <BaseModalButtons saveButtonText={t("Yes")}
                                  saveButtonIcon={<LuCheck/>}
                                  onSave={handleSubmit(confirmChangeStatus)}
                                  showCloseButton={true}
                                  onClose={onClose}
                                  closeButtonText={t("No")}
                                  separateButtons={false}
                                  extraClassName={"justify-end gap-3"}
                >
                </BaseModalButtons>
            </BaseModal>
        </FormProvider>
    );
};

export default ChangeStatusConfirmQuestionModal;    