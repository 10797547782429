import {useCallback, useMemo, useState} from "react";
import {axiosInstance} from "../config/appConfig";
import ListResponseDto from "../dtos/BaseResponse/ListResponseDto";
import {UrlHelper} from "../helpers/UrlHelper";
import {EmployeeTaskDto} from "../dtos/EmployeeTask/EmployeeTaskDto.ts";
import DetailsResponseDto from "../dtos/BaseResponse/DetailsResponseDto.ts";
import CreateEmployeeTaskDto from "../dtos/EmployeeTask/CreateEmployeeTaskDto.ts";
import CreateResponseDto from "../dtos/BaseResponse/CreateResponseDto.ts";
import {EmployeeTaskStatusEnum} from "../enums/EmployeeTaskStatusEnum.ts";
import {EnumHelper} from "../helpers/enumHelper.ts";
import {TaskFilterModal} from "../pages/TasksPage/types.ts";

const useFetchEmployeeTasks = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tasks, setTasks] = useState<EmployeeTaskDto[]>([]);
    const [task, setTask] = useState<EmployeeTaskDto | undefined>(undefined)
    const [error, setError] = useState<string | undefined>(undefined);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tasksCountByStatuses, setTasksCountByStatuses] = useState([0, 0, 0, 0, 0, 0]);
    const [totalCount, setTotalCount] = useState(0);

    const employeeTasksStatusOptions = useMemo(() => {
        const options: SelectControlOption<EmployeeTaskStatusEnum>[] = [];
        options.push({label: "New", value: EmployeeTaskStatusEnum.New});
        options.push({label: "Assigned", value: EmployeeTaskStatusEnum.Assigned});
        options.push({label: "In Progress", value: EmployeeTaskStatusEnum.OnGoing});
        options.push({label: "Closed", value: EmployeeTaskStatusEnum.Closed});
        options.push({label: "Inspected", value: EmployeeTaskStatusEnum.Inspected});
        return options;
    }, []);


    const fetchTasks = async (searchText?: string, skip?: number, take?: number, tasksFilter?: TaskFilterModal) => {
        console.log("fetchTasks filter", tasksFilter)
        try {
            setIsLoading(true);
            setError(undefined)

            const url = UrlHelper.generateUrlWithParams("EmployeeTasks", {
                searchText,
                skip,
                take,
                assigneeId: tasksFilter?.assigneeEmployee?.value,
                locationId: tasksFilter?.location?.value,
                apartmentId: tasksFilter?.apartment?.value,
                taskTypeId: tasksFilter?.taskType?.value,
                taskStatus: tasksFilter?.taskStatus?.value,
                taskSourceId: tasksFilter?.taskSource?.value,
                dateFrom: tasksFilter?.dateFrom,
                dateTo: tasksFilter?.dateTo
            });

            console.log("url", url)

            const response = await axiosInstance.get<ListResponseDto<EmployeeTaskDto>>(url);
            setTasks(response.data.items);
            setTotalCount(response.data.totalCount);
            return response.data
        } catch (error: any) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTask = async (taskId: string) => {
        try {
            setIsLoading(true);
            setError(undefined);

            const url = "EmployeeTasks/" + taskId;
            const response = await axiosInstance.get<DetailsResponseDto<EmployeeTaskDto>>(url);
            setTask(response.data.item);

            return response.data;
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const createTask = async (task: CreateEmployeeTaskDto) => {
        try {
            setIsSubmitting(true);
            setError(undefined);

            const url = "EmployeeTasks";
            const response = await axiosInstance.post<CreateResponseDto<EmployeeTaskDto>>(url, task);

            return response.data;
        } catch (error: any) {
            setError(error.message);
        } finally {
            setIsSubmitting(false);
        }
    }

    return {
        fetchTask,
        isSubmitting,
        createTask,
        fetchTasks,
        tasksCountByStatuses,
        employeeTasksStatusOptions,
        tasks,
        task,
        isLoading,
        error,
        validationErrors,
        totalCount
    }
};

export default useFetchEmployeeTasks;